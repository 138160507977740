import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nurs8123assignment6 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS 8123 Assignment 6.1 Literature Matrix</title>
                <meta name='description'
                    content="Unlock insights in NURS 8123 Assignment 6.1 Literature Matrix. Learn effective ways to analyze and organize research for academic success!" />
                <meta name='keywords' content='NURS 8123 Assignment 6.1 Literature Matrix' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS 8123 Assignment 6.1  < br /><span>Literature Matrix</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nurs8123assignment6.webp" alt="NURS 8123 Assignment 6.1 Literature Matrix" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>NURS 8123 Assignment 6.1 Literature Matrix.</strong></em></li>
                        </ul>
                        <h2>Literature Matrix</h2>
                        <table>
                            <tbody>
                                <tr>
                                    <td colspan="2" width="623">
                                        <p><strong>Literature Matrix Table</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="329">
                                        <p><strong>Article 1:</strong></p>
                                    </td>
                                    <td width="295">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td width="329">
                                        <p><strong>1. Date, Author Name, Title of Article, Title of Journal, &amp; DOI</strong></p>
                                        <p><strong>Date:</strong></p>
                                        <p><strong>Author(s):</strong></p>
                                        <p><strong>Title of Article:</strong></p>
                                        <p><strong>Title of Journal:</strong></p>
                                        <p>DOI:</p>
                                    </td>
                                    <td width="295">
                                        <p>2023</p>
                                        <p>Stephanie Rodrigo</p>
                                        <p>"The low retention rates of nontraditional students in community colleges: An exploratory case study"</p>
                                        <p><em>Doctoral Dissertations and Projects</em></p>
                                        <p><a href="https://digitalcommons.liberty.edu/doctoral/4793">https://digitalcommons.liberty.edu/doctoral/4793</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="329">
                                        <p><strong>2. Methodology</strong></p>
                                        <p><strong>Intervention and Data Collection </strong></p>
                                    </td>
                                    <td width="295">
                                        <p><strong>Study/Project Design</strong></p>
                                        <p>This study used qualitative research methodology. Specifically, it employed exploratory case study research design.</p>
                                        <p><strong>Sample Setting</strong></p>
                                        <p>The study was carried out at the Waynesboro Community College in Waynesboro, NC amongst nontraditional learners.</p>
                                        <p><strong>Purpose of Study</strong></p>
                                        <p>The target interest was to investigate the postsecondary educational experience of nontraditional community college students concerning student affairs, belongingness, and satisfaction.</p>
                                        <p><strong>Instruments of Study</strong></p>
                                        <p>Semi-structured interviews along with reflective journals self-administered questionnaires, and other field notes and memos were used as data collection instruments.</p>
                                        <p><strong>Intervention and Data Collection Procedure</strong></p>
                                        <p>The data collection instruments were interviews, journals, and questionnaires to ensure that they obtained the samples&rsquo; perspectives on all the proposed research questions and used the exploratory analysis.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="329">
                                        <p><strong>3. Findings: Analysis/Results</strong></p>
                                    </td>
                                    <td width="295">
                                        <p><strong>Data Analysis Test</strong></p>
                                        <p>The method employed to analyze the data and reveal thematic patterns was called thematic analysis.</p>
                                        <p><strong>Reason for Analysis Test</strong></p>
                                        <p>This method was adopted to gain maximum insight into the qualitative information that was gathered from the Non-Traditional students.</p>
                                        <p><strong>Summary of Analysis Results</strong></p>
                                        <p>Four main themes emerged from the study they include: 1) Poor connectivity of nontraditional learners within the college 2) Lack of support services that targeted the nontraditional student 3) Satisfaction that is associated with good academic relationships 4) Factors that infringe on nontraditional learners&rsquo; productive college experience.</p>
                                        <p><strong>Alignment with Study Objective</strong></p>
                                        <p>The findings were relevant to the objectives of the study because they brought out the need for organizations to provide support to non-traditional students to improve retention rates.</p>
                                        <p><strong>Demographic/Descriptive Data</strong></p>
                                        <p>The participants in the study were 10 nontraditional students which indicates the use of a purposeful sampling technique that offers an understanding of nontraditional students&rsquo; needs and experiences.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="329">
                                        <p><strong>4. Conclusion</strong></p>
                                        <p><strong>Relevance to the DNP Project:</strong></p>
                                    </td>
                                    <td width="295">
                                        <p><strong>Conclusion of Study</strong></p>
                                        <p>The research also stated that non-traditional learners are more likely to perceive themselves as marginalized. The institutions should provide them with more support services to increase their retention.</p>
                                        <p><strong>Alignment with Objectives</strong></p>
                                        <p>The conclusion was consistent with the goals set by the study and highlighted the importance of student support services to retention.</p>
                                        <p><strong>Usefulness to DNP Project</strong></p>
                                        <p>This article is relevant in informing the DNP project because it gives information on the challenges of retention of non-traditional students and the importance of, support services.</p>
                                        <p><strong>Implication to Clinical Practice</strong></p>
                                        <p>The study suggests that other interventions to promote student participation and encouragement might be used in the healthcare education context to increase students&rsquo; persistence.</p>
                                        <p><strong>Relevance to DNP Project</strong></p>
                                        <p>The article is related to the need to consider retention challenges in community colleges. It is important to the project, particularly in terms of catering to nontraditional learners.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="329">
                                        <p><strong>Article 2:</strong></p>
                                    </td>
                                    <td width="295">&nbsp;</td>
                                </tr>
                                <tr>
                                    <td width="329">
                                        <p><strong>1. Date, Author Name, Title of Article, Title of Journal, &amp; DOI</strong></p>
                                        <p><strong>Date: </strong></p>
                                        <p><strong>Author(s): </strong></p>
                                        <p><strong>Title of Article: </strong></p>
                                        <p><strong>Title of Journal: </strong></p>
                                        <p><strong>DOI: </strong></p>
                                    </td>
                                    <td width="295">
                                        <p>2020</p>
                                        <p>Mike Tight</p>
                                        <p>Student retention and engagement in higher education.</p>
                                        <p><em>Journal of Further and Higher Education</em></p>
                                        <p><a href="https://doi.org/10.1080/0309877X.2019.1576860">https://doi.org/10.1080/0309877X.2019.1576860</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="329">
                                        <p><strong>2. Methodology</strong></p>
                                    </td>
                                    <td width="295">
                                        <p><strong>Study/Project Design</strong></p>
                                        <p>Systematic Review</p>
                                        <p><strong>Sample Setting </strong></p>
                                        <p>Investigate student retention and engagement systems at different universities and colleges.</p>
                                        <p><strong>Purpose of Study</strong></p>
                                        <p>To review and analyze literature on issues of student persistence and success in higher education focusing on their antecedents, relationships, and evaluation.</p>
                                        <p><strong>Instruments of Study</strong></p>
                                        <p>The analysis of previous research articles, theoretical models, and criticism regarding student retention and engagement.</p>
                                        <p><strong>Intervention and Data Collection Procedure</strong></p>
                                        <p>The article does not involve the use of a new intervention but is a meta-synthesis of research that has already been done. A review of the literature was carried out and information was gathered from the studies.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="329">
                                        <p><strong>3. Findings: Analysis/Results</strong></p>
                                    </td>
                                    <td width="295">
                                        <p><strong>Data Analysis Test</strong></p>
                                        <p>Not applicable, the research presented solely depends on secondary sources of information, which do not include original data acquired from the respondents/samples.</p>
                                        <p><strong>Reason for Analysis Test</strong></p>
                                        <p>The study uses the integration of data collected from the literature reviewed while emphasizing the thematic analysis.</p>
                                        <p><strong>Summary of Analysis Results</strong></p>
                                        <p>The review identifies that engagement has taken greater emphasis than student retention. These issues have been now transferred to higher education institutions because funding is being devolved or shifted to the institutions.</p>
                                        <p><strong>Alignment with Study Objective</strong></p>
                                        <p>The findings are consistent in identifying how both engagement and retention are viewed and addressed in higher education institutions.</p>
                                        <p><strong>Demographic/Descriptive Data</strong></p>
                                        <p>The review does not include a precise description of primary demographic characteristics. It describes various tendencies and concerns identified in the list of reviewed articles.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="329">
                                        <p><strong>Conclusion</strong></p>
                                    </td>
                                    <td width="295">
                                        <p><strong>Conclusion of Study</strong></p>
                                        <p>In this article, the author argues that even though engagement and retention are both important. Engagement has been considered more important and has received more research attention in the last decade. The task of enhancing these aspects has shifted more and more to higher education institutions.</p>
                                        <p><strong>Alignment with Objectives</strong></p>
                                        <p><strong>T</strong>he conclusion supports the objectives of the study by taking out changes in focus and responsibilities in higher education.</p>
                                        <p><strong>Usefulness to DNP Project</strong></p>
                                        <p><strong>T</strong>his article helps get a sense of the wider literature on student retention and retention challenges. This can then help in addressing retention problems in the community college.</p>
                                        <p><strong>Implication to Clinical Practice</strong></p>
                                        <p>The article is based on higher education and the experiences of new and changed responsibilities. Areas of focus reflected in the study can be extended to similar issues in other contexts, such as healthcare education.</p>
                                        <p><strong>Relevance to the DNP Project</strong></p>
                                        <p>The review gives a basic knowledge of how retention and engagement challenges are perceived. It will assist in solving the problem of retention of inadequate resources in community colleges which is why the project is by the DNP project.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h2>References</h2>
                        <p>Rodrigo, S. (2023). The low retention rates of nontraditional students in community colleges: An exploratory case study. <em>Doctoral Dissertations and Projects</em>.</p>
                        <p>https://digitalcommons.liberty.edu/doctoral/4793</p>
                        <p>Tight, M. (2020). Student retention and engagement in higher education. <em>Journal of Further and Higher Education</em>, <em>44</em>(5), 1&ndash;16.</p>
                        <p>https://doi.org/10.1080/0309877x.2019.1576860</p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nurs8123assignment6