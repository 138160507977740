import React, { useState } from 'react'
import { LuFileBadge, LuUserCheck, LuTimerReset, LuFileLock2, LuBookCopy, LuFolderLock, LuClock4 } from "react-icons/lu";
import { FaGift } from "react-icons/fa";
import { FiTarget } from "react-icons/fi";
import Container from 'react-bootstrap/esm/Container';

const Features = () => {

  const [modal, setModal] = useState(false);
  const toggleModal = (e) =>{
    e.preventDefault()
    setModal(!modal)
  }
  const [modalTwo, setModalTwo] = useState(false);
  const toggleModalTwo = (e) =>{
    e.preventDefault()
    setModalTwo(!modalTwo)
  }
  const [modalThree, setModalThree] = useState(false);
  const toggleModalThree = (e) =>{
    e.preventDefault()
    setModalThree(!modalThree)
  }
  const [modalFour, setModalFour] = useState(false);
  const toggleModalFour = (e) =>{
    e.preventDefault()
    setModalFour(!modalFour)
  }
  const [modalFive, setModalFive] = useState(false);
  const toggleModalFive = (e) =>{
    e.preventDefault()
    setModalFive(!modalFive)
  }
  const [modalSix, setModalSix] = useState(false);
  const toggleModalSix = (e) =>{
    e.preventDefault()
    setModalSix(!modalSix)
  }
  const [modalSeven, setModalSeven] = useState(false);
  const toggleModalSeven = (e) =>{
    e.preventDefault()
    setModalSeven(!modalSeven)
  }
  const [modalEight, setModalEight] = useState(false);
  const toggleModalEight = (e) =>{
    e.preventDefault()
    setModalEight(!modalEight)
  }

  return (
    <>
    <Container>
    <div id='features' className='ocs2-features'>
    <h2 className='ocs2-features-heading'><span className='thin-text'>Need help?</span><span className='ocs2-features-headind-colortext'> Call us for personalized <br/>online course assistance</span></h2>
      <div className='ocs2-features-grid'>
        <div className='ocs2-single-feature' onClick={toggleModal}><span><LuFileBadge className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Exceptional Writers</span></div>
        <div className='ocs2-single-feature' onClick={toggleModalTwo}><span><LuUserCheck className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Quality Assurance Specialists</span></div>
        <div className='ocs2-single-feature' onClick={toggleModalThree}><span><LuTimerReset className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Team Leads and Support</span></div>
        <div className='ocs2-single-feature' onClick={toggleModalFour}><span><LuFileLock2 className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Personalized Solutions</span></div>
        <div className='ocs2-single-feature' onClick={toggleModalFive}><span><LuBookCopy className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Uncompromising Quality</span></div>
        <div className='ocs2-single-feature' onClick={toggleModalSix}><span><LuClock4 className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Timely Delivery</span></div>
        <div className='ocs2-single-feature' onClick={toggleModalSeven}><span><FiTarget className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Client-Centric Approach</span></div>
        <div className='ocs2-single-feature' onClick={toggleModalEight}><span><LuFolderLock className='ocs2-features-icon'/></span><span className='ocs2-feature-icon-title'>Your Privacy Matters</span></div>
      </div>

      {modal && (
        <div className='features-popup'>
          <div className='features-popup-inner'>
            <div className='path-title'>
              <span></span>
              <strong className='path-inner-title'>Exceptional Writers</strong>
              <strong onClick={toggleModal} className='close-btn'>X</strong>
            </div>
            <div className='path-details'>
              <p>Want to seek the help of top-notch writers to achieve high grades and academic
              performance? Fortunately, we have such a passionate team of exultant writers.</p>
            </div>
          </div>
        </div>
      )}
      {modalTwo && (
        <div className='features-popup'>
          <div className='features-popup-inner'>
            <div className='path-title'>
              <span></span>
              <strong className='path-inner-title'>Quality Assurance Specialists</strong>
              <strong onClick={toggleModalTwo} className='close-btn'>X</strong>
            </div>
            <div className='path-details'>
              <p>Our excellent nursing help platform specialists are dedicated to offering high-quality online MSNassistance.</p>
            </div>
          </div>
        </div>
      )}
      {modalThree && (
        <div className='features-popup'>
          <div className='features-popup-inner'>
            <div className='path-title'>
              <span></span>
              <strong className='path-inner-title'>Team Leads and Support</strong>
              <strong onClick={toggleModalThree} className='close-btn'>X</strong>
            </div>
            <div className='path-details'>
              <p> If you think I could hire someone to take my nursing exam, weare
available 24/7 for you. Our team leads enable the students to achieve their full
academic potential.</p>
            </div>
          </div>
        </div>
      )}
      {modalFour && (
        <div className='features-popup'>
          <div className='features-popup-inner'>
            <div className='path-title'>
              <span></span>
              <strong className='path-inner-title'>Personalized Solutions</strong>
              <strong onClick={toggleModalFour} className='close-btn'>X</strong>
            </div>
            <div className='path-details'>
              <p>Our professional and experienced team also offers comprehensive
coursework solutions for DNP nursing students.</p>
            </div>
          </div>
        </div>
      )}
      {modalFive && (
        <div className='features-popup'>
          <div className='features-popup-inner'>
            <div className='path-title'>
              <span></span>
              <strong className='path-inner-title'>Uncompromising Quality</strong>
              <strong onClick={toggleModalFive} className='close-btn'>X</strong>
            </div>
            <div className='path-details'>
              <p>Uncompromising quality is our core value, from products to service.  We never 
sacrifice quality, ensuring the highest standards and your trust.</p>
            </div>
          </div>
        </div>
      )}
      {modalSix && (
        <div className='features-popup'>
          <div className='features-popup-inner'>
            <div className='path-title'>
              <span></span>
              <strong className='path-inner-title'>Timely Delivery</strong>
              <strong onClick={toggleModalSix} className='close-btn'>X</strong>
            </div>
            <div className='path-details'>
              <p>Timely delivery is our priority. We mееt deadlines with efficiency, ensuring your 
satisfaction.  Trust us to mееt your projects on track</p>
            </div>
          </div>
        </div>
      )}
      {modalSeven && (
        <div className='features-popup'>
          <div className='features-popup-inner'>
            <div className='path-title'>
              <span></span>
              <strong className='path-inner-title'>Client-Centric Approach</strong>
              <strong onClick={toggleModalSeven} className='close-btn'>X</strong>
            </div>
            <div className='path-details'>
              <p>Our cliеnt-cеntric approach is at the heart of everything we do. We prioritize your 
nееds, ensuring your satisfaction and success. Your goals are our priority.</p>
            </div>
          </div>
        </div>
      )}
      {modalEight && (
        <div className='features-popup'>
          <div className='features-popup-inner'>
            <div className='path-title'>
              <span></span>
              <strong className='path-inner-title'>Your Privacy Matters</strong>
              <strong onClick={toggleModalEight} className='close-btn'>X</strong>
            </div>
            <div className='path-details'>
              <p>Your privacy matters greatly to us.  We take еvеry measure to safeguard your 
personal information, ensuring your data is secure and confidential.</p>
            </div>
          </div>
        </div>
      )}

      <div className='ocs2-features-content'>
        <div className='ocs2-badge'>
          <img src='images/badge.png' alt=''/>
          <img src='images/badge2.png' alt=''/>
        </div>
        <div className='ocs2-features-content-box'>
          <p className='ocs2-features-content-title'>Our Amazing Free Features - Save $30 <span><FaGift/></span></p>
          <div className='ocs2-features-content-table'>
            <div className='ocs2-features-content-table-list'><span>Dedicated User Area</span><del>$3.00</del></div>
            <div className='ocs2-features-content-table-list'><span>Instant Order Tracking</span><del>$2.00</del></div>
            <div className='ocs2-features-content-table-list'><span>Quality Assurance</span><del>$8.00</del></div>
            <div className='ocs2-features-content-table-list'><span>Formatting in Any Style</span><del>$7.00</del></div>
            <div className='ocs2-features-content-table-list'><span>Referencing & Bibliography</span><del>$3.00</del></div>
            <div className='ocs2-features-content-table-list'><span>Outline</span><del>$2.00</del></div>
            <div className='ocs2-features-content-table-list'><span>Certified Writer</span><del>$4.00</del></div>
            <div className='ocs2-features-content-table-list'><span>Title Page</span><del>$7.00</del></div>
          </div>
        </div>
      </div>


    </div>
    </Container>
    </>
  )
}

export default Features
