import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nurs8123assignment7 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS 8123 Assignment | Health Belief Model Insights</title>
                <meta name='description'
                    content="Explore NURS 8123 Assignment 7.1 Theoretical Framework. Learn how to apply models in nursing practice. Learn More!" />
                <meta name='keywords' content='NURS 8123 Assignment 7.1' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS 8123 Assignment 7.1  < br /><span>Theoretical Framework/ Conceptual Models and Middle-Range Theories Paper
                    </span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nurs8123assignment7.webp" alt="NURS 8123 Assignment 7.1" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>NURS 8123 Assignment 7.1 Theoretical Framework.</strong></em></li>
                        </ul>
                        <h2>Integrating the Health Belief Model and the Theory of Planned Behavior to Enhance</h2>
                        <h3>Health Promotion in Nursing Practice</h3>
                        <p>Conceptual models and theoretical frameworks are essential in nursing to offer a proficient technique for describing, analyzing, and anticipating occasions in the discipline. These frameworks and models provide the arrangement for nursing practice, schooling, and research to organize information and important interventions sensibly (Foroughi et al., 2022). As highlighted in&nbsp;<a href="https://tutorsacademy.co/nurs-8123-assignment-7-1-theoretical-framework/">NURS 8123 Assignment 7.1 Theoretical Framework</a>, the Health Belief Model (HBM) is a pivotal framework that dissects health behaviors by considering beliefs about health conditions, expected improvement advantages, and constraints.</p>
                        <p>This study will outline the theory of planned behavior (TPB), the middle-range theory related to HBM (Huang et al., 2020). The TPB expands on the HBM by adding explicit behavioral control, which is key for understanding health behaviors. Reviewing these frameworks will show their applicability in nursing, especially in developing valuable health promotion. This paper means to pressure the potential gain of integrating the theoretical and sensible to enhance a better understanding of thought and advance the nursing discipline.</p>
                        <h2>Theoretical Framework: Health Belief Model (HBM)</h2>
                        <h3>Description of Framework/Model</h3>
                        <p>The Health Belief Model (HBM) is a psychological model formulated during the 1950s by Hochbaum, Rosenstock, and Kegels, who worked with the U.S. Free Health Administration. The HBM was initially developed to address the shortfall of individuals in difficulty supposition and early undeniable proof errands (Huang et al., 2020). It has expanded over the years to incorporate more than initially defined. It is now one of the most mind-blowing known models to portray health-related behavior.</p>
                        <p>According to the HBM, individuals' beliefs on the conditions of unequivocal health convey the possible gains of performing a particular behavior, and the risks related to this change influence the behavior. It underlines self-interpreted meanings and changes in behavior to reveal them as genuine in developing health initiatives and instructive activities.</p>
                        <h3>Concepts and Propositions</h3>
                        <ul>
                            <li>
                                <h3>Key Concepts of HBM</h3>
                            </li>
                        </ul>
                        <p>The HBM includes crucial concepts and propositions that broadly understand health behavior.</p>
                        <p>&bull; Seen shortfall of safety is how much an individual remembers they are at risk for contracting a disorder or infection. It was spread that the higher the unmistakable shortcoming, the more an individual will presumably practice to minimize the bet (Akther &amp; Nur, 2022).</p>
                        <p>&bull; The reasonable genuineness interfaces with an individual's point of view on the gamble introduced by a particular infection, condition, and its ideas. Assuming an individual thinks the condition has sad results, this individual will genuinely need to take preventive measures.</p>
                        <p>&bull; In the undeniable benefits thought, uncovering accomplices with the individual's point of view on the sufficiency of the urged action to bring down the bet or sincerity. In other words, the sensible benefits enhance the likelihood of the predetermined behavior occurring.</p>
                        <p>&bull; Seen deterrents are hindrances that hinder the undertaking of a new development. They can be physical, similar to cost or time, or psychosocial, identical to pressure or shame. The fewer the hindrances seen, the higher the opportunity for the individual to perform the proposed improvement (Akther &amp; Nur, 2022).</p>
                        <p>&bull; The sign to-movement accepted is connected with outside lifts that require unequivocal exercises from individuals. Potential signs may be through media or other individuals, such as calls from a healthcare provider.</p>
                        <p>&bull; Self-plausibility was incorporated into the HBM and added later. Self-plausibility is the level of certainty the individual has in acting on the behavior. Then again, higher self-abundancy could raise the probability of behavior change (Moey et al., 2021).</p>
                        <h3>Propositions</h3>
                        <p>The HBM maintains that an infection's indisputable determination and therapy influence health behavior. This suggests that sensible shortcomings saw reality, saw advantages, and saw blocks influence the likelihood of engaging in healthy behavior.</p>
                        <p>Additionally, the model suggests that health-promoting behavior depends on signs of improvement that spread out bearing and self-plentifulness in performing the behavior. These concepts define the probability of practicing a healthy behavior (Moey et al., 2021). Analyzing the HBM in detail shows that enhanced information on this theory will allow healthcare professionals to foster health interventions further.</p>
                        <p>These interventions can be individualized to give input to the indisputable and genuine incentives of the patients and the neighborhood, increasing the opportunity for behavior change and better health. The HBM reports that healthcare behavior results from beliefs and impressions of this theory, which is instrumental in health schooling and behavior change for developing healthier promotion moves close.</p>
                        <h2>Middle-Range Theory: Theory of Planned Behavior (TPB)</h2>
                        <h3>Description of Theory</h3>
                        <p>The Theory of Planned Behavior (TPB) is one of Ajzen's middle-range theories, formulated in the last piece of the 1980s, based on the health belief model. The theory of planned behavior has been intended to explain the convincing factors that influence behavior by relying on intention, the most critical factor (Park &amp; Shin, 2021).</p>
                        <p>Covering points of view and conceptual norms moreover includes the chance of seen behavioral control, which offers an enhanced system for analyzing individuals' decisions about health behaviors. The TPB recommends that individual behavior is driven and influenced by three key factors: buzzword impression of the behavior, saw norms, and command over the behavior. It has been applied extensively to foresee health behavior change and is an essential model for developing health interventions.</p>
                        <h3>Processes or Concepts</h3>
                        <p>This demeanor toward the behavior thought reviews how much the individual has positive or negative insights towards undertaking the behavior. The mentality of mindsets is determined by thoughts concerning the results of the behavior and the stakes given to them. In smarts, the potential outcomes of performing a given health behavior are undoubtedly high,h if the individual holding the insight feels it will have a decent outcome (Gjicali &amp; Lipnevich, 2021).</p>
                        <p>The theoretical norms include the evaluation of the general people's suspicions towards the execution or non-execution of a particular development. This thought comes from individuals' judgment regarding the sensible questions for their family, mates, companions, or society regarding their behavior. If others who matter in the individual's public improvement enable the behavior, the individual will have an uplifting viewpoint toward performing the behavior.</p>
                        <ul>
                            <li>
                                <h3>Behavioral Control and Intention</h3>
                            </li>
                        </ul>
                        <p>Further, behavioral control can be defined as the level of the individual's impression of the likelihood of performing the behavior. This acceptance is close to the self-efﬁcacy of the HBM and insinuates the apparent effortlessness or difﬁculty of the behavior, considering prior experiences and expected inconveniences (Ataei et al., 2020). Enhancing explicit behavioral control enhances the formation of a solid intention to perform the behavior. Behavioral intention is the core of the TPB model and depends on mindsets, saw norms, and saw control.</p>
                        <p>Intention proposes an individual's willingness to participate in a particular behavior and is closer to behavior than demeanor. The more grounded the intention to perform a specific behavior, the higher the probability that the behavior will be shown.</p>
                        <h3>Clinical Practice Application</h3>
                        <p>The theory of planned behavior is huge for understanding and predicting health-related behaviors in clinical settings. Minimizing the opening can be achieved by reducing the errors between intention mindsets, seeing dynamic norms, seeing behavioral control of healthcare providers, and realistic interventions to work on these areas (Ruler et al., 2020).</p>
                        <p>They can be used to push health promotion, make mindfulness, influence mindsets, enhance supportive perceived practices, and increase explicit behavioral control. For instance, in promoting smoking end, interventions can be founded on gathering the health benefits of quitting (viewpoints) and support from family peers (extraordinary norms). Increase the certainty of stopping smoking by providing resources and training (saw self-sensibility).</p>
                        <p>Behavioral clinicians decide to deal with patients' points of view about healthy behaviors in counsel, understand the strain from social factors, and uncover shields for why patients feel feeble regarding certain health behaviors (Traina &amp; Feiring, 2020). This approach aligns with insights from NURS 8123 Assignment 7.1 Theoretical Framework, providing tailored individual counseling to each patient and addressing the influential aspects of practice. The TPB can help plan and review health programs as it identifies critical factors for behavior change.</p>
                        <p>For instance, concerning a program promoting dynamic work among patients with predictable infections, the TPB can be helpful regarding the likely gains of movement, pressures, social support, and insurance from exercising routinely. Assessing the improvements in these factors could help with understanding the reasonableness of the program and a portion of the issues that exist to address(Traina &amp; Feiring, 2020). The TPB inspires the assessment of health results and improves administration by identifying the fundamental determinants of health behaviors. For instance, the level of consistency with arrangement may be enhanced by points of view, social support, and control in patients with ongoing disorders.</p>
                        <h2>Analysis of Middle-Range Theory and Conceptual Model</h2>
                        <h3>Characteristics of Conceptual Model</h3>
                        <p>The HBM and TPB are huge in understanding health behavior, yet the two theories give it somewhat out of nowhere. The HBM generally depends on individuals' sensible characteristics regarding a specific health state. Meanwhile, the TPB underlines the opportunity of intention and sees behavioral control (Qiao et al., 2021). A few bits of the HBM can be distinguished within the TPB, showing that the two frameworks are connected and interdependent.</p>
                        <p>Most importantly, the HBM saw weakness, and sincerity is core to knowing why the individual is drawn nearer to certain health behaviors. The TPB, moreover, involves these examinations from its work point of view. Shortcomings and the fact of the matter are best wrapped in the definition of viewpoints in that they are an individual's belief about the results of behavior. For instance, an individual's point of view on exercising may be influenced by their sensible probability (shortcoming) and importance (sincerity) of contracting cardiovascular disorders (Qiao et al., 2021).</p>
                        <ul>
                            <li>
                                <h3>HBM and TPB Alignment</h3>
                            </li>
                        </ul>
                        <p>Furthermore, the apparent benefits and cutoff points to movement in HBM correspond to the viewpoints toward behavior and saw control in the TPB. The evident benefits should be observable from the viewpoints belonging to TPB, and hindrances to advancement should be covered by explicit behavioral control (Park &amp; Shin, 2021). This theory spread out a position on the thought saw ideas are the focal determinant of intending to perform healthy behavior routinely, and self-effort beliefs direct the Sort A behavior plan.</p>
                        <p>Thirdly, the TPB does not address prompts to advancement, disregarding suggesting the impact of outside pressures through profound norms. These norms suggest the social influences and questions from brilliant assistants regarding certain behaviors (Park &amp; Shin, 2021). For instance, a doctor's idea or a general's encouragement could go about as triggers to improvement that affirmation changes to a healthier lifestyle occur.</p>
                        <p>Fourthly, integrating self-reasonableness into the HBM model correlates most with the TPB's conspicuous command over the behavior. The two pivot around the individual's unmistakable self-believability in engaging in a behavior. This closeness has focused certainty and control in stimulating convincing health behaviors.</p>
                        <h3>Comparison and Contrast</h3>
                        <p>Both the HBM and TPB are relative in that they are both theoretical models; they have unequivocal contrasts that influence their pertinence and supportiveness in clinical settings. To begin with, it is essential to note that the HBM assembles mainly for the individual's insights regarding certain health states and their outcomes (Rosenthal &amp; Shmueli, 2021).</p>
                        <p>It moreover explores how such beliefs determine one's risks for performing or not performing healthy behaviors. Then again, the TPB places behavioral intention as the closest theoretical determinant of that behavior. The TPB is more determinedly associated with intention and genuine behavior than the TRA regarding the inclusion of seen behavioral control. Moreover, the TPB integrates social factors through conceptual norms, which differs from the HBM (Rosenthal &amp; Shmueli, 2021).</p>
                        <ul>
                            <li>
                                <h3>Comparing HBM and TPB</h3>
                            </li>
                        </ul>
                        <p>This inclusion makes the TPB more complete in explaining everyday practices, strains, and support for health behaviors. For instance, a patient's fixed consistency can be principally shaped by whether their kin and mates consider this behavior OK.</p>
                        <p>Thirdly, behavioral control in the TPB is an impossibly superior factor to the HBM. While saw limits are included in the HBM, TPB takes it further by considering the straightforwardness or inconvenience the individual feels about engaging in the behavior (Foroughi et al., 2022). This extension enables the expert to see better factors that can enhance or hinder changes in behavior. Fourth, the two models have incredible explanatory and reasonable authenticity for health behaviors.</p>
                        <p>The TPB acceptably increases the range of factors determining behavior by adding intention and behavioral control. Shi et al. (2021) uncovered that the TPB could foresee a more significant distinction in health behaviors than the HBM, especially for behaviors.</p>
                        <h3>Applicability and Utility in Clinical Practice</h3>
                        <p>In clinical practice, the applicability of both the HBM and the TPB helps design interventions and enable change in health behaviors. Their applicability could waver depending on the circumstance and the behavior being consigned. Real wavering is necessary for HBM to be valuable while developing frameworks to change individual beliefs about health and conditions (Shi et al., 2021). It will, by and large, be used to gather messages that help increase apparent shortcomings and sincerity while gaining preventive behaviors. For instance, the health belief model can be used in an overall health mission to support vaccination by drawing the client's respect for the dangers of not immunizing.</p>
                        <p>Second, TPB is useful for behavioral counseling within clinical practice, as the model includes different mental inspirational factors influencing behavior. Self-report proportions of the theory of planned behavior grant healthcare providers to determine the patient's demeanor, saw norms, and saw control interventions. For instance, a smoking suspension program will embrace the TPB to increase the self-sensibility for quitting smoking, maintain an uplifting standpoint, and influence essential social support (Małecka et al., 2022).</p>
                        <p>Thirdly, the two models can be used to propose and review health programs. The NURS 8123 Assignment 7.1 Theoretical Framework emphasizes the applicability of HBM for assessing patients' screening perspectives and limitations, as it is mainly grounded in beliefs. The TPB, on the other hand, provides structured resources for developing interventions that address multiple factors influencing behavior. Evaluating distinctions in perspectives, conceptual norms, and behavioral control can enhance the effectiveness of health programs and identify potential opportunities for improvement.</p>
                        <h2>Conclusion</h2>
                        <p>In conclusion, the Health Belief Model (HBM) and the Theory of Planned Behavior (TPB) are sensible frameworks that help handle and expect health behaviors. The HBM highlights self-information on health states, while the TPB includes clear behavioral intention and saw control. The two models include viewpoints like demeanor, social beliefs, and independence to instigate behavioral change. The results of the practice of nursing could never be more fundamental.</p>
                        <p>By applying these frameworks, clinical orderlies can expect interventions to change the patients' beliefs, affect social support, and enhance self-reasonableness, leading to more expeditiously calm health. By incorporating these theories into clinical practice, more supportive ways to deal with health promotion and patient schooling are gotten to a more critical level.</p>
                        <h2>References</h2>
                        <p>Akther, T., &amp; Nur, T. (2022). A model of factors influencing COVID-19 vaccine acceptance: A synthesis of the theory of reasoned action, conspiracy theory belief, awareness, perceived usefulness, and perceived ease of use.&nbsp;<em>PLOS ONE</em>,&nbsp;<em>17</em>(1), e0261869, Pg 1-20.</p>
                        <p>https://doi.org/10.1371/journal.pone.0261869</p>
                        <p>Ataei, P., Gholamrezai, S., Movahedi, R., &amp; Aliabadi, V. (2020). An analysis of farmers&rsquo; intention to use green pesticides: The application of the extended theory of planned behavior and health belief model.&nbsp;<em>Journal of Rural Studies</em>,&nbsp;<em>81(3), Pg 1-11.</em></p>
                        <p>https://doi.org/10.1016/j.jrurstud.2020.11.003</p>
                        <p>Foroughi, Z., Ebrahimi, P., Aryankhesal, A., Maleki, M., &amp; Yazdani, S. (2022). Toward a theory-led meta-framework for implementing health system resilience analysis studies: A systematic review and critical interpretive synthesis.&nbsp;<em>BMC Public Health</em>,&nbsp;<em>22</em>(1), e287, Pg 1-13.</p>
                        <p>https://doi.org/10.1186/s12889-022-12496-3</p>
                        <p>Gjicali, K., &amp; Lipnevich, A. A. (2021). Do you have a math attitude? (In)direct effects of student mathematics attitudes on intentions, behavioral engagement, and mathematics performance in the U.S. PISA.&nbsp;<em>Contemporary Educational Psychology</em>,&nbsp;<em>67(1)</em>, e102019, Pg 1-12.</p>
                        <p>https://doi.org/10.1016/j.cedpsych.2021.102019</p>
                        <p>Huang, X., Dai, S., &amp; Xu, H. (2020). Predicting tourists&rsquo; health risk preventative behavior and traveling satisfaction in Tibet: Combining the theory of planned behavior and health belief model.&nbsp;<em>Tourism Management Perspectives</em>,&nbsp;<em>33(2)</em>, e100589, Pg 1-10.</p>
                        <p>https://doi.org/10.1016/j.tmp.2019.100589</p>
                        <p>Małecka, A., Mitręga, M., Mr&oacute;z-Gorgoń, B., &amp; Pfajfar, G. (2022). Adoption of collaborative consumption as sustainable social innovation: Sociability and novelty seeking perspective.&nbsp;<em>Journal of Business Research</em>,&nbsp;<em>144</em></p>
                        <p><em>(1)</em>, Pg163&ndash;179.</p>
                        <p>https://doi.org/10.1016/j.jbusres.2022.01.062</p>
                        <p>Moey, S.-F., Che Mohamed, N., &amp; Lim, B.-C. (2021). A path analytic model of health beliefs on the behavioral adoption of breast self-examination.&nbsp;<em>AIMS Public Health</em>,&nbsp;<em>8</em>(1), Pg15&ndash;31.</p>
                        <p>https://doi.org/10.3934/publichealth.2021002</p>
                        <p>Park, S., &amp; Shin, H. (2021). An analysis and evaluation of the theory of planned behavior using Fawcett and desanto-madeya&rsquo;s framework.&nbsp;<em>Advances in Nursing Science</em>,&nbsp;<em>Publish Ahead of Print</em>, 44(4), Pg141-154.&nbsp;</p>
                        <p>https://doi.org/10.1097/ans.0000000000000365</p>
                        <p>Qiao, X., Ji, L., Jin, Y., Si, H., Bian, Y., Wang, W., &amp; Wang, C. (2021). Development and validation of an instrument to measure beliefs in physical activity among (pre)frail older adults: An integration of the health belief model and the theory of planned behavior.&nbsp;<em>Patient Education and Counseling</em>, 104 (10), Pages 2544-2551.</p>
                        <p>https://doi.org/10.1016/j.pec.2021.03.009</p>
                        <p>Rosental, H., &amp; Shmueli, L. (2021). Integrating health behavior theories to predict COVID-19 vaccine acceptance: Differences between medical students and nursing students.&nbsp;<em>Vaccines</em>,&nbsp;<em>9</em>(7), e.783, Pg 1-13.</p>
                        <p>https://doi.org/10.3390/vaccines9070783</p>
                        <p>Shi, H., Wang, J., Huang, R., Zhao, J., Zhang, Y., Jiang, N., Tanimoto, T., Ozaki, A., Shao, C., Wang, J., He, X., &amp; Xu, X. (2021). Application of the extended theory of planned behavior to understand Chinese students&rsquo; intention to improve their oral health behaviors: A cross-sectional study.&nbsp;<em>BMC Public Health</em>,&nbsp;<em>21</em>(1), e2303, Pg 1-9. (</p>
                        <p>https://doi.org/10.1186/s12889-021-12329-9</p>
                        <p>Sultan, P., Tarafder, T., Pearson, D., &amp; Henryks, J. (2020). Intention-behaviour gap and perceived-behcontrol-behaviorehaviour gap in the theory of planned behavior: Moderating roles of communication, satisfaction, and trust in organic food consumption.&nbsp;<em>Food Quality and Preference</em>,&nbsp;<em>81</em>(81),e103838. Pg 1-33</p>
                        <p>https://doi.org/10.1016/j.foodqual.2019.103838</p>
                        <p>Traina, G., &amp; Feiring, E. (2020). &ldquo;There is no such thing as getting sick justly or unjustly&rdquo; &ndash; a qualitative study of clinicians&rsquo; beliefs on the relevance of personal responsibility as a basis for health prioritization.&nbsp;<em>BMC Health Services Research</em>,&nbsp;<em>20</em>(1), e497, Pg 1-13.&nbsp;</p>
                        <p>https://doi.org/10.1186/s12913-020-05364-6</p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nurs8123assignment7