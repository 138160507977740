import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Bhafpx4010assessment4 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>BHA FPX 4010 Assessment 4 Develop a Research Plan</title>
                <meta name='description'
                    content='Get actionable steps in BHA FPX 4010 Assessment 4 Develop a Research Plan. Boost your research strategy—click to learn more!' />
                <meta name='keywords' content='BHA FPX 4010 Assessment 4 Develop a Research Plan' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>BHA FPX 4010 Assessment 4 < br /><span>Develop a Research Plan</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Bhafpx4010assessment4.webp" alt="BHA FPX 4010 Assessment 4 Develop a Research Plan" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>BHA FPX 4010 Assessment 4 Develop a Research Plan.</strong></em></li>
                        </ul>
                        <h2>Part 1</h2>
                        <h2>Problem Statement</h2>
                        <p>Late Data has shown that careful falters, particularly wipes and other things, remain a fundamental problem in the functioning room (Watchful messes up, held wipes stay massive problems, 2021). A coincidental new thing is a serious reportable or never event (Simpson,2014). Depending on the situation, 28 million assignments are performed cross-country. Out of those undertakings, 1,500 held cautious stuff left in the patient will be tended to (How often do watchful instruments get left inside patients,2020).</p>
                        <p>The most beyond ridiculous stunning screw-up is wipes and instruments left in patients after action (Gawande et al., 2003). Different crisis networks are fundamental in diminishing the bet of held wipes after vaginal birth (Simpson,2014). It has been tended that these held new articles, similar to wipes or instruments, that can cause the opening of the inside parts, sepsis, or even obliteration.</p>
                        <p>Notwithstanding how keeping is careful articles after an action an issue for the patient and arranged professionals, it essentially influences the crisis place and clinical staff (Zejnullahu et al., 2017). Since held wipes and instruments are serious, offices should execute the Five Maneuvers toward Safer An action. The five phases cement organizing, sign-in, break, sign-out, and chatting with (Hartley, 2018).</p>
                        <ul>
                            <li>
                                <h3>Preventing Retained Surgical Items</h3>
                            </li>
                        </ul>
                        <p>This problem statement provides data and proof of how many new things are left in a patient after movement, what kind of new articles have been exculpated, and the honesty of their being left in the body. The proof in this framing gets a handle on this repeating event, which settles the problems that hurt patients and why such unending new things are left in patients after action.</p>
                        <p>The assigned people were working in rooms and functioning admirably informed specialists. It is essential to understand how to research and lead this program, how much money it would cost, and how much time it would take to organize the examination. The problem was seen by seeing arranged professionals and OR staff in the functioning rooms, meeting OR staff, and surveying JACOS standards and the Center for Government Clinical Idea and Medicaid Affiliations center around systems.</p>
                        <p>The watchful count is basic when holding new articles that are far from harming patients. Recalling for unequivocal rooms is seen as high-repeat, high-believability, and problem-slanted (Edel, 2012). Impeding holds new articles that are not self-evident and uniform. There are strong regions for lessening the irregularity of counting practice by further developing the steady counting process and practicing marvelous social cutoff points and participation.&nbsp;Explore our assessment <a href="https://onlinecourseservices.us/bha-fpx-4010-assessment-1-research-problem">BHA FPX 4010 Assessment 1 Research Problem and Purpose Statements</a>&nbsp;for more information.</p>
                        <h2>Part 2</h2>
                        <h2>Purpose Statement</h2>
                        <p>Expecting offices to decline the bet of holding new things left in patients, having very much informed specialists and clinical staff execute the Five Maneuvers toward Safer A movement, which was made aware of by the Public Patient Achievement Association (NPSA), will help with diminishing the bet. (Vickers, 2011). Five mmaneuversoward Safer A movement is participation in further correspondence inside the gathering (Hartley, 2018).</p>
                        <p>Since Wipes and instruments are more traditional new things left in the patient, applying sign-in, break, sign-out, and speaking during undertakings will lessen the numbers. These cautious mishandles can genuinely hurt the patient and hurt the staff. Thus, crisis centers should make procedures and methods and prepare to disappoint hostile clinical staggers (Careful goofs, held wipes stay fundamental problems, 2021). It is principal for name instruments to avoid these clinical goofs. Present-day naming techniques have QR codes on the instruments to forestall clinical misunderstandings (Ipaktchi et al., 2013).</p>
                        <p>The research intends to help plan clinical staff on the most proficient method to be wary during exercises to hinder such ceaseless new articles left in patients after movement. It would show affiliation and companion review examination and validation for future executions to help clinical orderlies become more educated about the functioning rooms. Experts in forestalling held new articles on careful patients. This will likewise reduce hurt and, notwithstanding, passing to patients after surprising appearances during movement.</p>
                        <h2>Part 3</h2>
                        <h2>Qualitative Research Question</h2>
                        <p>This paper inspects a clinical office-secured condition related to withholding new bodies among vigilant patients, and explicative working outlines for BHA FPX 4010 Assessment 4 Develop a Research Plan. When a patient goes through an activity, how sober-minded is including an all-over standardized show for instrument liability and post-careful radiographs while disturbing held new bodies around the hour of the action?</p>
                        <p>The qualitative research question depends on setting and proof for new articles and the responsibility of instruments, wipes, and post-activity cautious radiographs inside the functioning space to decrease and thwart these events. Data from this study can provide plans showing specialists and clinical managers whether monitoring new systems and methods is beneficial.</p>
                        <ul>
                            <li>
                                <h3>Understanding Qualitative Research Methods</h3>
                            </li>
                        </ul>
                        <p>Qualitative research is based on events that occur, as shown by the perspectives of those included (Teherani, 2015). Qualitative methods and data collection help better understand the patient's experience (Barrett &amp; Twycross, 2018). In qualitative research, you must attempt to find the reactions to questions, such as "how, "here, why, when, and who," to research further. Qualitative research enables us to examine how decisions are made with nuances on how they could change care (Barrett &amp; Twycross, 2018).</p>
                        <p>Qualitative research investigates how someone sees something and how it gives meaning. There are four ordinary kinds of qualitative research methodologies: grounded theory, appropriate evaluation, ethnography, and phenomenological research. Therefore, phenomenological research would fit the research plan. Phenomenology is about people and understanding their experiences.</p>
                        <p>Unequivocally, when new things are held after an action, that individual has experienced something. Each individual could have a different establishment and how they handle what is happening. Meeting a patient would be a method for understanding the particulars and how they act.</p>
                        <h2>Part 4</h2>
                        <h2>Qualitative Data Collection</h2>
                        <p>The data collection method that would be best used to gather reasonable data for this qualitative research study is the gathering based on the technique. Meeting people is the most used method used for data collection. While sorting out gatherings, it gathers data, and researchers demand a standard questionnaire game plan. While meeting, there are three fundamental gatherings: eye-to-eye: eye-to-eye interviews, telephone gatherings, and PC-helped individual talking. Interviews lead to the most basic response rates.</p>
                        <p>Interviews give the researcher and client a direct conversation (Methods to Gather Quantitative Data, 2022). This method allows a particular methodology and comes doubtlessly from the source. The individual can give the data but will not be open to telling another person. Interviews grant getting nuances notes since they can be pleasing and unstructured. They provide the researcher with unquestionable questions. Regardless of how long it is genuinely, it offers illuminating data. It will allow the researcher to foster a relationship with the reasonable participant.</p>
                        <p>Safeguarding patient security is essential for this research. Concur agreed to partake in the survey, and the researchers imparted data to the participants, but the participants appreciated the provided data. An illuminating handout was given to the participants to help them understand the research, wire, chance, and benefits. The participants in this research completed gatherings, outlines, and educational endeavors that could propose some imperil of minor anxiety doing conventional presence resolves, similar to exhaustion, stress, and vexing. This study addressed no bet to the security or progress of the participants.</p>
                        <h2>Valid Data Collection Method</h2>
                        <p>This study utilizes authentic data collection methods based on validity theory and revelations. The data obtained from this research was analyzed to see the interview proposal. Counting cautious supplies and instruments has been a general practice.</p>
                        <p>Notwithstanding, it continues to be the fundamental wellspring of new articles held by cautious patients &mdash; the tutoring designdesign'sisals and validations through gatherings. Fostering an enlightening endeavor will aid future executions. Executing a best practice, excellent, and standardized tutoring undertaking can decrease how many new objects are held (The Joint Commission, 2013). The impact of this program on change is that the program would improve data in working room clinical guards and arranged professionals, which would actuate safe exercises and aversion to holding new articles in attentive patients.</p>
                        <h2>Reliability and Validity of the Measurement</h2>
                        <p>Measurement reliability and validity are experiences used to decide research (Middleton, 2022). Reliability and validity statements give data to perusers about the methods to ensure that the data are valid and strong. This ensures that the data gathered is met for the exercises. Validity and reliability are ways of assessing the chance of qualitative research (Babbie, 2016).</p>
                        <p>Reliability recommends the consistency of an activity (Middleton,2022). Reliability is the cutoff concerning a test or research considered repeatable. There are two kinds of reliability: inside and outside (Mcleod, 2007). Inward reliability derives the consistency of results across things inside a test, while outside, it proposes how much a development shift beginning with one use and then onto the going (Mcleod, 2007).</p>
                        <p>Different sorts of reliability can be evaluated through various quantifiable methods, for instance, test-retest, interrater, and inside consistency (Middleton, 2022). Test-retest is the consistency of an activity across time (Middleton, 2022). Interrater is the consistency of activity across raters or spectators (Middleton, 2022). Inward consistency is the consistency of the authentic measurement (Middleton, 2022). Ensuring reliability would lead to interviews. It would portray how the expressways of managing acting or responses will be counted and how questions are coordinated in a commonly near way (Middleton, 2022).</p>
                        <ul>
                            <li>
                                <h3>Ensuring Validity in Research</h3>
                            </li>
                        </ul>
                        <p>Validity is how unequivocally a method is assessed and what it is wanted to measure (Middleton, 2019). A test is valid if it checks what it professes to measure (Mcleod, 2013). Mcleod (2013) states that expecting research to be perfect should show that the fanciful notion of being overviewed exists. It is invalid if the method is untrustworthy (Middleton,2022). The validity of a measurement can be evaluated by contemplating three basic kinds of proof: construction, content, and standard (Middleton, 2022).</p>
                        <p>Develop checks existing theory and data (Middleton, 2022). Content is how much the measurement covers all viewpoints. The rule is the degree to which an activity looks at other valid standards (Middleton, 2022). To ensure validity, data on new things could be gathered using questionnaires. These questionnaires would spread out the theory and revelations of past evaluations. To use this method, it is ideal to depict everyone who is being researched.</p>
                        <p>In this survey, all patients were kept dull to keep validity. This solidity showed that the researchers had no mysterious inclination over the gathered data and held new things. Each serene was checked to guarantee there were no duplicate diagrams. Most importantly, moral conditions were maintained by anonymizing the patients. As for the researchers, the questions were appropriate for the audit part.</p>
                        <h2>Alignment</h2>
                        <p>The problem is that held new articles are left in careful patients, and break shows are fundamental in the functioning room. This study expressed that wipes and instruments are the essential wellsprings of new articles left in careful patients. The accumulation comparatively revealed the insane bet when these held new things are left in cautious patients. Inside this outline, working rooms were the tarted people; they watched out for the instrument counting issue. During movement, new articles are overwhelming goofs.</p>
                        <p>It was conveyed that 28 million undertakings were performed, and out of those exercises, 1,500 exercises had vigilant things left in the patient. Inside the audit, patients who had or have held new articles after a movement were chosen to do questionnaires and gatherings to ensure validity and reliability in this research to select the issue of holding new things in attentive patients. Clinical centers ought to make game plans and methods to thwart these messes. Prnt-day places are doing break shows, considering how the instruments have QR codes.</p>
                        <p>In the functioning room, the crucial thing to have is correspondence. Correspondence will prevent future bumbles and focus on care. This research paid special attention to how unique crisis offices are settling the issue of holding new targets so that staff inside the crisis local area can oversee care for <a href="https://www.studocu.com/en-us/document/capella-university/health-care-reimbursement-systems/bha-fpx4010-assessment-4-1/9144134">BHA FPX 4010 Assessment 4 Develop a Research Plan</a>. This study gave techniques that can show how specialists and clinical guards can shut down and whether there is a benefit in addressing new frameworks and methodologies to agitate and hold new things in cautious patients.</p>
                        <h2>Reference</h2>
                        <p>Brouwers, M. C. (2009). Appraisal of guidelines for research and evaluation II instrument.Hamilton, ON: The AGREE Research Trust. <a href="https://www.agreetrust.org/wp-content/uploads/2017/12/AGREE-II-Users-Manual-and-23-item-Instrument-2009-Update-2017.pdf">https://www.agreetrust.org/wp-</a><a href="https://www.agreetrust.org/wp-content/uploads/2017/12/AGREE-II-Users-Manual-and-23-item-Instrument-2009-Update-2017.pdf">content/uploads/2017/12/AGREE-II-Users-Manual-and-23-item-Instrument-2009-</a><a href="https://www.agreetrust.org/wp-content/uploads/2017/12/AGREE-II-Users-Manual-and-23-item-Instrument-2009-Update-2017.pdf">Update-2017.pdf</a></p>
                        <p>Edel, E. M. (2012). Surgical count practice variability and the potential for retained surgical items. <a href="http://futureornurses.weebly.com/uploads/4/8/1/1/48118813/surgical_count.pdf">http://futureornurses.weebly.com/uploads/4/8/1/1/48118813/surgical_count.pdf</a></p>
                        <p>Gawande, Atul A, M.D., M.P.H., Studdert, David M, LLB, ScD., M.P.H., Orav, E. J., Brennan, Troyen A, MD, J.D., M.P.H., &amp; Zinner, M. J., M.D. (2003). Risk factors for retained instruments and sponges after surgery. <em>The New England Journal of Medicine, 348</em>(3), 229-35. <a href="http://library.capella.edu/login?qurl=https%3A%2F%2Fwww.proquest.com%2Fscholarly-journals%2Frisk-factors-retained-instruments-sponges-after%2Fdocview%2F220150629%2Fse-2%3Faccountid%3D27965">http://library.capella.edu/login?qurl=https%3A%2F%2Fwww.proquest.com%2Fscholarly-</a><a href="http://library.capella.edu/login?qurl=https%3A%2F%2Fwww.proquest.com%2Fscholarly-journals%2Frisk-factors-retained-instruments-sponges-after%2Fdocview%2F220150629%2Fse-2%3Faccountid%3D27965">journals%2Frisk-factors-retained-instruments-sponges-after%2Fdocview%2F220150629%2Fse-</a><a href="http://library.capella.edu/login?qurl=https%3A%2F%2Fwww.proquest.com%2Fscholarly-journals%2Frisk-factors-retained-instruments-sponges-after%2Fdocview%2F220150629%2Fse-2%3Faccountid%3D27965">2%3Faccountid%3D27965</a></p>
                        <p>Hartley, W. (2018, May). <em>SOP 5 steps and who checklist - Bradford hospitals.nhs.uk</em>.STANDARD OPERATING PROCEDURES TO ENSURE "FIVE "STEPS" TOSAA "ER SURGERY" INCL" DING" WHO" HEC" "IST "A" E "MCOMPLETEDCORRECTLY.<a href="https://www.bradfordhospitals.nhs.uk/wp-content/uploads/2019/05/5-steps-and-WHO-checklist-SOP.pdf">https://www.bradfordhospitals.nhs.uk/wp-content/uploads/2019/05/5-steps-and-WHO-</a><a href="https://www.bradfordhospitals.nhs.uk/wp-content/uploads/2019/05/5-steps-and-WHO-checklist-SOP.pdf">checklist-SOP.pdf</a></p>
                        <p><em>How often do surgical instruments get left inside patients? </em>Golden Law Office. (2020, March 12). <a href="https://goldenlawoffice.com/medical-malpractice/how-often-do-surgical-instruments-get-left-inside-patients">https://goldenlawoffice.com/medical-malpractice/how-often-do-surgical-</a><a href="https://goldenlawoffice.com/medical-malpractice/how-often-do-surgical-instruments-get-left-inside-patients">instruments-get-left-inside-patients</a></p>
                        <p>Ipaktchi, K., Kolnik, A., Messina, M., Banegas, R., Livermore, M., &amp; Price, C. (2013). Current surgical instrument labeling techniques may increase the risk of unintentionally retaining foreign objects: a hypothesis. <em>Patient safety in surgery</em>, <em>7</em>(1), 31. <a href="https://doi.org/10.1186/1754-9493-7-31">https://doi.org/10.1186/1754-9493-7-31</a></p>
                        <p><em>Methods to collect quantitative data</em>. (2022, August 10). <a href="https://www.voxco.com/blog/quantitative-data-collection-methods">https://www.voxco.com/blog/quantitative-data-collection-methods</a></p>
                        <p>McLeod, S. A. (2007). <em>What is reliability? </em>Simply Psychology. <a href="http://www.simplypsychology.org/reliability.html">www.simplypsychology.org/reliability.html</a></p>
                        <p>McLeod, S. A. (2013). <em>What is validity? </em>Simply Psychology. <a href="http://www.simplypsychology.org/validity.html">www.simplypsychology.org/validity.html</a></p>
                        <p>Middleton, F. (2022, August 31). <em>Reliability vs. validity in research: Difference, types, and examples</em>. Scribbr. <a href="https://www.scribbr.com/methodology/reliability-vs-validity">https://www.scribbr.com/methodology/reliability-vs-validity</a></p>
                        <p>Simpson, K. R. (2014). Unintended retained foreign objects after surgery and other invasive procedures. MCN, the American Journal of Maternal Child Nursing, 39(2), 139-<a href="https://doi.org/10.1097/NMC.0000000000000018">https://doi.org/10.1097/NMC.0000000000000018</a></p>
                        <p><em>Surgical errors and retained sponges remain significant problems</em>. Relias Media | Online Continuing Medical Education | Relias Media - Continuing Medical Education Publishing. (2021, June 1). Retrieved September 6, 2022, from <a href="https://www.reliasmedia.com/articles/147986-surgical-errors-retained-sponges-remain-major-problems">https://www.reliasmedia.com/articles/147986-surgical-errors-retained-sponges-remain-</a><a href="https://www.reliasmedia.com/articles/147986-surgical-errors-retained-sponges-remain-major-problems">major-problems</a></p>
                        <p>The Joint Commission. (2015). Hospital national patient safety goals. <a href="http://www.jointcommission.org/assets/1/6/2015_HAP_NPSG_ER.pdf">http://www.jointcommission.org/assets/1/6/2015_HAP_NPSG_ER.pdf</a></p>
                        <p>Vickers R. (2011). Five steps to safer surgery. <em>Annals of the Royal College of Surgeons of England</em>, <em>93</em>(7), 501&ndash;503. <a href="https://doi.org/10.1308/147870811X599334">https://doi.org/10.1308/147870811X599334</a></p>
                        <p>Zejnullahu, V. A., Bicaj, B. X., Zejnullahu, V. A., &amp; Hamza, A. R. (2017). She retained Surgical Foreign Bodies after Surgery. <em>Open access Macedonian Journal of Medical Sciences</em>, <em>5</em>(1), 97&ndash;100. <a href="https://doi.org/10.3889/oamjms.2017.005">https://doi.org/10.3889/oamjms.2017.005</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Bhafpx4010assessment4
