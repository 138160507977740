import React from 'react';
import {FaRegArrowAltCircleRight, FaPhoneAlt} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Bannerfour = () => {
  return (
    <>
      <div className='ocs2-bannerone'>
        <div className='ocs2-bannerone-image'>
          <img src='images/bannerone.png' alt='' className='ocs2-bannerone-image-box'/>
        </div>
        <div className='ocs2-bannerone-detail'>
          <p className='ocs2-bannerone-detail-title'>GET ACADEMIC SUCCESS WITH OUR ASSISTANCE SERVICES <br/></p>
          <p className='ocs2-bannerone-detail-text'>Don't allow difficult coursework to prevent you from succeeding. Utilize our online course services to receive professional advice and improve your performance in the Capella MSN FlexPath program.  Get high grades now and start along the path to a better future!</p>
          <div className='ocs2-orderNow-box'>
          <Link to={"/order"} className='ocs2-orderNow-btnn'><button className='ocs2-orderNow'>Order Now &nbsp;<FaRegArrowAltCircleRight/></button></Link>
          <img src='images/time.png' alt='' className='ocs2-orderNow-time'/>
          <div className='ocs2-bannerone-phoneNo'>
            <FaPhoneAlt className='ocs2-bannerone-phoneNo-icon'/>
            <span>
              <p>Call us at</p>
              <strong>+1(669) 209-0161</strong>
            </span>
          </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default Bannerfour
