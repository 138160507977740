import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Dnp845week8assignment1 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>DNP 845 Week 8 Assignment 1 Implementing Evidence</title>
                <meta name='description'
                    content='Learn key strategies in DNP 845 Week 8 Assignment 1 Implementing Evidence Practice to improve healthcare outcomes. Start improving care now!' />
                <meta name='keywords' content='DNP 845 Week 8 Assignment 1 Implementing Evidence' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>DNP 845 Week 8 Assignment 1 < br /><span>Enhancing Healthcare Quality: A Programmatic Approach</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Dnp845week8assignment1.webp" alt="DNP 845 Week 8 Assignment 1 Enhancing Healthcare Quality: A Programmatic Approach" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>DNP 845 Week 8 Assignment 1 Enhancing Healthcare Quality.</strong></em></li>
                        </ul>
                        <h2>Disseminating Findings</h2>
                        <p>Quality improvement enables better patient care and ensures thriving in healthcare. Besides, care coordination across healthcare settings is developed through Comprehensive Unit-based Security Programs. The review aims to explore the plausible elements for implementing the program and emphasizes rigorous evaluation in selecting its plausibility. Accordingly, as the goal of DNP 845 Week 8 Assignment 1 Improving Healthcare Quality states, we build on foundations and resources from the valid literature which helps us discover the suitable means of utilizing CUSP in building up healthcare outcomes.</p>
                        <h3>Knowledge Dissemination</h3>
                        <ul>
                            <li>
                                <h3>Enhancing Healthcare Coordination Practices</h3>
                            </li>
                        </ul>
                        <p>AHRQ's CUSP was used for the quality improvement initiative to advance the coordination of care in healthcare. The selected quality topic is the necessity for substantial regions of robust provider coordination. Strong delivery of task outcomes and data is crucial for widespread adoption and awareness of execution (Fischer et al., 2020). The dissipating strategy selects channels accessible to healthcare experts, politicians, and scholars who could improve these practices.</p>
                        <h3>Platforms for Knowledge Dissemination</h3>
                        <h4>Platform 01: Professional Healthcare Conferences</h4>
                        <p>&bull; <strong>Rationale:</strong> Conferences bring physicians, heads, and researchers together to share revelations and best practices. They involve eye-to-eye conversations, systems organization, comprehensive venture results, and key presentations.</p>
                        <p>&bull; <strong>Example:</strong> The American Emergency Social Class Association and Foundation for Healthcare Improvement's annual conferences are bona fide (Fischer et al., 2020). These conferences attract patient care and quality improvement-prepared professionals, making them ideal for exchanging CUSP implementation expertise.</p>
                        <h4>Platform 02: Reviewed Journals by Peer</h4>
                        <p>&bull; <strong>Rationale:</strong> Peer-reviewed courses guarantee to illuminate carefulness and realness for CUSP implementation results and systems. This platform targets evidence-based prescription allies who can influence clinical recommendations and healthcare regulation.</p>
                        <p>&bull; <strong>Example:</strong> JAMA and Accomplishment Issues are two respected clinical journals that can express endeavor results, viewpoints, and evaluations expected to change healthcare practices (Pascual et al., 2023).</p>
                        <h4>Platform 03: Workshops and Seminars</h4>
                        <p>&bull; <strong>Rationale:</strong> Direct Contact Workshops and seminars either in healthcare working circumstances or as a feature of informational exercises offer direct relationships with physicians. It is during such social gatherings that the workforce can show the CUSP program, outline project-setting-centered analyses, and answer different feedback forms.</p>
                        <p>&bull; <strong>Example:</strong> Errand clinical fixations or healthcare structures can have workshops (Hampton et al., 2021). These may be remembered as professional procedures for preparing exercises to draw in people who can use the information at work.</p>
                        <h4>Platform 04: Webinars and Online Platforms</h4>
                        <p>&bull; <strong>Rationale:</strong>Advanced platforms are advantageous and open, particularly for decorations that cannot go to eye-to-eye occasions because of time or distance. Recording and on-demand webinars expand the undertaking's reach.</p>
                        <p>&bull; <strong>Example:</strong> Zoom or WebEx webinars and release recordings on LinkedIn or the Healthcare Development Organization as indicated by Oloyede et al., (2021), a dedicated undertaking site or electronic repository where users can access the project reports, instrument compartments, and updates would also be helpful.</p>
                        <h3>Platform Selection Reasons</h3>
                        <p>The platforms utilize masterpieces and new correspondence channels for broad thought and receptiveness.</p>
                        <p>They serve a couple of healthcare accomplices:</p>
                        <p>&bull; Peer-reviewed journals and Professional Conferences focus on individuals at the forefront of healthcare conveyance and framework making, guaranteeing the drive influences important managers and thought pioneers.</p>
                        <p>&bull; Attendants, physicians, and clinical focus managers can apply and get quick feedback from workshops and webinars.</p>
                        <ul>
                            <li>
                                <h3>Promoting Effective Care Coordination</h3>
                            </li>
                        </ul>
                        <p>Awareness of the CUSP quality improvement drive for the nonappearance of solid care coordination among suppliers is crucial for its flourishing and healthcare's continuous learning and progress (Oloyede et al., 2021). The venture can accomplish expansive acceptance and work on patient care and security throughout the healthcare structure by carefully picking platforms that meet its concentrations and decorations' demands. This dissemination process promotes sound healthcare quality improvement by making the venture's benefits extend to all stakeholders concerned.</p>
                        <h3>Evidence-based Practice Research</h3>
                        <p>I attested based practice research in module 3 evaluation and scholastic sources to help the procedures and findings in my quality improvement with entrusting to enhance care coordination through the Comprehensive Unit-based Flourishing Structure (CUSP) for the issue of nonappearance of successful care coordination among suppliers. A complete analysis of these sources and their significance to my venture is shown by the accompanying:</p>
                        <p>&bull; Brach et al. (2020) expressed that succeeding coaching is crucial for open and successful healthcare, and the Work Environment for Healthcare Research and Quality (AHRQ) stays aware of it. Understand accomplishment coaching for any quality improvement drive, especially yours, that brilliant lights on care transitions when clear correspondence and patient comprehension are focal.</p>
                        <p>&bull; The review of the literature by Krishnasamy et al., 2023, sheds light on how CUSP could reduce clinical focus falls. It shows the way CUSP can be applied to handle unequivocal accomplishment challenges in healthcare settings, basic in your errand of enhancement with transition security and coordination.</p>
                        <p>&bull; Patil et al. (2024) provide extended-length thriving implications for OSA patients and put into perspective the findings of AHRQ. The emphasis is on quality improvement processes in tireless disease management, and this remains sensitive to your venture to improve the coordination of care in managing patient outcomes.</p>
                        <p>&bull; Sharma et al. (2021) Though generally related to population health, this article shows the contribution of strategic structures to the outcomes, an analogous approach that CUSP has to health care with regard to care coordination-management of resources.</p>
                        <p>&bull; K Foreman, V. Barba, and K. Robey, (2020): This article emphasizes high reliability through data-driven quality improvement with a focus on specialty clinical. The article shows how data is able to change the culture in healthcare and its quality; this is significant for your project in moving security through predictable improvement using CUSP.</p>
                        <p>&bull; Braun et al. (2020) argue that a thriving culture influences defilement prevention and clinical results, as analyzed in this article. A thriving CUSP culture can moreover develop care transitions and coordination for your undertaking, increasing patient results.</p>
                        <p>&bull; M. Buljac-Samardzic et al. (2020), This critical analysis of healthcare pack plentifulness treatments reveals the best ways to deal with advancing assistance and correspondence, dire CUSP program parts. These experiences could assist you with changing your venture to draw in quality improvement through a bunch of parts.</p>
                        <p>&bull; Burgener (2020) articulates that the prime importance of effective communication processes in managing patient safety and satisfaction during transitions of care makes it relevant to your project.</p>
                        <p>&bull; Chang et al. (2019) present a multisite organizations' review on how to prevent contamination in the development of hearts. This shows serious areas of strength for how improvement functions and gives a point of view to extra-developing care coordination across office settings.</p>
                        <p>&bull; Burgener 2020 sponsors the undertaking's enhancement on extra developing healthcare supplier-patient joint efforts during care transitions, which is significant for care coordination. This enhancement includes the requirement for better correspondence to advance patient fulfillment and flourishing.</p>
                        <p>These regions figure out healthcare care coordination burdens and arrangements. They recommend using CUSP to address these issues with evidence, emphasizing the need for deliberate, bunch-based, and correspondence-centered approaches to managing to work on patient accomplishment and care quality. In alignment with the objectives of DNP 845 Week 8 Assignment 1 Enhancing Healthcare Quality, the venture uses these experiences to enhance care coordination purposefully and capably to manage patient results and healthcare conveyance.</p>
                        <h3>Successful Implementation</h3>
                        <p>A cognizant methodology with different procedures and systems ought to spread out whether the Comprehensive Unit-based Security Program (CUSP) for the issue of nonattendance of successful care coordination among providers was unsuccessful. Setting benchmarks before implementation, monitoring measurements mainly through implementation, and studying influence after implementation is crucial. This expansive assistant researches how to study CUSP implementation accomplishment:</p>
                        <h3>Setting Baseline Data</h3>
                        <p>CUSP implementation requires baseline care coordination and patient security data. This data can consolidate unpleasant events, patient satisfaction, correspondence failures, prescription misunderstandings, and readmissions (Oloyede et al., 2021). A baseline obliges a direct evaluation following CUSP transporting off the review to see whether gains have been made and then again if the circumstance has debilitated.</p>
                        <h3>Clear Goals and Benchmarks</h3>
                        <p>To use CUSP successfully, recognize goals in advance. For example, reduce disagreeable event rates by a predetermined degree, work on patient satisfaction, or lower readmission rates. Clear, quantifiable goals help reasonably study project accomplishment.</p>
                        <h3>Continuous Monitoring and Data Collection</h3>
                        <p>Continuous monitoring is pivotal throughout the foundation. CUSP constantly accumulates flourishing occurrence reports, patient remarks, and healthcare staff interchanges to reach a higher level. Reviews and registrations help spot discrepancies quickly.</p>
                        <h3>Professional and patient feedback</h3>
                        <p>Feedback from healthcare professionals and patients on the changes could reveal what is not working. Studies, parties, and figure get-togethers can store theoretical data that quantitative measurements cannot. Feedback concerning additional tangles, deficiency, or distress recommends that CUSP implementation fail.</p>
                        <h3>Comparative Analysis</h3>
                        <p>A pre-and post-CUSP data evaluation should be done after a substantial time. This analysis will reveal whether benchmarks were met (Fischer et al., 2020). Enduring data shows no improvement, measurements have declined, and the sending failed.</p>
                        <h3>Root Cause Analysis</h3>
                        <p>A root cause analysis is fundamental if the essential evaluation shows implementation failure. This requires investigating why CUSP failed. It might be because of needing more preparation, staff reluctance, nonappearance of resources, or work process interweaving (Oloyede et al., 2021). Understanding these factors is fundamental for future arrangements.</p>
                        <h3>External Factors Review</h3>
                        <p>Changes in health care arrangements, patient demography, or advancement may affect the implementation of CUSP. These factors can make it fail in implementation, hence giving a realistic view of the outcome.</p>
                        <h3>Expert Consultation</h3>
                        <p>Agree with the irritation of measurement of the implementation success may attract external experts who have successfully taken up CUSP elsewhere to give new insights. Such experts may irritate common mistakes and strategies that may display the details that may be overlooked during its implementation.</p>
                        <h3>Develop an Improvement Plan</h3>
                        <p>After monitoring, feedback, and analysis will come the need for improvement. The plan should identify challenges that involve the re-implementation of the revised CUSP procedure (Fischer et al., 2020).</p>
                        <h3>Re-evaluation</h3>
                        <p>The improvement plan should be reviewed after implementation to see whether it worked. This recurrent perspective advances continuous quality improvement in healthcare, adapting to changing requirements and inconveniences.<br />Following these procedures, healthcare organizations can review CUSP implementation progress and recognize areas for improvement to improve care coordination and patient security.</p>
                        <h2>Conclusion</h2>
                        <p>Quality improvement drives like CUSP ought to be surveyed to enhance healthcare. This cycle is essential to setting goals, reviewing progress, and getting partner data. When improvements miss the mark, a precise evaluation to reveal focal reasons and a significant structure for correction is required. This cycle arrangement of assessment and improvement helps healthcare organizations develop care coordination and creates an environment that changes with patients' and healthcare laborers' changing necessities. CUSP can be used perseveringly and reassessed to achieve key patient thriving and care quality requirements.</p>
                        <h2>References</h2>
                        <p>Barba, V., Foreman, K., &amp; Robey, K. (2020). Data-driven quality improvement, culture change, and the high-reliability journey at a special hospital for people with medically complex developmental disabilities. <em>International Journal of Healthcare Management</em>, <em>3</em>(2), 1&ndash;7.</p>
                        <p>https://doi.org/10.1080/20479700.2020.1721747</p>
                        <p>Brach, C., &amp; Borsky, A. (2020). How the U.S. Agency for Healthcare Research and Quality promotes health literate health care. <em>Studies in Health Technology and Informatics</em>, <em>269</em>(3), 313&ndash;323.</p>
                        <p>https://doi.org/10.3233/SHTI200046</p>
                        <p>Braun, B. I., Chitavi, S. O., Suzuki, H., Soyemi, C. A., &amp; Puig-Asensio, M. (2020). Culture of safety: Impact on improvement in infection prevention process and outcomes. <em>Current Infectious Disease Reports</em>, <em>22</em>(12).</p>
                        <p>https://doi.org/10.1007/s11908-020-00741-y</p>
                        <p>Buljac-Samardzic, M., Doekhie, K. D., &amp; van Wijngaarden, J. D. H. (2020). Interventions to improve team effectiveness within health care: A systematic review of the past decade. <em>Human Resources for Health</em>, <em>18</em>(1).</p>
                        <p>https://human-resources-health.biomedcentral.com/articles/10.1186/s12960-019-0411-3</p>
                        <p>Burgener, A. M. (2020). Enhancing communication to improve patient safety and to increase patient satisfaction. <em>The Health Care Manager</em>, <em>39</em>(3), 128&ndash;132.</p>
                        <p>https://doi.org/10.1097/hcm.0000000000000298</p>
                        <p>Chang, B. H., Yea Jen Hsu, Rosen, M. A., G&uuml;rses, A. P., Shu Tzu Huang, Xie, A., Speck, K., Marsteller, J. A., &amp; Thompson, D. A. (2019). Reducing three infections across cardiac surgery programs: A multisite cross-unit collaboration. <em>American Journal of Medical Quality</em>, <em>35</em>(1), 37&ndash;45.</p>
                        <p>https://doi.org/10.1177/1062860619845494</p>
                        <p>Fischer, C., &amp; Wick, E. (2020). An AHRQ national quality improvement project for implementation of enhanced recovery after surgery. <em>Seminars in Colon and Rectal Surgery</em>, <em>3</em>(2), 100778.</p>
                        <p>https://doi.org/10.1016/j.scrs.2020.100778</p>
                        <p>Hampton, R., &amp; Woods, T. M. (2021). Standardizing and improving the education of pharmacy students in a large health system. <em>American Journal of Health-System Pharmacy</em>, <em>78</em>(8), 666&ndash;668.</p>
                        <p>https://doi.org/10.1093/ajhp/zxab047</p>
                        <p>Krishnasamy, K., Tan, P. M., Zakaria, M. I., &amp; Chinna, K. (2023, July 3). <em>A Comprehensive Unit-Based Safety Intervention Program for the Prevention of Falls in Hospital</em>. Preprints.org.</p>
                        <p>https://doi.org/10.20944/preprints202307.0117.v1</p>
                        <p>Oloyede, A. A., Faruk, N., &amp; Raji, W. O. (2021). COVID-19 lockdown and remote attendance teaching in developing countries: A review of some online pedagogical resources. <em>African Journal of Science, Technology, Innovation and Development</em>, <em>3</em>(2), 1&ndash;19.</p>
                        <p>https://doi.org/10.1080/20421338.2021.1889768</p>
                        <p>Pascual, M. G., Morris, M. A., &amp; Kohn, L. L. (2023). Publication trends of qualitative research in dermatology: A scoping review. <em>Journal of American Medical Association Dermatology</em>, <em>159</em>(6), 648&ndash;658.</p>
                        <p>https://doi.org/10.1001/jamadermatol.2023.0839</p>
                        <p>Patil, S. P., Billings, M. E., Ghada Bourjeily, Collop, N. A., Gottlieb, D. J., Johnson, K. G., R. John Kimoff, &amp; Pack, A. I. (2024). Long-term health outcomes for patients with obstructive sleep apnea: placing the agency for healthcare research and quality report in context&mdash;a multi-society commentary. <em>Journal of Clinical Sleep Medicine</em>, <em>20</em>(1), 135&ndash;149.</p>
                        <p>https://doi.org/10.5664/jcsm.10832</p>
                        <p>Sharma, H. B., Vanapalli, K. R., Samal, B., Cheela, V. R. S., Dubey, B. K., &amp; Bhattacharya, J. (2021). The circular economy approach in solid waste management system to achieve UN-SDGs: Solutions for post-COVID recovery. <em>Science of the Total Environment</em>, <em>800</em>(3), 149605.</p>
                        <p>https://www.sciencedirect.com/science/article/pii/S0048969721046805</p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Dnp845week8assignment1
