import React from 'react'
const Whatsapp = () => {
  return (
    <div className='whatsapp-icon'>
      <a target='blank' href='https://wa.me/+14082149759'>
        <i class="fa-brands fa-square-whatsapp"></i>
      </a>
    </div>
  )
}
export default Whatsapp