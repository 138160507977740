import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvide } from "./context/authContext";
import Whatsapp from "./components/Whatsapp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AuthContextProvide>
            <App/>
            <Whatsapp/>
        </AuthContextProvide>
    </React.StrictMode>
)