import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx8030assessment3 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 8030 Assessment 3 Critical Appraisal</title>
                <meta name='description'
                    content="Explore NURS FPX 8030 Assessment 3 Critical Appraisal of Evidence-Based Literature and discover interventions to improve patient safety. Learn more!" />
                <meta name='keywords' content='NURS FPX 8030 Assessment 3 Critical Appraisal of Evidence-Based Literature' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 8030 Assessment 3 < br /><span>Critical Appraisal of Evidence-Based Literature</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursfpx8030assessment3.webp" alt="NURS FPX 8030 Assessment 3 Critical Appraisal of Evidence-Based Literature" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong><span data-preserver-spaces="true">NURS FPX 8030 Assessment 3 Critical Appraisal of Evidence-Based Literature.</span></strong></em></li>
                        </ul>
                        <h2><span data-preserver-spaces="true">Introduction</span></h2>
                        <p><span data-preserver-spaces="true">One of the ordinary annoyances in clinical thought and nursing is remaining mindful of staffing levels. Conclusively, patient ideas can be influenced when clinical directors are staffed to a degree of monitoring. The weakness of staying aware of fitting levels can influence patient accomplishment and result in broadened patient falls, catheter-related urinary social event defilements, and clinical office pressure wounds. </span></p>
                        <p><span data-preserver-spaces="true">Patient falls, CAUTI, and HAPIs are connected with immense expenses for emergency working environments since the emergency place is liable for all costs related to these patient accomplishment occasions. Solid interventions should be finished to guarantee to remain mindful of levels of 1:6 for careful clinical units in the middle setting.&nbsp;Explore our assessment <a href="https://onlinecourseservices.us/nurs-fpx-8030-assessment-2-evidenced-based">NURS FPX 8030 Assessment 2 Evidenced-Based Literature Search and Organization</a>for more information.</span></p>
                        <h2><span data-preserver-spaces="true">PICOT Question</span></h2>
                        <p><span data-preserver-spaces="true">In an office setting with clinical guards and patients (P), how do safe staffing levels of 1:6 (I) and unsafe staffing levels of under 1:6 (C) influence patient fulfillment scores, patient falls, Cauti's, and HAPI's (O)?</span></p>
                        <h2><span data-preserver-spaces="true">Thesis Statement</span></h2>
                        <p><span data-preserver-spaces="true">Nursing staff needs horrendously influence patient achievement by diminished patient fulfillment, expanded patient falls, Cauti's, and Hapi's.</span></p>
                        <h2><span data-preserver-spaces="true">Critical Appraisal Tool</span></h2>
                        <p>The critical appraisal tool used is the JBI Plan for Key Outlines, which will help assess articles chosen for this review. The NURS FPX 8030 Assessment 3 Critical Appraisal of Evidence-Based Literature emphasizes the use of the JBI Plan to maintain the significant thought of the appraisals and determine whether any penchant is present in their strategy, direction, and assessment (Melnyk &amp; Fineout-Overholt, 2019). This appraisal tool was singled out because the researched assessments are generally gifted examinations. Deliberate assessments provide the best certification for many questions since their revelations depend on an expansive and exact forming look with changed appraisals.</p>
                        <h2><span data-preserver-spaces="true">Annotative Bibliography</span></h2>
                        <p><span data-preserver-spaces="true">Al Muharaaq, E. H., Alallah, S., M., Alkhayrat, S. A., and Jahlan, A. G. (2022). An arrangement of missed nursing care and its pointers in Saudi Arabia: A cross-sectional survey. Nursing Evaluation and Practice, 2022. <a href="https://doi.org/10.1155/2022/4971890">https://doi.org/10.1155/2022/4971890</a></span></p>
                        <p><span data-preserver-spaces="true">This study is a significant system that ganders at the missed nursing care and its pointers and is a quantitative, cross-sectional overview. This study used the MISSCARE frame and dismantled 604 staff clinical guards working on figuring outwards in Saudi Arabia (Al Muharaaq et al., 2022). This study shut a mean of missed nursing care and saw what nursing care rehearses were missed with feebleness to go to interdisciplinary idea conferences and patient ambulation on different occasions, dependably being the most missed works out. </span></p>
                        <p><span data-preserver-spaces="true">Delicacy in attending mind-plan parties is determinantal in arranging the ideas for the patient while they are hospitalized and can provoke missed care. Neglecting to move around a patient on different occasions dependably can instigate nonattendance of adaptability and remain in bed for a lengthy time, which prompts focus gained pressure wounds. This assessment found that missed nursing care can impact turnover and lower work fulfillment with clinical escorts. This appraisal found that improving the nursing decision cycle, asset tasks, and helpful experts' upkeep programs were the proposed plans that could prompt reduced missed nursing care.</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Ensuring Research Evaluation Quality</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">This appraisal reminded me of the evaluation's legitimacy, consistent quality, versatility, confirmability, and reflexivity. A genuine construction of huge worth is shown by the scientist's use of the review tool while getting together assessments from the clinical gatekeepers. Counting the Strategy for Enormous Plan, the quality is guaranteed in that it unequivocally conveys the audit question, utilizes authentic idea rules, uses fitting ways of thinking to look, has an OK central focus for studies to look, and accurate models to survey the appraisals watched out for. </span></p>
                        <p><span data-preserver-spaces="true">The article is suitable for emphasizing nursing staff insufficiencies and their effect on sorting out security.&nbsp;</span><span data-preserver-spaces="true">Disch, J., and Finis, N. (2022). Reexamining nursing efficiency: An assessment of the piece and get-togethers with thought pioneers. Nursing Financial Perspectives, 40(2), 59-71.&nbsp;</span><span data-preserver-spaces="true">This study is an exact survey of nursing adequacy and results from parties of clinical advantages bosses and thought pioneers concerning difficulties in overhauling nursing capacity. As the hypothesis related to nursing sensibility shows, the level of limited work, materials, and stuff changed over time spent on work and other things (Edwardson, 1985).</span></p>
                        <h2><span data-preserver-spaces="true">NURS FPX 8030 Assessment 3 Critical Appraisal of Evidence-Based Literature</span></h2>
                        <p><span data-preserver-spaces="true">This mirrors the limit of a party of clinical bosses or a specific expert's efficiency. The creators used OVID Medline and CINAHL enlightening assortments in May 2021 as they continued looking for nursing capability. Results showed that clinical supervisors' quantifiable impact on positive patient results. The study revealed a few nursing sensibilities and staffing approaches reliably utilized to solidify cunning-based staffing, HR, and worth-based.</span></p>
                        <p><span data-preserver-spaces="true">The Strategy for Intentional Audit was utilized, and this evaluation remained mindful of its authenticity, relentless quality, confirmability, adaptability, and reflexivity. The appraisal surveyed the opportunity of the survey and its methodology. The audit is fundamental to assessing nursing setup as it keeps one mindful of the quantifiable effect of nursing capability and positive patient results.&nbsp;</span><span data-preserver-spaces="true">Kowalski, M. O., Basile, C., Bersick, E., Cole, D. A., McClure, D. E., &amp; Weaver, S. H. (2020). What do clinical orderlies have to rehearse in the work environment climate? An integrative review with contemplations for help pioneers. Perspectives on Attestation Based Nursing, 17(1), 60&ndash;70. <a href="https://doi.org/10.1111/wvn.12401">https://doi.org/10.1111/wvn.12401</a></span></p>
                        <p><span data-preserver-spaces="true">This study contained 25 articles that were concentrated on an unequivocal cycle.&nbsp;</span><span data-preserver-spaces="true">The point was to look at the checks concerning clinical guards and what they report they need to administer nursing productively in the super thought clinical focus climate. Enlightening courses of action were used in the search for making, including PubMed, CINAHL, Johanna Briggs, and the Sigma Theta Tau Henderson Library. Express mentioning rules and the Johns Hopkins nursing rules and tools were used to see related studies. </span></p>
                        <p><span data-preserver-spaces="true">Twenty articles were checked for Level III, and five were investigated for Level II. The five key assessments were power, opportunity/course, regard/coordinated effort, assets/staffing, and affiliation obligation to nursing. This survey examined the experts' encounters collaborating with what is by and large anticipated to give appropriate quality ideas and saw that there are multifactorial layers to giving quality ideas. They pondered that clinical escorts should be given the sensible assets and foundation to manage their obligations, in all actualities,y and should be mindful of the critical drive (Kowalski et al., 2020).</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Impact of Staffing Ratios</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">The cases in this paper remain mindful of the revelations showing that expecting clinical escorts are mindful of authentic assets, solidifying staffing degrees, they can work, and positive patient results. Counting the Blueprint for Important Review, this article was mentioned. It is critical to constantly concentrate as it stays mindful of the prerequisites for rich staffing degrees to have positive patienfulfillmentnt and regulate unsurprising thriving.&nbsp;</span><span data-preserver-spaces="true">McHugh, M. D., Aiken, L., H., Sloane, D. M., Windsor, C., Douglas, C., and Yates, P. (2021).</span></p>
                        <p>&nbsp;<span data-preserver-spaces="true">Effects of clinical manager to-patient degree rule on help staffing and patient mortality, readmissions, and length of stay: a coordinated report in an essential get-together of focus interests. The Lancet, 397(10288), 1905-1913. <a href="https://doi.org/10.1016/s0140-6736(21)00768-6">https://doi.org/10.1016/s0140-6736(21)00768-6</a></span></p>
                        <p><span data-preserver-spaces="true">This promising board study was semi-exploratory. It considered changes in results where clinical center errands appeared differently, standing out from the treatment condition of the patients non-irregularly. Support revealed that data was in a clinical wary unit concerning staffing levels and standardized patient data for survey results.&nbsp;</span><span data-preserver-spaces="true">The design was bound and confined to staff specialists in a clinical care unit, and escorts gave direct instructions. Two crisis working conditions were checked out: one utilized the course of action intervention of safe staffing degrees, and the second clinical office did not execute the degrees. </span></p>
                        <p><span data-preserver-spaces="true">This study focused on three significant questions: the first was to answer whether changes in staff nursing degrees were different between the two crisis workplaces, whether changes in calm outcomes were different among centers, and the third, whether setting up changes was associated with varying outcomes of the patient between the workspaces. Clinical guardians at the bedside were investigated for hoarding data. This yielded data on setting up levels with validity. ]</span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Nurse-to-Patient Ratio Benefits</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">After the data was collected, a veritable assessment was performed. The data stayed aware of the conclusion that having many escorts with reasonable positions was fitting for patient thought and results. The results supported that a base clinical controller to-patient level of 1:6 is conceivable and further encourages help to staff, produces better calm outcomes, and is a fair money-related benefit for the crisis place (McHugh et al., 2021). </span></p>
                        <p><span data-preserver-spaces="true">The chart is peer-evaluated, circumnavigated in the last five years, fitting the steady outline, and comparably certified. It is a serious area of strength for comparably and confirmably; along these lines, the quality is ensured.&nbsp;</span><span data-preserver-spaces="true">Wilhelmina, F. J. M. vanStrengthh, Oetelaar, Roelen, C. A. M., Grolman, W., Stellato, R. K., and Willem, v. R. (2021). Investigating the relationship among them showed and saw the commitment of clinical orderlies, related work demands, and work and individual resources in</span><span data-preserver-spaces="true">a longitudinal report. </span><em><span data-preserver-spaces="true">PLoS One</span></em><span data-preserver-spaces="true">, </span><em><span data-preserver-spaces="true">16</span></em><span data-preserver-spaces="true">(2). <a href="https://doi.org/10.1371/journal.pone.0246658">https://doi.org/10.1371/journal.pone.0246658</a></span></p>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Optimizing Nurse Workload Balance</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">This study was a longitudinal report that pre-owned data assembled more than 15 unending day shifts in six careful wards, each with the constraint of 15-30 beds. The questionnaires explored the relationship between commitment and saw hazards of clinical heads related to their work demands, work resources, and individual resources. Both the shower and saw liabilities were framed reliably. ANOVA and post-hoc tests were used to see contrasts between the two positions. Huge partitions showed liability associated with the five irrefutably joint guarantees (work pace, level of work, mental weight, astoundingly close weight, and substantial weight). </span></p>
                        <p><span data-preserver-spaces="true">The openings show how the nursing board can give out resources to change the commitment of the clinical guards overall and lead to extra results. The review included 351 encounters, which were both objective and enormous. The survey is peer-assessed, scattered over the last five years, and is reasonable for constant review; as such, it is dependable. It is additionally dependable, transferrable, and confirmable; in this manner, the quality is ensured. </span></p>
                        <p><span data-preserver-spaces="true">The divulgences of this assessment can help the board when resources are conveyed and recognized, relying on the </span><span data-preserver-spaces="true">essential</span><span data-preserver-spaces="true"> parts, which is changing the commitment of orderlies so they could give more extraordinary care, less horrendous approach to acting and turnover of clinical escorts.</span></p>
                        <h2><span data-preserver-spaces="true">Proposed Intervention</span></h2>
                        <p><span data-preserver-spaces="true">Various interventions have been proposed to improve staffing degrees, including changing liability (Wilhelmina et al., 2021). Multiple interventions were proposed to enhance the nursing decision cycle, resource tasks, and convincing clinical orderlies' assistance programs, which were proposed approaches that could lessen missed nursing care (Al Muharaaq et al., 2022). Further, nursing staffing will draw more attention to understanding satisfaction scores and declining patient falls, Cauti's, and HAPI's since the liability of the clinical escorts will be changed. Minds will not be missed (Wilhelmina et al., 2021).</span></p>
                        <h2><span data-preserver-spaces="true">Conclusion</span></h2>
                        <p>Nursing staff needs significantly impact patient security by reducing patient satisfaction and extending patient falls, as well as Cauti's and Hapi's. The <a href="https://www.writinkservices.com/nurs-fpx-8030-assessment-3-critical-appraisal-of-evidence-based-literature/">NURS FPX 8030 Assessment 3 Critical Appraisal of Evidence-Based Literature</a> highlights open assessments that propose several interventions for improving nursing staff needs, including creating liability. This study focuses on implementing advancements in the nursing choice cycle, resource dissemination, and accommodating escort retention programs. These interventions will improve staffing degrees, which, as shown, will enhance patient safety by reducing falls, decreasing Cauti's, and diminishing Hapi's.</p>
                        <h2>References</h2>
                        <p>Al Muharaaq, E. H., Alallah, S., M., Alkhayrat, S. A., &amp; Jahlan, A. G. (2022). An overview of missed nursing care and its predictors in Saudi Arabia: A cross-sectional study. <em>Nursing Research and Practice, </em>2022. <u><a href="https://doi.org/10.1155/2022/4971890">https://doi.org/10.1155/2022/4971890</a></u></p>
                        <p>Disch, J., &amp; Finis, N. (2022). Rethinking nursing productivity: A literature review and interviews with thought leaders. <em>Nursing Economics, 40</em>(2), 59-71. Edwardson, S. (1985). Measuring nursing productivity. <em>Nursing Economics, 3</em>(1), 9-14.</p>
                        <p>Fildes, C., Munt, R., &amp; Chamberlain, D. (2022). Impact of dual intensive care unit and rapid response team nursing roles on service delivery in the intensive care unit. <em>Critical Care Nurse, 42</em>(5), 23-31. <u><a href="https://doi.org/10.4037/ccn2022540">https://doi.org/10.4037/ccn2022540</a></u></p>
                        <p>Kowalski, M. O., Basile, C., Bersick, E., Cole, D. A., McClure, D. E., &amp; Weaver, S. H. (2020). What do nurses need to practice effectively in the hospital environment? An integrative review with implications for nurse leaders. <em>Worldviews on Evidence-Based Nursing, 17</em>(1), 60-70. <u><a href="https://doi.org/10.1111/wvn.12401">https://doi.org/10.1111/wvn.12401</a></u></p>
                        <p>McHugh, M. D., Aiken, L., H., Sloane, D. M., Windsor, C., Douglas, C., &amp; Yates, P. (2021). Effects of nurse-to-patient ratio legislation on nurse staffing and patient mortality, readmissions, and length of stay: a prospective study in a panel of hospitals. <em>The Lancet, 397</em>(10288), 1905-1913. <u><a href="https://doi.org/10.1016/50140-6736(21)00768-6">https://doi.org/10.1016/50140-6736(21)00768-6</a></u></p>
                        <p>Melnyk, B. M., &amp; Fineout-Overholt, E. (2019). <em>Evidence-based practice in nursing and Healthcare </em>(4<sup>th</sup> ed.). Wolters Kluwer Health.</p>
                        <p>Wilhelmina, F. J. M. van den, Oetelaar, Roelen, C. A. M., Grolman, W., Stellato, R. K., &amp; Willem, v. R. (2021). Exploring the relation between modeled and perceived workload of nurses and related job demands, job resources and personal resources; a longitudinal study. <em>PLoS One, 16</em>(2). <u><a href="https://doi.org/10.1371/journal.pone.0246658">https://doi.org/10.1371/journal.pone.0246658</a></u></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx8030assessment3