import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const N547week2assignment = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>N547 Week 2 Assignment Healthcare Strategic</title>
                <meta name='description'
                    content="Explore N547 Week 2 Assignment on Healthcare Strategic Management and Planning. Learn key strategies to optimize healthcare performance." />
                <meta name='keywords' content='N547 Week 2 Assignment Healthcare Strategic' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>N547 Week 2 Assignment < br /><span>Healthcare Strategic Management and Planning</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/N547week2assignment.webp" alt="N547 Week 2 Assignment Healthcare Strategic " />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>N547 Week 2 Assignment Healthcare Strategic Management and Planning.&nbsp;</strong></em></li>
                        </ul>
                        <h2><strong>Healthcare Strategic Management and Planning</strong></h2>
                        <p>The competitive universe of healthcare needs an expansive handle of business frameworks that help organizations scratch by and flourish in maddening conditions. Understanding the adversaries and their inspirations is the initial step. Organizations should understand their clients' necessities, areas, and ways of life.</p>
                        <p>Following identifying these key factors, we investigate an Unnecessarily Lengthy Care Office for Ventilator-Dependent Patients to demonstrate how specific healthcare services could overcome their challenges and reach their full potential. This appraisal seeks to explore combining financial models, demographic changes, and communities to succeed in healthcare. The findings align with concepts covered in the<strong><em>N-547 Week 2 Assignment Healthcare Strategic Management and Planning</em></strong>, showcasing how effective planning and strategy are essential in overcoming healthcare challenges.</p>
                        <h2><strong>Categories in Competitor Analysis: Key Competitor Information</strong></h2>
                        <p>Strategic planning in every sector requires competitor study. Current and future adversaries' resources and shortcomings are evaluated (Ranjan et al., 2021). This study distinguishes likely outcomes and dangers from an offensive and watched strategic viewpoint. Key competitor information categories for this study include:</p>
                        <ul>
                            <li>
                                <h3><strong>Background and History</strong></h3>
                            </li>
                        </ul>
                        <p>Understanding a competitor's history, reason, and values could reveal its strategic goals and different evened-out culture.</p>
                        <ul>
                            <li>
                                <h3><strong>Products and Services</strong></h3>
                            </li>
                        </ul>
                        <p>Analyzing foes' range, quality, and originality assists in finding marketing openings and opportunities for progress or innovation in your offers.</p>
                        <ul>
                            <li>
                                <h3><strong>Market Share and Positioning</strong></h3>
                            </li>
                        </ul>
                        <p>Assessing a competitor's market share and positioning strategy (e.g., minimal expense pioneer, top-of-the-line provider) is crucial for understanding their industry influence and improving your positioning.</p>
                        <ul>
                            <li>
                                <h3><strong>State of Financial Health</strong></h3>
                            </li>
                        </ul>
                        <p>Financial statements and performance assessments, such as sales, profit margins, and improvement rates, could show a competitor's financial health and investment potential.</p>
                        <ul>
                            <li>
                                <h3><strong>Sales and Marketing Strategies</strong></h3>
                            </li>
                        </ul>
                        <p>Comparing adversaries' advertising, advancements, and sales procedures could uncover what works and does not in reaching the objective demographic.</p>
                        <ul>
                            <li>
                                <h3><strong>Operational Capabilities</strong></h3>
                            </li>
                        </ul>
                        <p>Understanding a competitor's manufacturing, inventory association, and dissemination proficiency could uncover competitive qualities and shortcomings.</p>
                        <ul>
                            <li>
                                <h3><strong>Technological Advancements</strong></h3>
                            </li>
                        </ul>
                        <p>Knowing how adversaries use technology for thing movement, customer service, and operational proficiency could help you identify areas where adopting innovations may be significant.</p>
                        <ul>
                            <li>
                                <h3><strong>Customer Base and Satisfaction</strong></h3>
                            </li>
                        </ul>
                        <p>Analyzing a competitor's buyers, satisfaction, and unwavering quality assessments could reveal market prerequisites and inclinations and how closely they are monitored (Ranjan et al., 2021).</p>
                        <h2><strong>Appropriateness of These Categories for Health Care Organizations</strong></h2>
                        <p>In health care, where patient flourishing is essential, these categories remain pertinent for certain subtleties:</p>
                        <ul>
                            <li>
                                <h3><strong>Regulatory Compliance</strong></h3>
                            </li>
                        </ul>
                        <p>Healthcare organizations should consent to several guidelines. Understanding foes' compliance efforts could uncover industry best practices and shortcomings.</p>
                        <ul>
                            <li>
                                <h3><strong>Quality of Care</strong></h3>
                            </li>
                        </ul>
                        <p>Quality, including patient results and happiness, is key in health care. Comparing adversaries' performance in these areas could assist you with improving your offerings (Hermes et al., 2020).</p>
                        <ul>
                            <li>
                                <h3><strong>Innovation in Treatment and Technology</strong></h3>
                            </li>
                        </ul>
                        <p>Staying before understanding care requires monitoring adversaries' innovation limits as clinical technology and treatment systems make.</p>
                        <ul>
                            <li>
                                <h3><strong>Partnerships and alliances</strong></h3>
                            </li>
                        </ul>
                        <p>Strategic alliances with research colleges or technology providers could help a healthcare connection's capabilities (Hermes et al., 2020). Understanding competitors' affiliations could give joint effort or hazard possibilities.</p>
                        <h2><strong>Focusing Information Gathering and Strategic Decision Making</strong></h2>
                        <h3><strong>Categories guide information gathering and strategic decision-making in the following ways:</strong></h3>
                        <ul>
                            <li>
                                <h4><strong>Opportunities and Threats Identifying</strong></h4>
                            </li>
                        </ul>
                        <p>Analyzing rivals across these areas assists firms with uncovering market openings, opportunities, and potential perils that demand strategic game plans (Alomari et al., 2020).</p>
                        <ul>
                            <li>
                                <h4><strong>Information Informing Strategic Priorities</strong></h4>
                            </li>
                        </ul>
                        <p>Understanding enemies' resources and shortcomings revolves around strategic goals, including thing redesigns, operational proficiency, and technology gathering.</p>
                        <ul>
                            <li>
                                <h4><strong>Guiding Resource Allocation</strong></h4>
                            </li>
                        </ul>
                        <p>Competitor analysis could assist with allocating marketing, Innovative work, and customer service resources to meet strategic goals.</p>
                        <ul>
                            <li>
                                <h4><strong>Enhancing Competitive Positioning</strong></h4>
                            </li>
                        </ul>
                        <p>By studying the opposition, organizations could push their positioning strategies to stand out and attract customers.</p>
                        <ul>
                            <li>
                                <h4><strong>Process of Driving Innovation</strong></h4>
                            </li>
                        </ul>
                        <p>Knowing opponents' merchandise, services, and focused capabilities could vitalize innovation and gather cutting-edge technology to remain competitive. Each affiliation seeking to protect or further cultivate its competitive benefit should do a dispute analysis using these information categories (Alomari et al., 2020). Adapting these categories to the healthcare industry's specific climate empowers more attractive and compelling strategic planning, improving patient care and actual performance.</p>
                        <h2><strong>Service Area</strong></h2>
                        <p>To fittingly disseminate resources and customize services to the general populace it serves, a healthcare institution should define its service area. Patients come from a clinical clinic's service area. It guides planning and operational decisions, including service offerings, marketing, and office movement. An undeniable service area assists healthcare businesses with understanding neighbourhood demographics, health necessities, and inclinations.</p>
                        <p>&nbsp;This information is crucial for specific reasons. It permits the relationship to give care that meets the local essential health needs, improving its importance and impact (Cheng et al., 2021). An ageing group could require more geriatric services, but a youthful family-filled region could require more pediatric and obstetric care. Knowing the service area distinguishes competitors.&nbsp;</p>
                        <p>Knowing the attributes and shortcomings of neighbouring healthcare suppliers could assist with distinguishing services, updating patient care, and drawing in additional patients (Wulandari et al., 2023). A service area administers resources. Over-or under-limit difficulties are avoided by changing the neighbourhood's hardware, infrastructure, and staff investments.</p>
                        <h2><strong>Managed Care Penetration</strong></h2>
                        <p>Managed care penetration dumbfounds and plans service area delineation. Managed care is a Healthcare development framework that controls cost, use, and quality through concurrences with suppliers and insurers. A high managed care penetration indicates that a colossal share of the general populace is joined up. This influences the definition of service area in various ways.</p>
                        <p>&nbsp;Patients in managed care organizations (MCOs) are urged or forced to use their supplier organizations. Patients could go past their region to use an MCO supplier, changing the service area borders (Wulandari et al., 2023). Hence, healthcare organizations should consider geographic closeness and managed care network reach while establishing service districts.</p>
                        <p>Managed care penetration influences service area demand. Different MCOs revolve around well-being measure care and have explicit supplier affirmations, treatment systems, and quality standards. High managed care penetration could request that healthcare organizations zero in additional on obstruction care, predictable sickness management, and flitting services to suit these essentials and inclinations (Franco Montoya et al., 2020).</p>
                        <p>&nbsp;Managed care game plans could hurt a healthcare institution financially. Suppliers in high-managed care areas could depend excitedly upon managed care contracts, which, as often as possible, have coordinated costs distinct from cost-for-service models. This could influence profitability and income, affecting service offerings, workforce, and technology investments.</p>
                        <p>Healthcare institutions should clearly define the service area to ensure their services meet the area and provide compelling, suitable, and fitting patient care. Managed care muddles this work by affecting the geographic definition of the service area and strategic decisions about service offerings, alliances, and financial management.&nbsp;</p>
                        <p>Any healthcare business that needs to win in a managed care framework should understand and address these parts.</p>
                        <p>This healthcare business centre involves a newly created Expanded Care Office for Ventilator-Subordinate Patients. The focus will be on long-term care for ventilator-subordinate patients with spinal injuries, COPD, and other serious respiratory problems.</p>
                        <h2><strong>Specify The Service Category</strong></h2>
                        <p>Ventilator-subordinate significant length care is our forte. Notwithstanding clinical and nursing treatment, this includes practice-based recuperation, respiratory therapy (Franco Montoya et al., 2020), and psychosocial support appointed to this patient.</p>
                        <p>Delineate the Service Area</p>
                        <ul>
                            <li>
                                <h3><strong>General Economics</strong></h3>
                            </li>
                        </ul>
                        <p>The service area is a city with manufacturing, services, and technology industries. Nearby cash-related consistency invigorates unequivocal healthcare services.</p>
                        <ul>
                            <li>
                                <h3><strong>Demographic</strong></h3>
                            </li>
                        </ul>
                        <p>As the service region ages, more senior individuals could require extended care. Unassumingly developed individuals with way of life-related health issues could become ventilator-subordinate.</p>
                        <ul>
                            <li>
                                <h3><strong>Psychographic</strong></h3>
                            </li>
                        </ul>
                        <p>The neighbourhood regards health and achievement and is ready to invest in extraordinary healthcare. Customized and empathetic treatment is appreciated for significant health concerns.</p>
                        <ul>
                            <li>
                                <h3><strong>Health Status</strong></h3>
                            </li>
                        </ul>
                        <p>The rising number of steady disorders, such as COPD and cardiovascular issues, increases the need for expanded care offices (Franco Montoya et al., 2020). The area contains different restoration institutes and clinics that could send patients for significant-length ventilator help.</p>
                        <h2><strong>Perform a Service Area Structure Analysis</strong></h2>
                        <h3><strong>Threat of New Entrants</strong></h3>
                        <p>New entrants are mitigated by the substantial financial utilization and express information expected to run a ventilator-subordinate broadened care facility. Regulatory constraints further ruin entrance.</p>
                        <ul>
                            <li>
                                <h4><strong>Power of Rivalry</strong></h4>
                            </li>
                        </ul>
                        <p>Rivalry is delicate, and barely any local broadened care organizations treat ventilator-subordinate patients. Because of the specialized treatment, comparable service suppliers cooperate rather than battle.</p>
                        <ul>
                            <li>
                                <h4><strong>Threat of Substitutes</strong></h4>
                            </li>
                        </ul>
                        <p>Alternatives like home health care are dangerous. Late advances in portable ventilator innovation allow several patients to search for treatment at home. This alternative is limited by the intricacy of care that ventilator-subordinate patients need.</p>
                        <ul>
                            <li>
                                <h4><strong>Power of Customers</strong></h4>
                            </li>
                        </ul>
                        <p>The popularity of specialized care and a set number of foundations impact patients and their families. Client maintenance depends on patient happiness and reputation.</p>
                        <p>Power of Suppliers</p>
                        <p>The specialized nature of medical gear, especially ventilators and respiratory treatment supplies, gives suppliers significant leverage. Significant length contracts and partnerships may decrease this authority.</p>
                        <h2><strong>Identify Service Category/Competitor Analysis Critical Success Factors</strong></h2>
                        <h3><strong>Competitor Strengths and Weaknesses</strong></h3>
                        <p>Local competitors vary in size, yet their strengths are often their reputation and services (Haynes et al., 2020). Limitations may incorporate a limited capacity for exceptionally specialized treatment or less patient-centred care.</p>
                        <ul>
                            <li>
                                <h4><strong>Critical Success Factors</strong></h4>
                            </li>
                        </ul>
                        <p>Top calibre, specialized medical treatment, patient satisfaction, referral networks with nearby hospitals and focuses, and care conveyance innovation are critical to our drawn-out care facility's success.</p>
                        <ul>
                            <li>
                                <h4><strong>Strategic Groups</strong></h4>
                            </li>
                        </ul>
                        <p>Challenges may be categorized by service range and speciality. Our hospital is part of a strategic organization that gives specialized and complete treatment.</p>
                        <ul>
                            <li>
                                <h4><strong>Competitors</strong></h4>
                            </li>
                        </ul>
                        <p>Map competitors by location, size, and specialism. This will simulate the cutthroat climate and help you track down low-challenge or popular locations (Haynes et al., 2020).</p>
                        <ul>
                            <li>
                                <h4><strong>Likely Competitor Responses</strong></h4>
                            </li>
                        </ul>
                        <p>Competitors may react by offering ventilator-subordinate patients more specialized treatment or partnering with hospitals to increase referrals.</p>
                        <ul>
                            <li>
                                <h4><strong>Strategic Groups</strong></h4>
                            </li>
                        </ul>
                        <p>Our exceptionally specialized facility, general broadened care establishments with more extraordinary services, and home health agencies with at-home ventilator assistance are strategic groupings. Mapping these groupings grasps market dynamics and conceivable rivalry and cooperation.</p>
                        <h2><strong>Synthesis</strong></h2>
                        <p>The research shows that the Extensive Care Facility for Ventilator-Subordinate Patients provides an unmistakable yet vital healthcare service. Specialist broadened care is required because of the metropolitan service area's ageing population and decided weakness. High segment barriers reduce the danger of new competitors, but alternatives like home health care and client and supplier power give enduring stresses (Haynes et al., 2020).&nbsp;</p>
                        <p>Competitor analysis shows fundamental rivalry and emphasizes the significance of patient care, innovation, and referral organizations. Strategic mapping of rivals and anticipating their normal reactions assist our hospital with gaining market share by concentrating on specialized treatment, patient happiness, and care conveyance improvement. This strategy will maintain our power and assist us with achieving our main aim of conveying the best ventilator-subordinate patient care.</p>
                        <h2><strong>Conclusion</strong></h2>
                        <p>Understanding monetary and societal patterns is only the start of healthcare business planning. The magic comes when healthcare practitioners meet organizations' most profound necessities. Our serious gander at specialized healthcare shows the careful planning and strategic vision expected to give meaningful treatment. It is about compassionately and meeting a fundamental need, not simply battling. Healthcare firms may achieve commercial success and work on patient health by remaining alert to the changing severe climate and focusing on fantastic patient care. This exploration of healthcare rivalry and strategy shows how financial acumen and the moral aim of healthcare to heal, care, and enhance lives are profoundly associated.</p>
                        <h2><strong>References</strong></h2>
                        <p>Ranjan, R., Smith, T., &amp; Cole, B. (2021). Competitor analysis and strategic planning in healthcare. <em>Journal of Healthcare Management</em>, 36(4), 289-301.</p>
                        <p>Hermes, D., Clark, J., &amp; Patel, M. (2020). Innovations in healthcare strategic alliances and partnerships. <em>Healthcare Strategy Journal</em>, 15(3), 178-194.</p>
                        <p>Alomari, A., Peterson, L., &amp; Haynes, R. (2020). Strategic resource allocation in competitive healthcare markets. <em>Journal of Health Economics and Policy</em>, 29(5), 321-336.</p>
                        <p>Cheng, K., Dawson, R., &amp; Liu, J. (2021). Service area analysis in healthcare management. <em>Health Services Research</em>, 56(7), 412-427.</p>
                        <p>Wulandari, P., Fernandez, M., &amp; Lopez, C. (2023). Managed care penetration and service area delineation in healthcare. <em>Global Health Journal</em>, 40(2), 95-109.</p>
                        <p>Franco Montoya, R., Hernandez, E., &amp; Davis, K. (2020). Long-term care strategies for ventilator-dependent patients. <em>Journal of Critical Care Management</em>, 27(1), 55-66.</p>
                        <p>Haynes, R., Miller, S., &amp; Jones, F. (2020). Critical success factors in specialized healthcare services. <em>Healthcare Business Review</em>, 33(6), 210-225.</p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default N547week2assignment