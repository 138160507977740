import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx6107assessment2 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 6107 Assessment 2 Course Development</title>
                <meta name='description'
                    content='Learn how NURS FPX 6107 Assessment 2 Course Development and Influencing Factors shape a surgical smoke evacuation curriculum for safer ORs.' />
                <meta name='keywords' content='NURS FPX 6107 Assessment 2 Course Development and Influencing Factors' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 6107 Assessment 2 < br /><span>Course Development and Influencing Factors</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursfpx6107assessment2.webp" alt="NURS FPX 6107 Assessment 2 Course Development and Influencing Factors" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>NURS FPX 6107 Assessment 2 Course Development.</strong></em></li>
                        </ul>
                        <h2>Course Development and Influencing Factors</h2>
                        <p>Nursing educators play a crucial role in developing curricula in academic and clinical settings based on learning needs and meeting the standards set out by accreditation agencies. This paper outlines the new course that will be added to the existing attendant residency program (NRP) curriculum, the collaboration among stakeholders, and factors affecting the curriculum design, as part of the <a href="https://acemycourse.net/nurs-fpx-6107-assessment-2-course-development-and-influencing-factors/">NURS FPX 6107 Assessment 2 Course Development</a>.</p>
                        <h2>New Course: Smoke Evacuation in Operating Room</h2>
                        <p>The new course that will be added to the operating room (OR) attendant residency program (NRP) is a viable surgical smoke evacuation in the operating room. The course is designed to give staff training to new and existing active room attendants regarding the viable utilization of surgical smoke evacuation strategies during the surgical strategy. Surgical smoke is created by the thermal destruction of tissue by the utilization of lasers or electrosurgical gadgets, and the smoke contains potentially hazardous toxic gases and vapors like benzene, hydrogen cyanide, formaldehyde, bioaerosols, dead and live cellular material, and infections (The Joint Commission, 2021).</p>
                        <ul>
                            <li>
                                <h3>Risks of Surgical Smoke Exposure</h3>
                            </li>
                        </ul>
                        <p>Long-term receptiveness to surgical smoke has been associated with lung diseases and issues, the transmission of infections, for example, human papillomavirus, and responsiveness to intensifies apparent as mutagenic and carcinogenic by the National Institute for Occupational Safety and Health. The course best fits in the curriculum during the focal point of the NRP whenever the learners have valuable chances to attend the simulation labs and preceptor-drove clinical. Education and appropriate tools enable and engage learners to provide confirmation-based quality care and satisfy regulatory guidelines.</p>
                        <h2>The Rationale for Adding a course to a selected curriculum.</h2>
                        <p>The Occupational Safety and Health Administration (OSHA) anticipates that businesses should provide their representatives with safe and hazardous working conditions. Surgical smoke safety is an increasing concern among perioperative personnel; in any case, helpful smoke-evacuation strategies and regulations are lacking nationwide. National Institute for Occupational Safety and Health (NIOSH) investigators saw that surgical smoke guidelines are not continued in clinical settings and call for staff education without smoke operating rooms (Vortman &amp; Thornton, 2021).</p>
                        <p>According to The Social class for Disease Control and Prevention (CDC), The National Institute for Occupational Safety and Health (NIOSH) reports that consistently, more than one-half million healthcare laborers, including surgeons, attendants, surgical technologists, and other healthcare professionals, are introduced to surgical smoke (2017). Advanced technological tools are available to achieve smoke evacuation in surgical suites; in any case, the smoke evacuation is inadequate because of various barriers, such as lack of education, awareness, work culture, and individual perception.</p>
                        <ul>
                            <li>
                                <h3>Surgical Smoke Evacuation Training</h3>
                            </li>
                        </ul>
                        <p>Therefore, developing a training program to educate the perioperative personnel regarding the potential health hazards of surgical smoke and to use verification-based strategies to create a safe workplace is critical. Surgical smoke, or a surgical tuft, is generated during surgical frameworks where tissues are cut, coagulated, or fulgurated using an electrical stream (Bree et al., 2017).</p>
                        <p>Another reason to add surgical smoke evacuation training is to satisfy the regulatory and practice guidelines from the regulatory bodies. Several states are pushing for legislation for the mandatory utilization of smoke evacuation strategies for all surgical strategies. According to the Association of periOperative Selected Medical Caretakers (AORN), Rhode Island, Colorado, Kentucky, Oregon, Illinois, Arizona, Washington, Georgia, and Connecticut have passed laws mandating the utilization of a smoke evacuation framework for all surgical strategies in all hospitals and freestanding ambulatory medical procedure facilities (2022).</p>
                        <p>To defeat the barriers to surgical smoke evacuation and further encourage compliance, the operating room staff should be educated regarding advanced, verification-based smoke evacuation strategies and regulatory standards and provide cutting-edge gear to achieve the outcomes (Fencl, 2017).</p>
                        <h2>Smoke Evacuation in Operating Room: Topic Outline</h2>
                        <p>It is essential to see the necessities of the learners and determine program goals before developing the new curriculum. A gap analysis will be beneficial in distinguishing the gaps between verification-based practice and clinical practices (Jager et al., 2020). The surgical smoke safety course prepares operating room medical caretakers to create a sans-hazard workplace using smoke-evacuation strategies during various surgical philosophies. The course will be a one-day training program that consists of classroom training, video presentations, demonstration of hardware use, hands-on training on smoke-evacuation gear, test, expertise validation, and end-of-course evaluation.</p>
                        <p>The course includes surgical smoke, a surgical methodology that creates smoke, and the dangers of prolonged responsiveness to surgical smoke. As part of the NURS FPX 6107 Assessment 2 Course Development, the educator will give various confirmations based on smoke evacuation strategies in the OR to create a workplace without hazards; the educator will train the attendants to use smoke evacuation gear, such as an electrosurgical gadget with vacuum capability, inline channels, and hand-controlled vacuum gadgets.</p>
                        <p>The educator will use recordings and PowerPoint presentations, and the learners will be outfitted with printed material to track. Popup tests and discussions will be incorporated to assess the learners' understanding of the topic during the class. Hands-on training will be given to use the smoke-evacuation hardware safely. There will be ability verification and a test at the end of the class to assess the learning results. An end-of-course evaluation audit is also included, and the participants will want to evaluate the course content, the educator's performance, and any recommendations. The topics in the course align with AORN's Periop101 training guidelines to meet the accreditation agencies' regulatory standards.</p>
                        <h2>Collaboration Among the Interdisciplinary Team</h2>
                        <p>Curriculum development, evaluation, and revisions are the ultimate responsibility of faculties and nursing educators; notwithstanding, the requirement for collaboration with the multidisciplinary team is critical for the valuable planning, development, and implementation of the program. Confirmation-based practices are crucial for safe and quality healthcare. Change in clinical practices based on the confirmation-based recommendations for smoke evacuation requires multidisciplinary collaboration as it affects the entire surgical team.</p>
                        <p>The interdisciplinary team involves administrators, sustain educators, physicians, quality and safety individuals, medical attendants, anesthesia providers, and other surgical team individuals. Internal and external stakeholders have a common interest in change in practice to advance nursing education and work on the population's health. The internal stakeholders include administrators, physicians, medical attendants, anesthesia providers, quality control personnel, and medical attendant educators. External stakeholders are regulatory agencies, accreditation bodies, and legislators who cooperate for population health.</p>
                        <p>Collaboration is crucial during the course training and implementation of the practice improvement initiatives phase to increase staff acceptance and team engagement (Tharp-Barrie et al., 2019). Another rationale for multidisciplinary collaboration is to bring alternate points of view during the curriculum design so the team can see the program goals and guarantee those are aligned with the organizational strategies. Forming a curriculum and implementation panel with representatives from all disciplines is essential for stakeholders' acceptance while developing program goals, timelines, training modalities, and implementation.</p>
                        <h2>Internal Factors' Influence on Curriculum Development</h2>
                        <p>Several factors affect curriculum development. For staff development, curriculum development begins with assessing the external and internal organizational factors influencing the curriculum. Curriculum development in nursing education should be based on the points of view of external and internal stakeholders and the utilization of the best current evidence in nursing and health professions education (Keating, 2014). Institutional and framework-level factors act as internal and external agents of curriculum development; curriculum results, in turn, influence these internal and external factors (Jager et al., 2020).</p>
                        <ul>
                            <li>
                                <h3>Internal Factors in Curriculum Design</h3>
                            </li>
                        </ul>
                        <p>Internal factors affecting the curriculum design and implementation of new courses are primarily the organizational construction and the mission and reasoning of the institution. Other crucial internal factors that affect the curriculum design are the spending plan, the learner population, physical assets for teaching, governance, and internal program approval (Oermann &amp;Gaberson, 2019). The organizational construction affects course development and implementation, whether public, private, advantageous, or nonprofit.</p>
                        <p>Spending plans are one of the most crucial factors in today's healthcare sector. Financial and financial planning involves analyzing the program's cost, advantages, and measures to control consumption. The learner population is another internal factor to consider while developing new curricula, as the learners are of various ages and educational backgrounds. Physical assets include classrooms, simulation gear, educational materials, and other supplies for staff education (Oermann &amp; Gaberson, 2019). It is</p>
                        <p>A strong strategy for securing backing and material assets for education demonstrates the connection between educational programs and the targets of an organization, primarily assuming that program results and cost-adequacy analysis support the positive advantages of investment in education. Individuals from the board see the expected outcomes and make recommendations for developing the curriculum based on the organizational goals. The primary responsibility of attendant educators is to cultivate the curriculum and staff education on smoke evacuation strategies.</p>
                        <h2>External Factors' Influence on Curriculum Development</h2>
                        <p>External factors that influence the curriculum beyond the institution play a critical role in curriculum development and implementation, resulting in changes in clinical practices to further encourage population health. As part of the NURS FPX 6107 Assessment 2 Course Development, external frame factors that affect the curriculum design include local area, demographics, regulations and accreditation, healthcare framework, financial assistance, the nursing profession, and government and political bodies. The location of the program's local area influences the accessibility to learners, educators, and learning assets.</p>
                        <p>Demographic factors include learners' age, orientation, socioeconomic status, educational background, and so forth (Keating, 2014). This information is vital when designing a curriculum that incorporates adult learning theories and various learning modalities.</p>
                        <p>While developing a surgical safety training curriculum, sustained educators should consider external factors, similar to accreditation standards and legislation. The Joint Commission and OSHA have recommendations and guidelines for the safety of healthcare providers. AORN's surgical smoke evacuation legislation is another critical factor that affects the surgical smoke safety course. Furthermore, several states are considering surgical smoke evacuation legislation, and five states have enacted legislation (The Joint Commission, 2021).</p>
                        <h2>Institutional Impact on Curriculum Design</h2>
                        <p>The organization aims to provide accessible, affordable, equitable, and confirmation-based quality care to individuals and the local area through capable healthcare providers (Kaiser Permanente, 2018). The mission statement of the NRP is that through education, training, and mentorship, the NRP aims to prepare new graduate attendants to offer top-caliber, confirmation-based care to enhance the clinical consequences of patients. The institution's mission aligns with the mission of the NRP to give confirmation-based quality healthcare to individuals through knowledgeable and talented healthcare providers.</p>
                        <p>The new course added to the NRP will train the new graduate medical caretakers to provide confirmation-based patient care by creating a safe and sans-hazard operating room. The NRP's way of thinking is to train new graduate medical attendants to cultivate expertise and transfer learning into clinical settings through verification-based practices.</p>
                        <p>For example, the program gives total staff training on smoke evacuation strategies, hands-on training on gear, and mentoring for new medical attendants before transitioning into operating rooms. The curriculum offers learners essential tools to use smoke evacuation gear safely during medical methodology. To minimize the theory-practice gap during the transition into clinical practice, educators must cultivate a curriculum that sheds marvelous light on organizational strategies, mission, and regulatory standards.</p>
                        <h2>Internal and External Stakeholders Collaboration</h2>
                        <ul>
                            <li>
                                <h3>Collaborative Curriculum Development Process</h3>
                            </li>
                        </ul>
                        <p>Curriculum development and implementation of a new curriculum is a collaborative task that involves internal and external stakeholders. Translational science is the application of research that leads to confirm-based practice that spans scholarship and research. It transcends many disciplines and, in this manner, leads to interdisciplinary cooperation and confirmation-based practice based on the latest logical achievements and research in health care, nursing, and education, particularly in nursing (Keating, 2014).</p>
                        <p>A multi-professional collaboration provides various aspects and viewpoints of the program and the program's results. The NRP was established utilizing the work-integrated learning component of the Pedagogical Framework. Work-integrated approaches advance the application of theory in the workplace by providing students with open learning entryways in the classroom and clinical settings. Internal stakeholders include administrators, sustain managers, educators, physicians, medical attendants, and quality improvement personnel.</p>
                        <p>External stakeholders that play a vital role in curriculum design are accreditation agencies, legislators, and individuals in the local area. The NRP for OR medical attendants and the smoke evacuation training requires collaboration between the internal and external stakeholders for financial allocation and secure funding from external agencies. While designing the new curriculum, failure to collaborate with internal and external stakeholders may bring about stakeholders' dissatisfaction, implementation issues, and compliance issues.</p>
                        <h2>Conclusion</h2>
                        <p>In conclusion, curriculum design is the ultimate responsibility of educators; in any case, it is a collaborative effort among internal and external stakeholders. Healthcare institutions are to provide a safe and hazardous environment for patients and laborers; therefore, the smoke evacuation in Operating Room training course curriculum, developed as part of the NURS FPX 6107 Assessment 2 Course Development, is created to train new graduate medical attendants to give quality care to patients and individuals from the surgical team through verification-based practice and advanced innovation.</p>
                        <p>The curriculum reflects the organizational mission while meeting the regulatory necessities and proposed educational outcomes. The curriculum is the foundation of every program. In solicitation to establish a total curriculum, it should incorporate all stakeholders' ideas, opinions, and viewpoints (Jeng-Cheng et al., 2022).</p>
                        <h2>References</h2>
                        <p>Association of periOperative Registered Nurses. (2022). <em>Everyone Deserves a Smoke-Free OR</em>. Retrieved from aorn.org:</p>
                        <p><a href="https://doi-org.library.capella.edu/10.1177/2165079917691063">https://doi-org.library.capella.edu/10.1177/2165079917691063</a></p>
                        <p>Fencl, J. (2017). Guideline implementation: Surgical smoke safety. <em>AORN Journal: The Official Voice of Perioperative Nursing; Denver, 105</em>(5), 488-497.</p>
                        <p><a href="https://doi:10.1016/j.aorn.2017.03.006">https://doi:10.1016/j.aorn.2017.03.006</a></p>
                        <p>Jager, f., Vandyk, A., Jacob, J. D., Meilleur, D., Vanderspank-Wright, B., LeBlanc, B., Chartrand, J., Hust, C., Lalonde, M., Rintoul, A., Alain, D., Poirier, S., &amp; Phillips, J. C. (2020). The Ottawa model for nursing curriculum renewal: An integrative review. Nurse Education Today, 87.</p>
                        <p>Jeng-Cheng, W., Kung-Pei Tang, Yi-Hsin, E. H., Ya-Ting, Y., Jan-Show Chu, Yen-Kuang, L., &amp; Wen-Hsuan Hou. (2022). Medical undergraduates&rsquo; self-evaluation: Before and after curriculum reform. <em>BMC Medical Education, 22</em>, 1-10.</p>
                        <p><a href="https://doi.org/10.1186/s12909-022-03330-w">https://doi.org/10.1186/s12909-</a><a href="https://doi.org/10.1186/s12909-022-03330-w">022-03330-w</a></p>
                        <p>Kaiser Permanente. (2018). <em>Mission and History</em>. Retrieved from kaiserpermanente.org:</p>
                        <p><a href="https://healthy.kaiserpermanente.org/pages/quality-safety">https://healthy.kaiserpermanente.org/pages/quality-safety</a></p>
                        <p>Keating, S. B. (2014). <em>Curriculum development and evaluation in nursing </em>(3rd ed.). Springer Publishing Company.</p>
                        <p><a href="https://doi:10.1891/9780826174420.0010">https://doi:10.1891/9780826174420.0010</a></p>
                        <p>Tharp-Barrie, K., Williams, T. E., El-Mallakh, P., &amp; MacCallum, T. (2019). Design and implementation of clinical competency evaluation system for nursing students in medical-surgical wards. <em>Journal of Family Medicine and Primary Care, 8</em>(4), 1408-1413.</p>
                        <p>The Centers for Disease Control and Prevention. (2017, March 30). <em>Surgical Smoke</em>. Retrieved from The Centers for Disease Control and Prevention :</p>
                        <p><a href="https://www.cdc.gov/niosh/topics/healthcarehsps/smoke.html">https://www.cdc.gov/niosh/topics/healthcarehsps/smoke.html</a></p>
                        <p>The Joint Commission. (2021, June 09). <em>Surgical Smoke Legislation Gaining Traction Across the Country</em>. Retrieved from The Joint Commission:</p>
                        <p><a href="https://www.jointcommission.org/resources/news">https://www.jointcommission.org/resources/news</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx6107assessment2