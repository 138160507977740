import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursing547module6assignment = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>Nursing 547 Module 6 Assignment Strategy Implementation</title>
                <meta name='description'
                    content="Unlock success with Nursing 547 Module 6 Assignment Strategy – Learn key techniques to excel in nursing. Click to read more!" />
                <meta name='keywords' content='Nursing 547 Module 6 Assignment Strategy Implementation' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>Nursing 547 Module 6 Assignment < br /><span>Strategy Implementation</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursing547module6assignment.webp" alt="Nursing 547 Module 6 Assignment Strategy Implementation" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>Nursing 547 Module 6 Assignment Strategy Implementation.</em></strong></li>
                        </ul>
                        <h2><strong>Introduction</strong></h2>
                        <p>The purpose of this assignment is to understand different business environments. A critical investigation of the organization's strengths, flaws, resources, and abilities is essential to this effort. This internal scrutiny prepares value-added service delivery and support strategies crucial to the company's client service. Services marketing has distinct challenges and intricacies. Therefore, internal environment analysis and value-adding strategies shape service marketing action plans and activities.</p>
                        <h2><strong>Part 1: Linkage between Internal Environment Analysis, Value-adding Service Delivery, and Support Strategies</strong></h2>
                        <p>Organizations in various industries, including healthcare, should understand the relationship between value-adding service delivery and support strategies and internal environment analysis. It has a profound interconnection with each other.</p>
                        <h3><strong>Internal Environment Analysis</strong></h3>
                        <p>Internal environment analysis involves analyzing an organization's internal resources and the factors that affect its performance, culture, and operations (Sitepu et al., 2023). This analysis includes managing and structuring human resources, understanding the standards, values, and convictions influencing behavior and judgment, and examining financial resources and spending plans.</p>
                        <p>A total internal environment analysis examines an organization's resources and parts that affect achievement, civilization, and strategy. This includes managing human resources, defining organizational positions, and understanding representative behavior and dynamic standards, attitudes, and convictions. The analysis also examines the organization's financial resources and spending plan for strategic planning and operational adequacy. Organizations can further cultivate their market performance by exploring these internal factors to find strengths and flaws.</p>
                        <h3><strong>Strategies for Value-Added Service Delivery and Support Strategies</strong></h3>
                        <p>Value-adding service delivery and support strategies aim to improve the efficacy, proficiency, and quality of services provided to clients or purchasers (Jensen &amp; Voordt, 2020). These approaches enhance healthcare patient care while streamlining cycles and resources. Patient-centered care, quality improvement, interdisciplinary collaboration, and innovation are the techniques to improve value-adding and supporting strategies. This guarantees that treatment is based on energy research and strategy.</p>
                        <h3><strong>Value-adding Strategies and Internal Environment Analysis</strong></h3>
                        <h4><strong><em>Identifying Your Strengths and Weaknesses</em></strong></h4>
                        <p>Organizations may utilize their strengths as talented labor or innovative innovation. Conducting an internal environment analysis advances value-adding strategies. To further encourage service and support, shortcomings can be addressed through focused improvement projects (Eakin &amp; Gladstone, 2020). Organizations can gain an advantage by using their assets like talented labor and creative innovation. Internal environment analysis distinguishes strengths and conceivable results, enabling strategic efforts. It also distinguishes service and support inadequacies that focused improvement programs can address. This strategy maintains and chips away at the quality of service and operational proficiency, increasing customer happiness and corporate new turn of events.</p>
                        <h4><strong><em>Enhancing the Allocation of Resources</em></strong></h4>
                        <p>Understanding their internal environment, organizations could allocate resources to support adding-value strategies. Supporting staff training and advancement could chip away at clinical aptitude. It will also increase the patient's results and satisfaction. Funding technological advances may enhance service delivery accuracy and viability.</p>
                        <h4><strong><em>Building a Supportive Culture</em></strong></h4>
                        <p>Without an encouraging workplace, the strategy of value-adding execution fails. An analysis of the internal environment may reveal this. A culture that advances cooperation, creativity, and representative involvement encourages collaboration and innovation in service and support activities. An inspiring workplace is essential for value-adding strategy implementation, and internal environment analysis evaluates this. A positive workplace culture that encourages collaboration, innovation, and representative engagement is necessary. This culture advances idea exchange and collaboration across the organization, boosting the delivery of services and promoting innovation. Representatives who feel valued and involved are more inclined to add to the company's goals, improving productivity and performance.</p>
                        <h4><strong><em>Changing to Meet New Needs</em></strong></h4>
                        <p>Regularly reviewing their internal environment enables organizations to adapt to patient needs, technological advances, and regulatory demands. Adaptability is essential for healthcare proficiency and production network productivity. Organizations, especially healthcare providers, need regular internal setting audits to adapt to changing circumstances. This allows them to rapidly adapt to changing patient needs, adopt innovations, and meet new regulations. Adaptability further creates healthcare delivery and store network productivity, ensuring that medical institutions are exceptional in giving magnificent care. Healthcare organizations may enhance patient outcomes and stay serious in a fast-changing business by keeping adaptable.</p>
                        <h4><strong><em>Establishment of Internal Environment</em></strong></h4>
                        <p>Analyzing the internal environment helps locate inefficiencies in frameworks and cycles and communication gaps. Organizations can enhance healthcare professional collaboration by addressing these concerns and guaranteeing smooth service delivery and patient support.</p>
                        <p>To summarize, organizations should interface internal environment analysis with value-adding service delivery and support strategies to maximize resources, cultivate a culture of support, adjust to shifting demands, and give patients phenomenal care (Amos &amp; Boakye-Agyeman, 2022). Organizations can chip away at patient outcomes by matching internal abilities with strategic objectives, staying serious in the healthcare market, and working on patient happiness.</p>
                        <h2><strong>Part 2: Value-adding Strategies in Connection with the Action Plan</strong></h2>
                        <p>Value-adding strategies can be guiding principles that further encourage customer experiences, streamstream operations, and accomplish their objectives. There are several essential ways wherein the two are related while creating an action plan, which portrays the exact actions and activities expected to incorporate these ideas:</p>
                        <h3><strong>Strategic Agreement</strong></h3>
                        <p>The action plan should reflect the organization's value-adding strategy (de Jesus Pacheco et al., 2023). The plan's actions and initiatives should all cooperate to make these strategies a reality. Enhancing customer service is one of the value-adding strategies; for instance, the action plan can include implementing staff training programs, customer feedback channels, or cycle enhancements to accomplish this.</p>
                        <h3><strong>Setting Objectives</strong></h3>
                        <p>Value-added strategies are often an organization's goals. The action plan separates undeniable level goals into substantial activities or benchmarks, making them more achievable and quantifiable. Organizations may track their advancement by setting time-bound goals and guaranteeing that their action plan meets strategic goals. Value-added initiatives underpin an organization's goal-setting. These tactics make significant-level goals more achievable by breaking them down into phases and benchmarks.</p>
                        <p>An action plan with unequivocal, time-bound targets assists organizations with tracking their advancement. This systematic strategy helps link each task with strategic goals and meet milestones on time. Accordingly, organizations may monitor their progress and make modifications to stay centered, promoting continual improvement and achievement.</p>
                        <h3><strong>Allocation of Resources</strong></h3>
                        <p>The action plan includes cash, labor, and technological needs. Other resources are also expected for progress. It guarantees that zeroed-in on value-adding frameworks are spread to maximize their impact (Wari et al., 2023). Assuming technological integration is essential, the action plan can support new software frameworks or infrastructure upgrades.</p>
                        <h3><strong>Obligations and Commitment</strong></h3>
                        <p>A fair action plan assigns occupations and considers individuals accountable for project achievement. The action plan guarantees stakeholder agreement and commitment to the organization's objectives. It also determines who is answerable for each task, when it should be finished, and how progress will be measured. Individuals are offered the chance to be liable for using value-adding strategies. Liable for their achievement, they actively contribute.</p>
                        <h3><strong>Tracking and Inspection</strong></h3>
                        <p>The action plan tracks progress and evaluates activities (Scorza &amp; Santopietro, 2021). Organizations can track performance and see plan deviations. Regular evaluations and inspections assist them with adjusting. Organizations may guarantee continuous advancement. They can include feedback circles in action plans to adapt to changing needs or conditions. The action plan is essential for monitoring achievement and recognizing deviations from booked tasks.</p>
                        <p>As the plan outlines, regular audits and inspections allow organizations to make ideal modifications, sustaining force toward strategic goals. The action plan should also include feedback circles to adjust to changing objectives or situations. This responsive strategy allows organizations to make real-time changes to their plans and operations depending on stakeholder feedback. In this manner, organizations may stay focused while adapting to external and internal changes, assuring continual improvement.</p>
                        <h3><strong>Iteration Enhancement</strong></h3>
                        <p>Action plans and value-added strategies change. They alter with internal and external factors. Constant evaluation and seeing assistance organizations improve and change their action plans. This iterative strategy allows organizations to adapt to new entryways and challenges while staying predictable with their strategic goals.</p>
                        <p>Action plans transform value-adding strategies into initiatives and actions (Haritha Malika Dara et al., 2024), whereas value-adding strategies advance organizational improvement. Organizations can effectively apply value-adding strategies and generate favorable results in all operations through goal alignment, asset allocation, obligation assignment, progress tracking, and ceaseless improvement.</p>
                        <h2><strong>Part 3: Differentiating Features: Marketing a Service vs. Marketing a Physical Product</strong></h2>
                        <p>The marketing of services is unmistakable from that of physical products in several important aspects (Kumar et al., 2022) manager, among them being the intangible character of services and the challenges they present. A piece of the factors are:</p>
                        <h3><strong>Tangibility</strong></h3>
                        <p>Marketing a service varies significantly from marketing something tangible in additional ways than one. Physical products enable marketers to feature features, quality, and plans because they are physical wares that purchasers can contact, feel, and see. Services are intangible and cannot be similarly capable by the faculties. It is hard to communicate the value of a service to planned clients because of this absence of tangibles. Offering a service is remarkable when selling a product since it is intangible. This intangibility requires emphasizing the service's advantages and results instead of physical qualities. Service marketing, part of the time, emphasizes capability, dependability, and fantastic experience. Marketers should leverage client feedback, case studies, and service adequacy demonstrations to pass value on to potential clients.</p>
                        <h3><strong>Inseparability</strong></h3>
                        <p>This is one of the main characteristics of services. Services are usually created and used simultaneously, unlike physical articles, which can be conveyed, set aside, and transferred regardless of the maker. Because the service provider's abilities, demeanor, and lead often have an immediate impact on the quality of the service, this addresses a special difficulty for service marketers.</p>
                        <h3><strong>Perishability</strong></h3>
                        <p>Another significant differentiation is the perishability of services. Services are often perishable and cannot be saved for later use, in contrast to tangible products, which can be stored and reused (Milo&scaron; &Scaron;ajbidor &amp; Mari&aacute;n Mikol&aacute;&scaron;ik, 2023). As part of <strong>Nursing 547 Module 6 Assignment Strategy Implementation</strong>, service providers must manage demand and capacity to avoid underutilization or excessive demand, which can disrupt clients. Services differ from tangible goods in that they expire.</p>
                        <p>They are consumed at production and cannot be stored or reused. Providers must balance resources and capacity to optimize utilization in <strong>the context of Nursing 547 Module 6 Assignment Strategy Implementation</strong>. Underutilization leads to wasted assets, while overcapacity reduces service quality, potentially frustrating clients. Strategic planning, flexible asset management, and dynamic pricing align service availability with client demand, ensuring efficient and satisfactory delivery.</p>
                        <h3><strong>Heterogeneity</strong></h3>
                        <p>Service heterogeneity means that each service experience is remarkable and depends upon the client's necessities, choices, and setting. Because of this, service marketers battle to standardize client experiences across client interactions. Service heterogeneity makes consistency annoying because each service interaction depends upon the client's necessities, inclinations, and conveyance circumstances. Because of this heterogeneity, service companies cannot standardize experiences like physical product makers. Service marketers and wholesalers use adaptable service standards and train laborers to handle various situations and manage this variety. This assists the tailor with adjusting to client needs while maintaining quality. CRM frameworks can also capture client inclinations and history to give more reliable, personalized service.</p>
                        <h3><strong>Client Interaction</strong></h3>
                        <p>Services require client contact during manufacturing (Blut et al., 2021) in contrast to tangible products manufacturers make and clients use. Services regularly require client participation. Service marketers should encourage clients to participate in service conveyance since they co-create value. Service conveyance requires client participation, unlike tangible products created and consumed separately.</p>
                        <h4><strong><em>Client Engagement in Services</em></strong></h4>
                        <p>In many service organizations, the client is an active player who affects the outcome. Service marketers should encourage and maintain client participation in value co-creation. Organizations ought to cultivate accessible, engaging services to encourage purchaser loyalty additionally. This may incorporate clear interaction, client training, or easy-to-understand frameworks that let clients straightforwardly express their choices and feedback. Engaging clients in the service cycle creates a more personalized and satisfying experience, increasing purchaser loyalty and loyalty.</p>
                        <p>Organizations can use real-time information from this participative strategy to deal with their services. Because of these differentiations, service marketing emphasizes advantages, results, and experiences while creating client trust. Information, immediacy, dependability, and satisfaction with clients may be anxious. In-service marketing, ideas from family and dear companions, feedback, and other social verification assist with creating believability and attracting new clients.</p>
                        <h2><strong>References</strong></h2>
                        <p>Amos, D., &amp; Boakye-Agyeman, N. A. (2022). Content validation of value-adding indicators for corporate real estate management: insights from a developing country. <em>Journal of Corporate Real Estate</em>, <em>ahead-of-print</em>(ahead-of-print). <a href="https://doi.org/10.1108/jcre-08-2021-0024">https://doi.org/10.1108/jcre-08-2021-0024</a></p>
                        <p>Blut, M., Wang, C., W&uuml;nderlich, N. V., &amp; Brock, C. (2021). Understanding anthropomorphism in service provision: a meta-analysis of physical robots, chatbots, and other AI. <em>Journal of the Academy of Marketing Science</em>, <em>49</em>. <a href="https://doi.org/10.1007/s11747-020-00762-y">https://doi.org/10.1007/s11747-020-00762-y</a></p>
                        <p>de Jesus Pacheco, D. A., M&oslash;ller Clausen, D., &amp; Bumann, J. (2023). A multi-method approach for reducing operational wastes in distribution warehouses. <em>International Journal of Production Economics</em>, <em>256</em>, 108705. <a href="https://doi.org/10.1016/j.ijpe.2022.108705">https://doi.org/10.1016/j.ijpe.2022.108705</a></p>
                        <p>Eakin, J. M., &amp; Gladstone, B. (2020). &ldquo;Value-adding&rdquo; Analysis: Doing more with qualitative data. <em>International Journal of Qualitative Methods</em>, <em>19</em>, 160940692094933. <a href="https://doi.org/10.1177/1609406920949333">https://doi.org/10.1177/1609406920949333</a></p>
                        <p>Haritha Malika Dara, Raut, A., Adamu, M., Ibrahim, Y. E., &amp; Ingle, P. (2024). Reducing non-value-adding activities through lean tools for the precast industry. <em>Heliyon</em>, <em>10</em>(7), e29148&ndash;e29148. <a href="https://doi.org/10.1016/j.heliyon.2024.e29148">https://doi.org/10.1016/j.heliyon.2024.e29148</a></p>
                        <p>Jensen, P. A., &amp; Voordt, T. J. M. van der. (2020). Typology of value-adding FM and CREM interventions. <em>Journal of Corporate Real Estate</em>, <em>ahead-of-print</em>(ahead-of-print). <a href="https://doi.org/10.1108/jcre-09-2019-0042">https://doi.org/10.1108/jcre-09-2019-0042</a></p>
                        <p>Kumar, H., Gupta, P., &amp; Chauhan, S. (2022). Meta-analysis of augmented reality marketing. <em>Marketing Intelligence &amp; Planning</em>, <em>41</em>(1). <a href="https://doi.org/10.1108/mip-06-2022-0221">https://doi.org/10.1108/mip-06-2022-0221</a></p>
                        <p>Milo&scaron; &Scaron;ajbidor, &amp; Mari&aacute;n Mikol&aacute;&scaron;ik. (2023). <em>Research on the Service Sector in the Modern Economy</em>. 1&ndash;21. <a href="https://doi.org/10.1007/978-3-031-25695-0_1">https://doi.org/10.1007/978-3-031-25695-0_1</a></p>
                        <p>Scorza, F., &amp; Santopietro, L. (2021). A systemic perspective for the sustainable energy and climate action plan (SECAP). <em>European Planning Studies</em>, 1&ndash;21. <a href="https://doi.org/10.1080/09654313.2021.1954603">https://doi.org/10.1080/09654313.2021.1954603</a></p>
                        <p>Sitepu, R. H., Nasution, H. S., Daryanto, E., &amp; Rosnelli. (2023). Using internal and external environmental analysis in strategic management at SMPN 1 Laubaleng, North Sumatra province. <em>East Asian Journal of Multidisciplinary Research</em>, <em>2</em>(1), 81&ndash;94. <a href="https://doi.org/10.55927/eajmr.v2i1.2275">https://doi.org/10.55927/eajmr.v2i1.2275</a></p>
                        <p>Wari, E., Zhu, W., &amp; Lim, G. (2023). Maintenance in the downstream petroleum industry: A review on methodology and implementation. <em>Computers &amp; Chemical Engineering</em>, <em>172</em>, 108177. <a href="https://doi.org/10.1016/j.compchemeng.2023.108177">https://doi.org/10.1016/j.compchemeng.2023.108177</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursing547module6assignment