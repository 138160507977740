import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx8045assessment7 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 8045 Assessment 7 Conceptualizing</title>
                <meta name='description'
                    content="Explore NURS FPX 8045 Assessment 7 Conceptualizing a Potential Project. Learn how assessing readiness and weight-loss plans improve patient outcomes!" />
                <meta name='keywords' content='NURS FPX 8045 Assessment 7 Conceptualizing a Potential Project' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 8045 Assessment 7 < br /><span>Conceptualizing a Potential Project</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursfpx8045assessment7.webp" alt="NURS FPX 8045 Assessment 7 Conceptualizing a Potential Project" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>NURS FPX 8045 Assessment 7 Conceptualizing a Potential Project.</strong></em></li>
                        </ul>
                        <h2>Conceptualizing a Project</h2>
                        <p>Weight is a staggering, moderate, and relapsing constant disease characterized by abnormal or exorbitant muscle versus fat that impairs health and quality of life. Weight is a creating scourge (Ceccarini et al., 2015). It is characterized by a weight record (BMI) in several places in the range of 25 and 30 kg m&minus;2, and unpleasant stoutness is portrayed as a BMI of 30 kg m&minus;2 or greater (Caterson et al., 2019). The prevalence of nationwide stoutness has increased from 4.6% in 1980 to 14.0% in 2019 (Boutari &amp; Mantzoros, 2022).</p>
                        <ul>
                            <li>
                                <h3>Obesity Care and Intervention</h3>
                            </li>
                        </ul>
                        <p>Stoutness affects 42% of adults and costs healthcare $3.8 trillion in 2019 (State of Nevada, 2021). A gap in care exists in managing stout patients; notwithstanding increased acknowledgement as a reliable disease, weight remains significantly underdiagnosed and undertreated (Caterson et al., 2019). Evaluating patients' readiness to change and decrease their weight is paramount to formulating a treatment plan (American Diabetic Association, 2021).</p>
                        <p>This paper will conceptualize a stoutness-related project, the "evaluating for readiness to shed pounds and implementation of a weight decrease pack in primary care (RTL-Weight-Primary), with a related PICOT question:(P) In solid female patients (I) How does evaluating for readiness to change, and presentation of a weight decrease bundle with diet and exercise education, and month to month registrations (C) compared to no mediation (O) impact weight decrease education compliance during a primary care facility office visit (T) at 4 weeks and two months.</p>
                        <h2>Analysis and Synthesis of Research</h2>
                        <p>(M)Obesity is characterized as a BMI &ge;30 kg/m2. It affects more than 650 million adults around the world. Heaviness is also viewed as liable for more than 13 kinds of malignancies. Females constantly lead as far as the level of force is normal, given the biologically settled higher percentage of muscle versus fat in ladies. Evaluating readiness to change in weight decrease is essential, as not all patients are ready to change.</p>
                        <p>(E) In the American Diabetic Association (2021), authors report that concentrated behavioural mediations ought to zero in on dietary changes, physical activity, and behavioural strategies to achieve a 500-750 kcal/day energy shortage (American Diabetic Association, 2021). Various ways to assess readiness to change exist, for example, the transtheoretical model (TTM), which ascended out of a complicated and obfuscating array of psychotherapy approaches that attempted to anticipate and explain "what individuals need to do to make change happen (Sutton, 2022).</p>
                        <ul>
                            <li>
                                <h3>Obesity Impact and Prevalence</h3>
                            </li>
                        </ul>
                        <p>Authors Boutari and Mantzoros (2022) report that repercussions of stoutness on health incorporate those that outcome from the mechanical impacts of an overabundance of body weight, for example, several musculoskeletal complications, metabolic impacts like diabetes and cardiovascular gamble, and the ramifications for mental health. The ascent in weight has accelerated in the last decade (Samuel N and Megan, 2019). Its prevalence is higher in females than males and increases with age (Caterson et al., 2019). Evaluating for weight is paramount to starting a treatment routine (American Diabetic Association, 2021).</p>
                        <p>In a meta-analysis of 165 articles, authors Silveira et al. (2022) report that the prevalence of a sedentary way of life was 35%, and physical inactivity was 43% in adults and more seasoned adults with power. Disregarding that it may be a disease that impacts overall health, many individuals with stoutness (PwO) assume entire liability for weight decrease (81%). They saw their way of life as a vital factor in their battle with brawniness (63%) (Caterson et al., 2019). 36% of PwO had examined their weight with a health care professional (HCP) during the past 5 years, and 67% had been diagnosed with stoutness (Caterson et al., 2019).</p>
                        <h2>NURS FPX 8045 Assessment 7 Conceptualizing a Potential Project</h2>
                        <p>(A)In a cross-sectional, non-interventional, particular framework that gathered data from (PwO) via an electronic investigation of 19,700 respondents, authors understood that a total of 68% of PwO and 88% of HCPs agreed with the statement that giganticness is a constant disease. Most PwO (82%) and HCPs (76%) accepted that weight dramatically impacts overall health.</p>
                        <p>In comparison, 75%-85% of PwOs and 82%-93% of HCPs stated that diabetes, stroke, cancer, or undaunted obstructive pulmonary disease significantly impact overall health. In an investigation of 165 articles, authors report that this is the main systematic framework and meta-analysis of observational examinations to investigate the prevalence of a sedentary way of life (SL) (35%) and physical inactivity (PI) (43%) in adults and more seasoned adults with heaviness.</p>
                        <p>They tracked down high estimates and a large variability for SL (16%-71%) and PI (3%-82%) in individuals with stoutness. A sedentary way of life, together with the utilization of a high-sugar, refined-carb diet and hereditary, metabolic, and environmental factors, is as of now seen as the leading cause of the power pandemic. (L)Obesity affects more than 650 million adults around the world.</p>
                        <p>During 2001-2018, the age-adjusted prevalence of abdominal stoutness increased from 57.58 to 67.33% in females. Weight affects 42% of American adults and costs healthcare more than $3.8 trillion in 2019; this proof adequately substantiates a gap in power care. Primary care suppliers are particularly situated to affect stoutness in patients because they have created a confiding bond.</p>
                        <h2>Recommendations to Stakeholders</h2>
                        <p>The proposed project, "evaluating for readiness to get in shape and implementation of a weight decrease pack in primary care (RTL-Weight-Primary)," is an 8-week quality improvement project (QIP) targeted at primary care habitats in Las Vegas, Nevada. The QIP aims to evaluate the PICOT question (P) In heavy female patients (I), how does evaluating for readiness to change and presentation of a weight decrease bundle with diet and exercise education and month-to-month registrations (C) compared to no mediation (O) impact weight decrease education compliance during a primary care facility office visit (T) at 4 weeks and two months?</p>
                        <p><strong>(M)Obesity is created from the interaction between genotypes and the climate.</strong></p>
                        <p>Understanding how and why weight happens is inadequate; it incorporates integrating metabolic, social, cultural, behavioural, physiological, and hereditary factors. Guaranteeing readiness for change is particularly important for patients; it is also crucial that the individual is familiar with the behaviour's advantages and learns the reason why change matters. Increased BMI is a gamble factor for comorbidities like diabetes, cardiovascular disease, and musculoskeletal issues, and results in a dramatic decrease in life quality and expectancy. (E) Suppliers should assess readiness to engage in behavioural changes to decrease weight (American Diabetic Association, 2021).</p>
                        <p>Author Sutton (2022) reports that individuals change once they are ready to; regardless, having somebody in their corner to offer encouragement can assist patients with staying on track. Evaluating readiness to change for weight decrease can be driven by utilizing a theoretical model that incorporates pre-contemplation, contemplation, preparation, action, and maintenance (Sutton, 2022). To calculate BMI, BMI 30-34.9 kg/m2; weight class II: BMI 35-39.9 kg/m2; stoutness class III: BMI &ge;40 kg/m2) should be noted as thick. (ElSayed et al., 2022). Weight decrease can be achieved by a net shortage of kilocalories (units of energy) (Lin &amp; Li, 2021).</p>
                        <p>Low-carbohydrate eating less yields rapid outcomes with a more significant initial weight decrease than low-fat weight control plans (up to 3.3 kg in a half year) (Ruban et al., 2019). Restricting calorie ingestion is another approach to achieving a net energy shortfall (Wyatt, 2018). Low and exceptionally low-calorie utilization (LCD and VLCD) limit energy intake to 800-1600 kcal/day and &lt;800 kcal/day, separately (Ruban et al., 2019).</p>
                        <h2>NURS FPX 8045 Assessment 7 Conceptualizing a Potential Project</h2>
                        <p>Practice and physical activity are various parts of weight decrease management (Samuel N and Megan, (2019). Regular physical activity hacks down the gamble of stoutness-related conditions and decreases mortality among meaty individuals (Samuel N and Megan, 2019). (A) Patients ought to be evaluated for readiness to change. Regardless, when the client and the mental health professional see the need for change, it may not happen. Assisting patients in understanding the essentials for weight decrease is paramount. Having somebody in their corner to offer encouragement can help patients stay on track.</p>
                        <h2>Comprehensive Obesity Treatment Strategies</h2>
                        <p>Patients will be acquired during the month to evaluate progress and express encouragement. Dietary therapy, macronutrient piece, calorie limitation, meal replacement, diet style, pharmacotherapy, gastric balloon, and bariatric medical strategy are all treatments for stoutness (Ruban et al., 2019). Broadened-length pharmacotherapeutic agents, for example, phentermine/topiramate, lorcaserin, naltrexone/bupropion, and liraglutide, all have relatively similar efficacy of more than a 1-year (Samuel N and Megan, 2019). Bariatric medical reasoning is the treatment of a decision when all medications have failed (ElSayed et al., 2022). (L).</p>
                        <p>Weight is a significant health and monetary emergency facing the state-of-the-art world. It is associated with abundance mortality and dreariness and is straightforwardly related to normal circumstances, for example, type 2 diabetes mellitus, coronary heart disease, and rest apnea (Lin &amp; Li, 2021). Evaluating readiness to get considerably more shaky is essential in planning a healthy plan.</p>
                        <p>Primary care suppliers are uniquely positioned to assist patients in choosing weight-loss therapies. The proposed QIP will address obesity and female patients' readiness to change for weight loss and present a weight-loss plan that incorporates dietary recommendations, physical activity, and weight-loss medications when appropriate. Future investigations could examine what primary care providers mean for patients' motivation to change.</p>
                        <h2>Communication with Stakeholders</h2>
                        <p>This experience will introduce the S-weight screening instrument to evaluate patients' planning to change and present a weight decline pack. Three providers at Intermountain Clinical Idea will be alluded to take part in the screening and execution of the weight decline pack. Office staff will control the S-Weight diagram, and providers will take a gander at the results, notwithstanding presenting a weight decline pack that incorporates eating typically, outrageous work, and fixes when certifiable.</p>
                        <p>Patients will be drawn nearer to returning at 4 weeks and 2 months to evaluate consistency with the weight decline pack. Communication with providers starts with an email inviting them to participate in this endeavour and figuring out the nuances. Individuals who show interest will be given a PowerPoint show on the errand. If gigantic, stakeholders will be given a doorway to present demands through a Microsoft Packs meeting.</p>
                        <p>Data to be followed will cement seasons of progress (pre-thought/examination/status/action and backing). Data will be amassed at the endeavour execution, at the 4-week point, and at the 8-week point. In about a month, the patient will return to the workplace and repeatedly do the S weight audit during the two months. Assessments will be made concerning the pre-, interventional, and present tests to frame the patient's status change and consistency with the weight decline pack. Bits of data will be used to obliterate the data.</p>
                        <h2>Feedback</h2>
                        <p>Feedback is a titanic piece of the innovative cycle. Throughout this course, this maker has gotten fundamental feedback while staying in contact with her PICOT question, assembling an endeavour, and framing the Supper plan. Because of feedback, this essayist had the choice to take out a futile article and add articles that assist with supporting the head undertaking thought, reading up for status to shed pounds, and execution of a weight decline pack in significant idea (RTL-Weight-Focal). One area of the battle is to keep the word/page considered as far as conceivable, as various undertakings demand various things per class. As proposed now, APA continues to be an endeavour that requires fixation.</p>
                        <h2>Conclusion</h2>
                        <p>Strength affects 42% of adults and impacts ladies more significantly than men. Focal idea providers are in an extraordinary situation to assist patients' weight decline by assessing their readiness to change for weight decrease. As featured in <a href="https://www.studocu.com/en-us/document/western-governors-university/healthcare-research-and-statistics/nurs-fpx8045-conceptualizing-a-potential-project/67220047">NURS FPX 8045 Assessment 7 Conceptualizing a Potential Project</a>, solid weight decline depends on carrying out an unquestionably outrageous way of life changes. Offering someone in their corner for reassurance can play a crucial work in assisting patients with staying engaged and committed.</p>
                        <h2>References</h2>
                        <p>American Diabetic Association. (2021). 8. heartiness and weight the bosses for the assumption and treatment of type 2 diabetes: rules of clinical idea in diabetes &mdash; 2022. Diabetes Care, 45(Supplement_1), S113-S124.<br /><a href="https://doi.org/10.2337/dc22-s008">https://doi.org/10.2337/dc22-s008</a></p>
                        <p>Boutari, C., and Mantzoros, C. S. (2022). A 2022 update on the examination of sickness transmission of force and a wellspring of inspiration: As its twin Coronavirus pandemic produces an impression of being pulled out, the cumbersomeness and dysmetabolism pandemic continues to rage on. Handling, 133, 155217.<br /><a href="https://doi.org/10.1016/j.metabol.2022.155217">https://doi.org/10.1016/j.metabol.2022.155217</a></p>
                        <p>Caterson, I. D., Alfadda, A. A., Auerbach, P., Coutinho, W., Cuevas, A., Exchange, D., Hughes, C., Iwabu, M., Kang, J., Nawar, R., Reynoso, R., Rhee, N., Rigas, G., Salvador, J., Sbraccia, P., V&aacute;zquez‐Vel&aacute;zquez, V., and Halford, J. C. (2019). Openings to relate: Misalignment between sharpness, reality, and practices in weight. Diabetes, Thickness and Handling, 21(8), 1914-1924.<br /><a href="https://doi.org/10.1111/dom.13752">https://doi.org/10.1111/dom.13752</a></p>
                        <p>Ceccarini, M., Borrello, M., Pietrabissa, G., Manzoni, G., and Castelnuovo, G. (2015). Inspecting inspiration and status to change for weight the bosses and control: A ccompleteassessment of three instruments. Disrupted regions in Frontal cortex science, 6.<br /><a href="https://doi.org/10.3389/fpsyg.2015.00511">https://doi.org/10.3389/fpsyg.2015.00511</a></p>
                        <p>ElSayed, N. A., Aleppo, G., Aroda, V. R., Bannuru, R. R., Brown, F. M., Bruemmer, D., Collins,<br />1. S., Hilliard, M. E., Isaacs, D., Johnson, E. L., Kahan, S., Khunti, K., Leon, J., Lyons,<br />2. K., Perry, M., Prahalad, P., Pratley, R. E., Seley, J., Stanton, R. C., and Gabbay, R. A. (2022). Imposingness and weight are the pioneers for the countering and treating type 2 diabetes. Diabetes Care, 46(Supplement_1), S128-S139. <a href="https://doi.org/10.2337/dc23-s008">https://doi.org/10.2337/dc23-s008</a></p>
                        <p>Farrell, E., Bustillo, M., le Roux, C. W., Nadglowski, J., Hollmann, E., and McGillicuddy, D. (2021). The lived information on individuals with pudginess: Studies show a determined survey and mix of close-to-home assessments. Useful Investigations, 10(1).<br /><a href="https://doi.org/10.1186/s13643-021-01706-5">https://doi.org/10.1186/s13643-021-01706-5</a></p>
                        <p>Gaesser, G. A., and Angadi, S. S. (2021). Heartiness treatment: Weight decline instead of stretching out well-being and dynamic work to diminish success prospects. iScience, 24(10), 102995.<br /><a href="https://doi.org/10.1016/j.isci.2021.102995">https://doi.org/10.1016/j.isci.2021.102995</a></p>
                        <p>Lin, X., and Li, H. (2021). Power: The examination of disease transmission, pathophysiology, and therapeutics. Edges in Endocrinology, 12.<br /><a href="https://doi.org/10.3389/fendo.2021.706978">https://doi.org/10.3389/fendo.2021.706978</a></p>
                        <p>Ruban, A., Stoenchev, K., Ashrafian, H., and Teare, J. (2019). Current drugs for power.<br />Clinical Solution, 19(3), 205-212.<br /><a href="https://doi.org/10.7861/clinmedicine.19-3-205">https://doi.org/10.7861/clinmedicine.19-3-205</a></p>
                        <p>Samuel N, G., and Megan, W. (2019). Strategy for overseeingthe weightof the bosses in the major idea setting. Diary of Heaviness and Weight Decline Arrangement, 5(1).<br /><a href="https://doi.org/10.23937/2572-4010.1510024">https://doi.org/10.23937/2572-4010.1510024</a></p>
                        <p>Area of Nevada. (2021). Yearly weight report 2021. Recovered January 4, 2023, from<br /><a href="https://www.leg.state.nv.us/Division/Examination/Reports/RTTL_NRS439.521_2022.pdf">https://www.leg.state.nv.us/Division/Examination/Reports/RTTL_NRS439.521_2022.pdf</a></p>
                        <p>Sun, J.- Y., Huang, W.- J., Hua, Y., Qu, Q., Cheng, C., Liu, H.- L., Kong, X.- Q., Mother, Y.- X., and Sun,<br />1. (2022). Plans by and large and stomach vigour in grown-ups: Proof from the public success and food examination study (2001-2018). Backwoods in Ordinary Success, 10. <a href="https://doi.org/10.3389/fpubh.2022.925293">https://doi.org/10.3389/fpubh.2022.925293</a></p>
                        <p>Sutton, J. (2022, October 13). One small step at a time rules to survey and further cultivatea basis for change. PositivePsychology.com.<br /><a href="https://positivepsychology.com/openness for-change/">https://positivepsychology.com/openness for-change/</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx8045assessment7