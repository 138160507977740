import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Dnp850module4assignment = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>DNP 850 Module 4 Assignment Nursing Educator</title>
                <meta name='description'
                    content="Explore DNP 850 Module 4 Assignment Prepared Nursing Educator insights! Learn how doctoral educators provide nursing education. Read more now!" />
                <meta name='keywords' content='DNP 850 Module 4 Assignment Nursing Educator' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>DNP 850 Module 4 Assignment < br /><span>Nursing Educator</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Dnp850module4assignment.webp" alt="DNP 850 Module 4 Assignment Nursing Educator" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong><span data-preserver-spaces="true">DNP 850 Module 4 Assignment Prepared Nursing Educator.</span></strong></em></li>
                        </ul>
                        <h2><span data-preserver-spaces="true">Doctoral Prepared Nursing Educator</span></h2>
                        <p>Nursing education is an area undergoing dynamic transformation through the efforts of doctoral-prepared educators. As explored in the <a href="https://onlinetutormasters.com/dnp-850-a-module-4-doctoral-prepared-nursing/">DNP 850 Module 4 Assignment Prepared Nursing Educator</a>, two basic categories of doctorates in nursing, the Doctor of Philosophy (Ph.D.) and Doctor of Nursing Practice (DNP), play critical roles in driving this change. Ph. Ph.D.-prepared educators are primarily science-oriented, emphasizing research and theoretical developments.</p>
                        <p><span data-preserver-spaces="true">It also becomes significant to comprehend the significance of each degree and their combination to gain insights into their influence on the future of nursing education. This assessment aims to outline the responsibilities of Ph. D. and DNP educators, principles, their influence in nursing education, and a personal idea on utilizing a DNP degree in this area.</span></p>
                        <h2><span data-preserver-spaces="true">Role of the Doctoral-Prepared Educator: Ph.D. vs. DNP</span></h2>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Roles of Doctoral Educators</span></h3>
                            </li>
                        </ul>
                        <p><span data-preserver-spaces="true">Doctoral-prepared educators genuinely should assume a significant responsibility in promoting the nursing profession. Two basic categories of doctoral degrees in nursing are the Doctor of Philosophy (Ph. D. ) in Nursing and the Doctor of Nursing Practice (DNP). The two degrees can prepare individuals for leadership positions, yet they differ concerning their conceptual orientation to nursing (May et al., 2020). </span></p>
                        <p><span data-preserver-spaces="true">This paper aims to understand the roles of PhD-prepared and DNP-prepared educators, which entails discussing the basic foundations of each degree and the scholarly work of the two kinds of educators.</span></p>
                        <h3><span data-preserver-spaces="true">Ph. D. - Prepared Educators</span></h3>
                        <p><span data-preserver-spaces="true">The qualifications of Ph. Ph.D.-prepared educators involve a significant emphasis on research and knowledge production within the specialty area of nursing. The Ph. D. program coursework focuses on theory, methods, and a logical approach. Graduates are ready to carry out primary research, write for academic journals, and perform the duties of a teacher.</span></p>
                        <h2><span data-preserver-spaces="true">Principles of Ph.D. Education</span></h2>
                        <h3><span data-preserver-spaces="true">Research-Oriented</span></h3>
                        <p><span data-preserver-spaces="true">Doctorate programs focus more on the researcher's abilities to conduct research. Graduates develop skills and capabilities in designing, conducting, and evaluating research addressing pertinent healthcare delivery questions.</span></p>
                        <h3><span data-preserver-spaces="true">Theoretical Focus</span></h3>
                        <p><span data-preserver-spaces="true">Some of the typical curricula include nursing theory, philosophy of science, and advanced statistics (Rosenfeld et al., 2021). This theoretical background creates a solid ground for developing new nursing paradigms and conceptual models.</span></p>
                        <h3><span data-preserver-spaces="true">Scholarly Contribution</span></h3>
                        <p><span data-preserver-spaces="true">Some of the socially beneficial performances of Ph. D. educators include publishing articles in research journals, presenting papers at academic conferences, and acquiring funds for research.</span></p>
                        <p><span data-preserver-spaces="true">ENTS are crucial in academia and offer administrations as instructors, researchers, and policymakers in nursing education. They also assist in expanding the body of knowledge in the nursing field and enhance the quality of patient care.</span></p>
                        <h3><span data-preserver-spaces="true">DNP-Prepared Educators</span></h3>
                        <p><span data-preserver-spaces="true">DNP-prepared educators, in contrast, concentrate on clinical practice and the implementation of research in the practice arena to increase patient results (Rosenfeld et al., 2021). The DNP program focuses on clinical practice, leadership, and applying evidence-based practice. Graduates are qualified for positions in clinical and healthcare management and as educators.</span></p>
                        <h2><span data-preserver-spaces="true">Principles of DNP Education</span></h2>
                        <h3><span data-preserver-spaces="true">Practice-Oriented</span></h3>
                        <p><span data-preserver-spaces="true">Most DNP programs focus on the practice and implementation of research in various practice environments. Students acquire express clinical capabilities and healthcare facility management skills.</span></p>
                        <h3><span data-preserver-spaces="true">Evidence-Based Practice</span></h3>
                        <p><span data-preserver-spaces="true">These academic offerings include seminars on quality improvement, healthcare policy, and evidence-based practice. This focus guarantees that graduates can incorporate what is obtained from research.</span></p>
                        <h3><span data-preserver-spaces="true">Leadership and Policy</span></h3>
                        <p><span data-preserver-spaces="true">The DNP prepares its educators to direct healthcare organizations, formulate guidelines, and advance healthcare frameworks. They are essential for researching findings and ensuring the quality of administrations.</span></p>
                        <p><span data-preserver-spaces="true">Prominent roles of DNP-prepared educators include providing clinical faculty administrations at academic institutions by offering clinical courses, supervising student clinical encounters, and directing practice-based research projects. They translate knowledge to practical application to ensure that nursing students come out exceptional to handle clinical positions.</span></p>
                        <h2><span data-preserver-spaces="true">Comparing and Contrasting Ph.D. and DNP</span></h2>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">Comparison of Doctoral Degrees</span></h3>
                            </li>
                        </ul>
                        <h3><span data-preserver-spaces="true">Focus</span></h3>
                        <p><span data-preserver-spaces="true">The Ph. D. program is centered on research and theory, in contrast to the emphasis on practice and the application of research that characterizes the DNP program.</span></p>
                        <h3><span data-preserver-spaces="true">Skills Developed</span></h3>
                        <p><span data-preserver-spaces="true">Ph. D. programs, therefore, cultivate abilities in research proposals, data analysis, and theoretical frameworks (Mu&ntilde;oz et al., 2023). DNP programs encourage strong clinical capabilities, leadership in managing healthcare organizations, and the application of research findings.</span></p>
                        <h3><span data-preserver-spaces="true">Scholarly Work</span></h3>
                        <p><span data-preserver-spaces="true">A critical contribution of Ph. D. graduates is conducting research and advancing theories or publications. They practice by implementing their findings in clinical areas and enhancing healthcare in general.</span></p>
                        <p><span data-preserver-spaces="true">The two degrees hold significant importance in the field of nursing. PhD-prepared educators focus on developing nursing knowledge through research, whereas DNP-prepared educators utilize research findings to develop practice and patient care results further.</span></p>
                        <h2><span data-preserver-spaces="true">Balancing the Ph.D. and DNP Degrees in Nursing</span></h2>
                        <p><span data-preserver-spaces="true">The Ph. D. and DNP degrees in nursing: synthesis of two approaches that contribute to the assistance of nursing development. Each degree offers a different specialization in nursing, and when the degrees are combined, they provide both the curriculum theory and application.</span></p>
                        <h3><span data-preserver-spaces="true">Ph.D. Degree: The Research Focus</span></h3>
                        <p><span data-preserver-spaces="true">Ph. D. Degree:</span><span data-preserver-spaces="true"> The primary research focus is on the field of research that the researcher wants to concentrate on. The second is the methodology the researcher intends to use in the study.</span></p>
                        <p><span data-preserver-spaces="true">The role of nurses prepared at the Ph. .D. level is in research and theory. Their education focuses on:</span></p>
                        <h3><span data-preserver-spaces="true">Research Skills</span></h3>
                        <p><span data-preserver-spaces="true">These Ph.D. programs prepare nurses for conducting elaborate research projects. Students learn the most common methods of performing tests, gathering data, and determining the results.</span></p>
                        <h3><span data-preserver-spaces="true">Theory Development</span></h3>
                        <p><span data-preserver-spaces="true">PhD nurses learn about theoretical nursing models and create new ones illuminating the human condition, health, and disease.</span></p>
                        <h3><span data-preserver-spaces="true">Academic Contributions</span></h3>
                        <p><span data-preserver-spaces="true">These nurses disseminate their findings by publishing their work in learned journals and presenting them at conferences (King et al., 2019). From the work discussed above, research carried out by these authors contributes to the development of knowledge within the nursing field and enhances patient care practices.</span></p>
                        <h2><span data-preserver-spaces="true">DNP Degree: The Practice Focus</span></h2>
                        <h3><span data-preserver-spaces="true">DNP Degree</span></h3>
                        <p><span data-preserver-spaces="true">The Practice Focus alludes to identifying practice areas that relate to the health needs of residents to develop compelling and sustainable health interventions for improved health results. DNP-prepared nurses have manager-level decision-making knowledge and translation capabilities. Their education focuses on:</span></p>
                        <h3><span data-preserver-spaces="true">Clinical Skills</span></h3>
                        <p><span data-preserver-spaces="true">DNP programs outfit the attendant with exceptional skills in diagnosing and managing patient care issues.</span></p>
                        <h3><span data-preserver-spaces="true">Evidence-Based Practice</span></h3>
                        <p><span data-preserver-spaces="true">The DNP-prepared sustain also understands how to use ongoing studies to enhance the end effects of patients and procedures within healthcare delivery.</span></p>
                        <h3><span data-preserver-spaces="true">Leadership and Policy</span></h3>
                        <p><span data-preserver-spaces="true">These nurses also coordinate leadership positions in healthcare organizations to direct practice alterations and adjust healthcare approaches.</span></p>
                        <h2><span data-preserver-spaces="true">PhD and DNP Degrees Balance Each Other</span></h2>
                        <ul>
                            <li>
                                <h3><span data-preserver-spaces="true">PhD vs DNP Roles</span></h3>
                            </li>
                        </ul>
                        <h3><span data-preserver-spaces="true">Theory and Practice</span></h3>
                        <p><span data-preserver-spaces="true">Ph. D. nurses generate new knowledge through research, while DNP nurses implement the latest knowledge in practice (Avery-Desmarais et al., 2021). This assists with promoting the fact that the existing logical evidence carries out the nursing practice.</span></p>
                        <h3><span data-preserver-spaces="true">Education and Mentorship</span></h3>
                        <p><span data-preserver-spaces="true">Nurses with Ph. D. degrees deliver theoretical knowledge to nursing students in academic facilities. DNP nurses also teach, mainly focusing on clinical practice and skills. They offer the necessary coverage within a nursing student's curriculum framework.</span></p>
                        <h3><span data-preserver-spaces="true">Research Implementation</span></h3>
                        <p><span data-preserver-spaces="true">Because they engage in research, PhD nurses can think of exceptional solutions to various issues in the healthcare setting. The DNP nurses apply and guide these solutions in practical practice contexts while offering feedback for enhancement.</span></p>
                        <h3><span data-preserver-spaces="true">Improving Healthcare</span></h3>
                        <p><span data-preserver-spaces="true">While PhD nurses generate and advance nursing knowledge in the healthcare field, DNP nurses ensure that such knowledge is used to care for patients. This outcome will result in a positive optimization of the healthcare framework and the population's overall health.</span></p>
                        <h2><span data-preserver-spaces="true">Impact of Ph.D. and DNP Degrees on the Future of Nursing Education</span></h2>
                        <p><span data-preserver-spaces="true">Ph. D. and DNP have been positioned as critical determinants of the future of nursing education. Each degree has its solidarity that, when combined, will assist with establishing a more excellent and contemporary nursing education framework. Their significance comes from their role in improving research, practice, pedagogy, and the general quality of healthcare.</span></p>
                        <h3><span data-preserver-spaces="true">Impact of Ph.D.- Prepared Nurses on Nursing Education</span></h3>
                        <p>The knowledge advanced by Ph.D. nurses is innovative as it extends the boundaries of nursing science. As highlighted in the DNP 850 Module 4 Assignment Prepared Nursing Educator, they work on essential healthcare questions, creating new information that can be incorporated into graduate nursing programs. By applying the latest research findings and theoretical framework developments to nursing courses, Ph.D. educators ensure their students receive the most contemporary and challenging education. These educators are responsible for preparing the next exceptional generation of nurse researchers (Avery-Desmarais et al., 2021).</p>
                        <p><span data-preserver-spaces="true">They explain to students the most common way of conducting research, data analysis, and writing research papers or reports. This mentorship is essential for enhancing the selection of nursing researchers and reinforcing the associated help structures. Ph. D. educators use research-based teaching practices, which means that teachers use approaches based on the knowledge of the accepted procedures in education.</span></p>
                        <h3><span data-preserver-spaces="true">Impact of DNP-Prepared Nurses on Nursing Education</span></h3>
                        <p><span data-preserver-spaces="true">Nurses preparing at the DNP level pay particular attention to the clinical component and the practical utilization of research. DNP educators focus on the development of advanced clinical skills among learners who are pursuing their nursing degrees. They impart concepts that can only be understood to provide the best patient care. In this connection, DNP educators underscore the relevance of implementing research evidence for practice. Various DNP programs provide leadership development regarding nurses as clinical leaders, health managers, and policymakers. </span></p>
                        <p><span data-preserver-spaces="true">This assists students in acquiring skills that are needed in managing healthcare teams and enhancing the frameworks. DNP educators habitually engage with populations, emphasizing availability, accessibility, quality, and care productivity. They assist students in understanding how to interact with networks and respond to their one-of-a-kind health-related needs.</span></p>
                        <h2><span data-preserver-spaces="true">Personal Vision for Using a DNP Degree in Nursing Education</span></h2>
                        <h3><span data-preserver-spaces="true">Teaching and Mentoring Students</span></h3>
                        <p><span data-preserver-spaces="true">In response to my concern, I will give quality clinical training to nursing students. By transferring the acquired clinical experience and recognizing the principles of evidence-based practice, I should give the student the ability to provide a legitimate degree of patient care. I will also patronize the students, advising them on their academic cycle and assisting them in becoming qualified, aloof nurses.</span></p>
                        <h3><span data-preserver-spaces="true">Developing Innovative Curriculum</span></h3>
                        <p><span data-preserver-spaces="true">I propose incorporating the findings of research studies and the accepted nursing procedures into the curriculum. This cycle will allow students to learn the latest patient care forms. I will also incorporate critical thinking procedures and create real-life simulation cases to make practice safe.</span></p>
                        <h3><span data-preserver-spaces="true">Community Outreach and Public Health</span></h3>
                        <p><span data-preserver-spaces="true">I will involve the locals in searching for the best approach to satisfying their healthcare necessities. By arranging and implementing health education programs, screenings, and prevention campaigns, it will be feasible to stabilize and enhance the population's health. The students will be engaged in these activities, which will afford them practical experience and cultivate their concern for social responsibilities.</span></p>
                        <h3><span data-preserver-spaces="true">Advocating for Policy Changes</span></h3>
                        <p><span data-preserver-spaces="true">Based on my overall understanding of the healthcare frameworks and strategies, I will advocate for change that will enhance the quality of healthcare provision and education. This includes striving for policy development, nursing education and practice upgrades, and improved patient results (Loescher et al., 2020).</span></p>
                        <h2><span data-preserver-spaces="true">Conclusion</span></h2>
                        <p>Ph.D. and DNP degrees are crucial in preparing future generations of nursing education. As explored in the DNP 850 Module 4 Assignment Prepared Nursing Educator, DNP-prepared educators advance patient care by applying research to the field and equipping clinically skilled students for real-world challenges. These contributions support a comprehensive and systematic approach to training nurses.</p>
                        <p>In the future, I will utilize my degree to educate learners, guide them, participate in professional networks, and advocate for strategic changes. This dual approach ensures that nursing education remains dynamic, future-focused, and grounded in research and best practices, ultimately enhancing the nursing profession and improving patient health.</p>
                        <h2>References</h2>
                        <p>Avery-Desmarais, S. L., Hunter Revell, S. M., &amp; McCurry, M. K. (2021). A theoretical framework to promote minority PhD and DNP Student Success in nursing education. <em>Journal of Professional Nursing</em>.</p>
                        <p><a href="https://doi.org/10.1016/j.profnurs.2021.10.002">https://doi.org/10.1016/j.profnurs.2021.10.002</a></p>
                        <p>King, T. S., Melnyk, B. M., OʼBrien, T., Bowles, W., Schubert, C., Fletcher, L., &amp; Anderson, C. M. (2019). Doctoral degree preferences for nurse educators. <em>Nurse Educator</em>, 1.</p>
                        <p><a href="https://doi.org/10.1097/nne.0000000000000730">https://doi.org/10.1097/nne.0000000000000730</a></p>
                        <p>Loescher, L. J., Love, R., &amp; Badger, T. (2020). Breaking new ground? The dual (Ph.D.) doctoral degree in Nursing. <em>Journal of Professional Nursing</em>.</p>
                        <p><a href="https://doi.org/10.1016/j.profnurs.2020.05.001">https://doi.org/10.1016/j.profnurs.2020.05.001</a></p>
                        <p>May, J. T., Littzen, C. O. R., Morrison, H. W., &amp; Loescher, L. J. (2020). Experiences of dual Ph.D. nursing students during doctoral education. <em>Journal of Professional Nursing</em>.</p>
                        <p><a href="https://doi.org/10.1016/j.profnurs.2020.02.004">https://doi.org/10.1016/j.profnurs.2020.02.004</a></p>
                        <p>Mu&ntilde;oz, L. R., Thomas, S. P., Sebach, A. M., Gibbons, M. M., &amp; Neal, A. M. (2023). Experiences of Doctor of Nursing Practice (DNP)-prepared nurses choosing to pursue a Doctor of Philosophy (PhD) degree. <em>Journal of Professional Nursing</em>, <em>46</em>, 168&ndash;178.</p>
                        <p><a href="https://doi.org/10.1016/j.profnurs.2023.03.003">https://doi.org/10.1016/j.profnurs.2023.03.003</a></p>
                        <p>Rosenfeld, P., Glassman, K., Vetter, M., &amp; Smith, B. (2021). A comparative study of PhD and DNP nurses in an integrated health care system. <em>Nursing Outlook</em>, <em>70</em>(1).</p>
                        <p><a href="https://doi.org/10.1016/j.outlook.2021.07.010">https://doi.org/10.1016/j.outlook.2021.07.010</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Dnp850module4assignment