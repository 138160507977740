import React from 'react';
import {FaRegArrowAltCircleRight, FaPhoneAlt, FaFacebook, FaInstagram, FaTwitter, FaPinterest, FaLinkedin, FaYoutube} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';



const Footer = () => {


  return (
    <>
        <Container>
        <div className='ocs2-footer-top'>
            <div className='ocs2-footer-top-bg'>
            <div className='ocs2-footer-banner-title'>
                <h2>We are available 24/7.</h2>
            </div>
            <div className='ocs2-footer-banner-cta'>
            <Link to={"./order"} className='ocs2-orderNow-btnn'><button className='ocs2-orderNow'>Order Now &nbsp;<FaRegArrowAltCircleRight/></button></Link>
                <div className='ocs2-bannerone-phoneNo'>
                    <FaPhoneAlt className='ocs2-bannerone-phoneNo-icon'/>
                    <span>
                    <p>Call us at</p>
                    <strong>+1(669) 209-0161</strong>
                    </span>
                </div>
            </div>
            </div>
        </div>
        </Container>
        <div className='ocs2-footer-middle-outer'>
        <Container>
        <div className='ocs2-footer-middle'>
            <div className='ocs2-footer-logo'>
                {/* <img src='images/logo.png' alt='' className='site-logo'/> */}
                <img src='images/footerbadge.png' alt='' className='footer-badge'/>
            </div>
            <div className='ocs2-footer-menu'>
                <h3>Quick Links</h3>
                <ul>
                    <li><Link className='ocs2-footer-menu-link' to="/terms-and-conditions" >Terms And Conditions</Link></li>
                    <li><Link className='ocs2-footer-menu-link' to="/privacy-policy" >Privacy Policy</Link></li>
                    <li><Link className='ocs2-footer-menu-link' to="/order" >Order Now</Link></li>
                </ul>
                <h3>Social Media</h3>
                <ul className='social-media'>
                    <li><a href='https://www.facebook.com/onlinecourseservices1'><FaFacebook/></a></li>
                    <li><a href='https://www.instagram.com/onlinecourse.services/'><FaInstagram/></a></li>
                    <li><a href='https://twitter.com/onlinecourse21'><FaTwitter/></a></li>
                    <li><a href='https://www.pinterest.com/onlinecourseservices2/'><FaPinterest/></a></li>
                    <li><a href='https://www.linkedin.com/company/onlinecourseservices/'><FaLinkedin/></a></li>
                    <li><a href='https://www.youtube.com/channel/UClYpw9b-JMkt3d2cTRN6NZg'><FaYoutube/></a></li>
                </ul>
            </div>
            <div className='ocs2-footer-contact'>
                <h3>Tell Us About Your Work</h3>
                <ul>
                    <li><strong>Name:</strong> Online Course Services</li>
                    <li><strong>Address:</strong> San Francisco CA, USA</li>
                    <li><strong>Number:</strong> +1(669) 209-0161</li>
                    <li><strong>Email:</strong> contact@onlinecourseservices.us</li>
                </ul>
                <h3 className='ocs2-footer-payment'>Payment Options</h3>
                <img src='images/payment.png' alt=''/>
            </div>
        </div>
        </Container>
        </div>
        <div className='ocs2-footer-disclaimer'>
          <p>Disclaimer : The custom writing services that we provide are intended exclusively for study and research. We do not allow direct submission of our content to institutes, organizations, etc., that prohibit the use of custom writing services.</p></div>
        <div className='ocs2-footer-copyright'><p>Copyright © 2023 OnlineCourseServices | Powered by MD LLC</p></div>
    </>
  )
}

export default Footer

