import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Bhafpx4104assessment4 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>BHA FPX 4104 Assessment 4 Human Resources Strategy</title>
                <meta name='description'
                    content='BHA FPX 4104 Assessment 4 Human Resources Strategy and Competitive Advantage reveals winning HR strategies. Click to read more!' />
                <meta name='keywords' content='BHA FPX 4104 Assessment 4 Human Resources Strategy and Competitive Advantage' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>BHA FPX 4104 Assessment 4 < br /><span>Human Resources Strategy and Competitive Advantage</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Bhafpx4104assessment4.webp" alt="BHA FPX 4104 Assessment 4 Human Resources Strategy and Competitive Advantage" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>BHA FPX 4104 Assessment 4 Human Resources Strategy and Competitive Advantage.</em></strong></li>
                        </ul>
                        <h2><strong>Human Resources: Strategy and Competitive Advantage</strong></h2>
                        <p>As a vast planner working with the connection pack in human resources at St. Anthony Clinical Center, I have been moved nearer to refer to a report concerning my discernible certified factors and evaluation following what happened at Vila Accomplishment, including the affiliation's current workforce and staffing issues. In what follows, I will rather than seclude our current staffing circumstance with expected future needs and separate how human resources can give a competitive advantage to our affiliation. Explore our assessment <a href="https://onlinecourseservices.us/bha-fpx-4104-assessment-2-strategic-analysis">BHA FPX 4104 Assessment 2 Strategic Analysis and Operational Considerations</a> for more information.</p>
                        <h2><strong>Part 1: Comparison of Current Workforce to Future Needs</strong></h2>
                        <p>In the context of BHA FPX 4104 Assessment 4 Human Resources Strategy and Competitive Advantage, as a harbinger in human resources at Vila Flourishing, I understand that I am in danger of the cycle by which our affiliation sees, picks, prepares, and deals with our different workforce. Since we live in an environment in which there is a deficiency of clinical advantages for professionals and clinicians, a chief test looked by our clinical thought affiliation, comparatively with different others, is drawing in and holding profoundly qualified and fit people (Healey &amp; Marchese, 2012) who can function as part of a high performing pack.</p>
                        <p>Central such people, as the new model at St. Anthony's sorts out, can defeat checks and answer rapidly to a quickly laying out climate. Moreover, conveying and remaining mindful of such a party proposes supporting qualities like trust, obligation, correspondence, and participation (Hakanen &amp; Soudunsaari, 2012) across departments, as is displayed in the going with the situation at St. Anthony's.</p>
                        <p>As commented in this scene, the current state or status concerning the workforce at SAMC is deficient in a couple of districts and needs to be done by and large quickly as part of our key planning process. As the third most prominent focus in the Minneapolis-St. Paul metro region, SAMC not only fills in as the central appearance clinical concentrate nearby, but it also offers care to our most delicate masses, including low-pay, explorer, and uninsured occupants.</p>
                        <ul>
                            <li>
                                <h3><strong>Challenges in Emergency Staffing</strong></h3>
                            </li>
                        </ul>
                        <p>Comparably, in other emergency offices, nursing needs and pay needs make staffing a particular test, and last week, a compound spill accomplished by a train crash featured some customarily close staffing levels, as did a couple of other patient circumstances, particularly in the trauma center and PICU, which has frightfully impacted our staff clinical monitors and nursing authority. In one circumstance, for example, Caleb Harvey, the emergency room help chief, was given the reasonable "turfing" of a pregnant patient by another clinical office, Construction, during this awe-inspiring time.</p>
                        <p>Notwithstanding the way that he is short-staffed, he sends one of his trauma center clinical chiefs to go with the new emergency room doctor, Nathan Cartwright, and patient, Mrs. Kalombo, up to L&amp;D. In any case, there is a correspondence issue: Mrs. Kalombo fundamentally conveys in French, and there is no translator open. Fortunately, Dr. Cartwright can understand and visit with her: she is deplored and will be put "out in the city," as the last emergency office she was at did, considering that she has no security.</p>
                        <p>Caleb tells Cartwright he is not astonished by this appearance of "turfing" from Rule, yet they need to work rapidly to find an OB and move her to L&amp;D before she needs to envision an offspring in the trauma center. Our CNO, in a later assembling with the board settling this and other moderate issues that are obliterating quality ideas at the office, yields that the "language Issue" is "one of our more fragile regions," and while they got "fortunate" by restriction of the pregnant lady, that is not overall the situation: they essentially have one administrator individual, for example, who imparts in Spanish, and they get a "surge of calls" from Rule to take these non-English language talking patients.</p>
                        <p>The Director sees whether there is a cycle set up to track down mediators so that the patient's needs will be met even in non-flood conditions. The other major and more squeezing staffing issue, at any rate, given the crisis flood in patients by uprightness of the train crash, happens in the PICU, one of the three units (with trauma focus and Grown-up ICU) that "are currently particularly under-staffed," as per CNO Jackie Sandoval.</p>
                        <h3><strong>BHA FPX 4104 Assessment 4 Human Resources Strategy and Competitive Advantage</strong></h3>
                        <p>She attempts to address the sharpness and patient weight issue in PICU by taking a gander at with Phoebe Hemsworth, PICU Expert Director, a couple of fixes, for example, pulling extra beds from plan surg to handle the pediatric over-trouble and moving different patients to pediatrics from drug surg to set free beds for any new declarations from the trauma center. While that will assist with sharpness in the PICU, Phoebe tells Jackie they are short two escorts for the night and three for the night shift, and one helpful Jackie moved from a course of action surg to ICU did not most recently one day in the new unit.</p>
                        <p>Jackie will perceive the way she could move a couple of extra clinical experts to take PICU shifts, and yet is focused on that new trauma center patients might be pulling in who will, in a short period, be moved to PICU, so Jackie and Phoebe should work with fix surg and trauma concentrate, energetically, to see what the customary crunch might be. During the later assembling with a pro in the emergency office meeting room, Jackie is generally worried about this staffing issue: even without the flood, they'd, in any case, be Expanded slim, and she ought to get extra staff for an enormous piece of the units on different events during the beyond quite a while: "Sharpness is up on all units and we have 23 beds left," she tells George Rat, Director of Clinical Endeavors.</p>
                        <p>She is concerned that they have depleted the PRN Clinical boss pool and have been relying too unequivocally on business offices for staffing urgently. While they can hire clinical gatekeepers from other facilities to respond to crises, Jackie argues they need more resources for everyday circumstances, not just emergencies. In any case, Focal opposes the financial plan for new staff, which is not open, as another diabetes-bursting region has been added to their cash.</p>
                        <ul>
                            <li>
                                <h3><strong>Staffing Challenges and Solutions</strong></h3>
                            </li>
                        </ul>
                        <p>Likewise, the new focus has recoded crucial deferrals for approaches, and it has not decreased how much patients genuinely come to their trauma center for different circumstances, too, as Jackie says. There may be a cutoff issue in the middle or unused space because they do not have adequate staff yet. Then again,</p>
                        <p>Along these lines, muscular staffing issues across different units continue to impact the emergency neighborhood's ability to provide quality care to our patients, and these worries should be addressed as part of the fundamental perpetual planning process.</p>
                        <p>In particular, there is a significant need for extra nursing staff, particularly in trauma centers and PICU, to compensate for an overflow of patients who are expected to feel pride in the office, even in customary times. Moreover, proclamations and EWR could profit from extra mediators added to the staff, particularly in vernaculars close to our traveler's patient individuals, like French and Spanish, so conditions, for example, what happened with the pregnant lady in the trauma center will not be rehashed &mdash; i.e., close to misses will not become signal occasions. This current workforce circumstance will be exacerbated.</p>
                        <p>In the future, too, given the office's improvement and new transient wings, for example, the diabetes region, on the web. Our CNO is currently scrambling to fill shifts, so it will be our competitive advantage to join and set up a couple of new full-time help who, with canning, begin to fill in the red before it threatens to isolate. Different issues to consider that will be accomplished by the division among current and future staffing limits, as our Manager has raised, consolidated support and cash, since using new staff is impossible at this point, given the turn of events, yet should be part of a future planning process expecting that we plan to remain competitive in our market.</p>
                        <h2><strong>Part 2: Staffing Plan and Competitive Advantage</strong></h2>
                        <p>Given our current circumstance, as portrayed above, empowering an optimal workforce or staffing plan fundamentally influences our future accomplishments at SAMC. The best workforce planning happens while different teams are evened out, and managers can work close by their affiliation's human asset department to check staffing needs and encourage a plan that could best explain those issues (Longest and Darr, 2014).</p>
                        <p>Too often, as shown in the new situation of reviewing a flood for patients at our emergency neighborhood or a train catastrophe, laborer needs can cause overabundances in clinical advantages settings. Outer factors, for example, the making individuals or making individuals average biological parts, support expanded interest in clinical thought affiliations, particularly emergency office settings, and these factors are fitting to our office. In that limit, directors indeed ought to have a workforce plan set to guarantee staff needs like the ones portrayed above are really or immediately settled or stayed away from altogether.</p>
                        <ul>
                            <li>
                                <h3><strong>Workforce Planning and Development</strong></h3>
                            </li>
                        </ul>
                        <p>In making such a workforce or staffing plan, regardless, I see that the execution of any strategy for development requires setting targets and that the progression of plans and financial plans should change over into express headway plans (Ginter, Duncan, and Swayne, 2013). Such plans, additionally, should be made to connect the department or actual unit, such as a trauma center or PICU, to the general strategy (Ginter, Duncan, and Swayne, 2013) for the clinical thought conspiracy.</p>
                        <p>Fittingly, it is essential to work with the human resources department on workforce planning, considering how the HR pack is prepared to handle joining and staffing issues. Notwithstanding, unit directors need to work with them to guarantee that the HR staff understands the particular work liabilities, confirmations, and necessities for open conditions, for instance.</p>
                        <p>Taking into account my evaluation of the current circumstance at SAMC, subsequently, my ideal staffing plan would contemplate more staff, particularly in picking and preparing gifted clinical supervisors and nursing collaborators to fill in openings in shifts in high-volume units like trauma centers, PICU, and Grown-up ICU, as well as a more expanded staff, particularly in declarations and in the using of mediators/translators furnished for working with our expert patients (we see a tremendous get-together of Huong occupants as well as Spanish-talking vagabonds in our trauma center, for example).</p>
                        <p>The relationship would benefit from taking on this plan, particularly in nursing the heap up/booking. Our CNO would not be in the unenviable position of rummaging up transitory nursing staff or moving clinical experts from different departments to compensate for unfilled moves and openings in thought for high-volume units, even in non-flood times.</p>
                        <ul>
                            <li>
                                <h3><strong>Staffing Challenges and Solutions</strong></h3>
                            </li>
                        </ul>
                        <p>In one more article on this staffing emergency, the American Orderlies Connection (ANA) battles that "[n]urse staffing levels are fundamental to streamlining nature of the patient idea, further making thought results, and basic length cost rule" (ANA, 2017) for the affiliation, particularly during this season of "support staffing insufficiencies, a making individuals and expanded patient eccentrics" (ANA, 2017) inside the clinical advantages industry.</p>
                        <p>Moreover, colossal measures ought to be taken by the relationship to guarantee the result of this future staffing plan. These exercises would join HR, in counsel with drive and department heads in impacted regions, first seeing the affiliation's ability needs (for our situation, generally more clinical directors to fill openings in shifts), trailed by an ID of the cutoff points, values, and approaches to overseeing acting expected for persuading execution, and at last a joining and picking strategy worked with toward meeting our both short and expanded length staffing needs.</p>
                        <h3><strong>BHA FPX 4104 Assessment 4 Human Resources Strategy and Competitive Advantage</strong></h3>
                        <p>As an issue of some significance, understanding workforce cutoff focuses can go far toward joining and holding the right staff. Overall, it could kill the necessity of using more new individuals at SAMC. For instance, the American Emergency Office Understanding (2013), in its Workforce Planning Model (WPM) white paper, suggests that the alliance ensures its "respected, basic length specialists have direct consent to tools and resources that will permit them to take occurring with tutoring, get retrained or access support affiliations" (AHA, p. 4).</p>
                        <p>The AHA recommends that the clinical advantages affiliation develop "an improvement outline raising individuals to the top of their solidarity level, limits, and/or tutoring, for example,' an occupation stepping stool for every department [that] can help delegates in imagining their profession way'" (p. 4).</p>
                        <p>Finally, reviewing the restrictions of prior representatives could lead to the question, "[a]sking, 'Does a clinical watchman need to finish this work?' Maybe there are other staff individuals who have the cutoff focuses, explanations, and/or course/preparation to finish the work" (AHA, p. 4). This move could decrease the strain on the nursing staff in high-volume units like trauma centers and PICUs.</p>
                        <p>While the staffing plan is executed, we can study and pick its flourishing by pondering (an evaluative instrument for this can be planned by HR appropriately) the two patients and staff in the impacted units to check whether their needs, which are not on an outstandingly essential level immaterial, are being met. Without question, the AHA (2013) white paper argues that an essential help plan ought to "[e]ncourage representatives to finish worker diagrams and offer layout results and show how any worries are being looked out for" (p. 16).</p>
                        <ul>
                            <li>
                                <h3><strong>Workforce Planning and Success</strong></h3>
                            </li>
                        </ul>
                        <p>At last, "[i]n referencing to expand an affiliation's profit from starting capital hypothesis on help, it prescribes a ton to quantify the results of onboarding, picking, and upkeep programs. An alliance cannot regulate or foster ventures if they do not look at their outcomes" (AHA, 2013, p. 18). This can be achieved through "[s]succession planning," which blends the proactive ID of force needs through drive workforce evaluation, association work planning, and improvement" (AHA, 2013, p. 18).</p>
                        <p>Concentrating on the consequence of a workforce planning model can, as such, be accomplished by encouraging an assessment tool to assist the relationship with exploring the plan's resources and deficiencies as we push forward (AHA, 2013, p. 22).</p>
                        <p>Thus, to make and support a high-performing bunch, HR staff should see that doable designs are set up in a manner that joins and holds people who can satisfy the careful needs of our industry. By really making such a get-together, SAMC can change itself into a high consistency relationship in which patients are coordinated and redesigned with immaterial put out a sensible nearly a sensible split between quality or patient security, which is a crucial objective and vital to our affiliation's vision, too.</p>
                        <p>While there are different qualities of high-performing get-togethers, Wiese and Ricci (2016) list two that appear to be particularly fitting to our clinical focus: 1) "The party participates in the wide conversation, and everybody has a huge opportunity to contribute"; and 2) "Battle is seen as something to be thankful for, and clashes are made due. Assessment is helpful and facilitates unequivocal thinking and killing adjustments."</p>
                        <ul>
                            <li>
                                <h3><strong>Staff Engagement and Performance</strong></h3>
                            </li>
                        </ul>
                        <p>I notice these contemplating the way that, as seen with the head gathering isolated above and the discussions among relationship in the nursing and clinical staff at SAMC, handling a dangerous issue like a shortfall of proficient staff in key positions expects the enormous responsibility of that tremendous number of partners expected, from the shift clinical guards to the CNO to the Director of Errands and President. If NAs and RNs do not feel like they have a voice in the issues and clashes affecting their work, trust, and individual and get-together execution, they will drop to the disadvantage of our patients.</p>
                        <p>Diligently end, we ought to use and hold staff open to obliging appraisal, yet close by willing, whether they are generally nonconformists, to convey something concerning the issues influencing their units and their capacity to answer patients' needs. Individuals from high-performing parties should be "sure about how to team up and how to achieve tries" (Wiese &amp; Ricci, 2016), And accomplishing this property proposes staff feeling perfect to voice their perspectives and closures when clashes emerge in the working environment as they will.</p>
                        <p>In the context of <a href="https://www.studocu.com/en-us/document/capella-university/strategic-leadership-and-workforce-planning-in-health-care/bha-fpx4104-assessment-4-1/14398894">BHA FPX 4104 Assessment 4 Human Resources Strategy and Competitive Advantage</a>, if the alliance does not address work environment issues and staffing needs, it will be impacted unfairly, as the new case of the flood caused by the train wrecking became particularly evident. Our weakness in addressing these staffing deficiencies and needs will similarly influence our ability to meet long-term strategic planning goals and place us in a difficult position with some of our partner organizations, like Framework.</p>
                        <p>Human resources management research shows that an organization's workforce is often one of its most significant competitive advantages. Therefore, it should be valued, developed, and maintained (Longest and Darr, 2014). We can achieve this by following some of the strategies I have outlined and organized above.</p>
                        <h2><strong>References</strong></h2>
                        <p>American Hospital Association. (AHA). (2013). Developing an adequate healthcare workforce planning model [PDF]. Retrieved from <a href="https://www.aha.org/system/files/2018-05/13wpmwhitepaperfinal.pdf.">https://www.aha.org/system/files/2018-05/13wpmwhitepaperfinal.pdf.</a></p>
                        <p>American Nurses Association (ANA). (2017, Oct. 24). Nurse staffing crisis. Retrieved from <a href="https://www.nursingworld.org/practice-policy/nurse-%20staffing/nurse-staffing-crisis/">https://www.nursingworld.org/practice-policy/nurse- staffing/nurse-staffing-crisis/.</a></p>
                        <p>Ginter, P. M., Duncan, W. J., &amp; Swayne, L. E. (2013). <em>The strategic management of healthcare organizations </em>(7th ed.). San Francisco, CA: Jossey-Bass.</p>
                        <p>Hakanen, M., &amp; Soudunsaari, A. (2012). Building trust in high-performing teams. <em>Technology Innovation Management Review, 2</em>(6), 38&ndash;41.</p>
                        <p>Healey, B. J., &amp; Marchese, M. C. (2012). <em>Foundations of health care management: Principles and methods. </em>San Francisco, CA: Jossey-Bass.</p>
                        <p>Longest, B. B., &amp; Darr, K. (2014). <em>Managing health services organizations and systems </em>(6th ed.). Baltimore, MD: Health Professions Press.</p>
                        <p>Wiese, C., &amp; Ricci, R. (2016). 10 characteristics of high-performing teams. Retrieved from <a href="http://www.huffingtonpost.com/carl-wiese/10-characteristics-of-hig_b_1536155.html.">http://www.huffingtonpost.com/carl-wiese/10-characteristics-of-hig_b_1536155.html.</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Bhafpx4104assessment4
