import React from 'react'
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";



const Faqs = () => {
    return (
        <>
        <Container>
            <div className='ocs2-faqs'>
                <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                <div className='ocs2-faqs-boxes'>
                    <div className='accordian-box-image'>
                        <img src='images/faqs.png' alt='' />
                    </div>

                    <Accordion className='accordian-boxes'>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>What services do you provide for Capella University nursing assessments?
                                </h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                We offer individualized assistance, such as tutoring, taking assessments guidance, and ongoing support to guarantee academic achievement.
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Where can I get assistance with my online course?
                                </h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Just get in touch with us for a consultation, and we'll put you in contact with a trained course assistant to help.
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Is it possible to hire a person to complete my online course?
                                </h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Yes, being online course assistance we do provide help in managing the courses you are involved in.
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>What if I have questions particular to the nursing ideas?
                                </h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                They are experts in various areas of nursing and will be available to nurture the student where needed.</div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>How can I know whether your services are what I need?</h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Our services are tailored to meet your academic needs, to maximize the help you get.
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>To what extent are the tutors familiar with Capella’s FlexPath program? </h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>
                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Indeed, all the tutors at our company are well acquainted with Capella’s FlexPath delivery system.</div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>How can one get started? </h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                To set up an initial meeting during which we will advise you on the best course of action, please contact us.</div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Is there anything to say about grades? </h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                We can’t give you grades, but our major concern will be not only to help you increase your knowledge and improve your scores.                            
                                </div>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader className='accordian-header'>
                                <h3 className={`accordion-title`}>Can I get access to study materials through your services? </h3>
                                <TiArrowSortedDown className='accordian-header-icon' />
                            </AccordionHeader>

                            <AccordionBody className='accordian-body-ans'>
                                <div className="accordion-body">
                                Indeed, as part of the support we give our students in online courses, we avail ourselves to readily available and complete study references.</div>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </div>
            </div>
            </Container>
        </>
    )
}

export default Faqs
