import React from 'react';
import Hero from '../homepage/Hero';
import Bannerone from '../homepage/Bannerone';
import Features from '../homepage/Features';
import Services from '../homepage/Services';
import Orderingprocess from '../homepage/Orderingprocess';
import Testimonials from '../homepage/Testimonials';
import Comparison from '../homepage/Comparison';
import Faqs from '../homepage/Faqs';
import Bannertwo from '../homepage/Bannertwo';
import Bannerfour from '../homepage/Bannerfour';
import Contentone from '../homepage/Contentone';
import Contenttwo from '../homepage/Contenttwo';
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Online Course Services | First Class 50% Off Apply Today</title>
        <meta name='description'
          content='Explore premium online course services with expert tutors and personalized support. Start now and claim a 50% discount to boost your studies!' />
        <meta name='keywords' content='online course services,onlinecourseservices, Online Course Services' />
      </Helmet>
      <Hero />
      <Services />
      <Bannerone />
      <Features />
      <Contentone />
      <Bannertwo />
      <Orderingprocess />
      <Testimonials />
      <Contenttwo />
      <Bannerfour />
      <Comparison />
      <Faqs />
    </>
  )
}

export default Home
