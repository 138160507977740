import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Dnp850module8assignment = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>DNP 850 Module 8 Assignment Nursing Leadership</title>
                <meta name='description'
                    content="Explore the DNP 850 Module 8 Assignment Advanced Nursing Leadership. Learn how DNP leaders drive change in patient safety. Read more now!" />
                <meta name='keywords' content='DNP 850 Module 8 Assignment Nursing Leadership' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>DNP 850 Module 8 Assignment < br /><span>Nursing Leadership</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Dnp850module8assignment.webp" alt="DNP 850 Module 8 Assignment Nursing Leadership" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>DNP 850 Module 8 Assignment Advanced Nursing Leadership.</strong></em></li>
                        </ul>
                        <h2>Advanced Nursing Leadership Reflection</h2>
                        <p>In the paper titled <strong>"</strong><a href="https://onlinetutormasters.com/dnp-850-module-8-nursing-leadership/">DNP 850 Module 8 Assignment Advanced Nursing Leadership</a><strong>"</strong>, the focus is on exploring the advanced nursing leadership skills and attributes essential for reducing central line-related bloodstream infections (CLABSIs) in the crisis unit at Roseland Social Class Hospital. Effective leadership is crucial in ensuring patient safety and achieving optimal outcomes. The content delves into the theoretical framework supporting this initiative, the hospital&rsquo;s organizational culture, key stakeholders involved, and the impact of healthcare policies and information technology on this vital endeavor.</p>
                        <h2>Leadership Skills and Attributes</h2>
                        <div>
                            <div>
                                <div>
                                    <div dir="auto" data-message-author-role="assistant" data-message-id="7f908800-b8c0-439a-b0e4-79f1230fc597" data-message-model-slug="gpt-4o">
                                        <div>
                                            <ul>
                                                <li><strong>Essential Leadership Skills in Nursing</strong></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>Reasonable leadership in nursing requires a blend of significant skills and fundamental attributes that, with everything looked at in advance as a suitable environment for positive change and ideal patient consideration results. Dire leadership skills include:</p>
                        <h3>Communication</h3>
                        <p>Guaranteed communication ought to be maintained at all levels. Convincing, tuning in with clients, giving fitting direction, and attracting the team to talk with each other are key to progressive care (Cummings et al., 2020). Communication allows everybody to be in contact, which is essential in basic areas like the ICU.</p>
                        <h3>Decision-Making</h3>
                        <p>The capacity to make educated and supportive decisions is a chief factor in healthcare settings(Cummings et al., 2020). This tendency incorporates researching information, considering the obligation of team members, assessing normal outcomes, and making decisions that emphasize calm safety and care quality.</p>
                        <h3>Emotional Intelligence</h3>
                        <p>Mental flourishing ought to be noticeable as one's capacity to manage oneself by directing sentiments, being mindful, and being stirred (Brewster et al., 2020). Emotional intelligence, or the trailblazer's capacity to control their perspectives and accurately work with subordinates' targets, opinions, and necessities, drives better team support and, generally, expanded adequacy while heading to helpful patient consideration.</p>
                        <h3>Problem-Solving and Delegation</h3>
                        <p>Strong trailblazers can see problems, create procedures, and execute changes. This combines definitive thinking and imagination, associating with trailblazers to address difficulties like decreasing CLABSIs proficiently. The capacity to consign errands reasonably ensures that team people are utilized to their resources, impelling a more productive work process and interfacing with staff to get a sense of obligation to occupations.</p>
                        <h2>Key leadership Attributes</h2>
                        <p><strong>Integrity:</strong> This clues the capacity of an individual, an affiliation, or a thing to oversee problems and avoid giving out deluding information ethically. StrictlyWhen trailblazers show raised levels of integrity; laborers will most likely trust them and expect a comparable kind of integrity from them. (Stoller, 2020).</p>
                        <p><strong>Resilience:</strong> Solid trailblazers can inspect setbacks and difficulties without losing focus or affirmation. This quality is gigantic in healthcare settings, where surprising conditions a colossal piece of the time arise.</p>
                        <p><strong>Empathy:</strong> Empathy licenses trailblazers to interface with their team people on a single level, understanding their propensities and motivations (Stoller, 2020). This characteristic advances a broad, supportive workplace, encouraging team affiliation and resolve.</p>
                        <p><strong>Vision:</strong> A prominent and persuading vision moves and rouses the team to seek after shared targets. The visionary control of leadership can consolidate the capacity to grant the conceivable outcome concerning the proficiency of the organizational concentrations in a given endeavour.</p>
                        <h2>Theoretical Framework</h2>
                        <ul>
                            <li>
                                <h3>Transformational Leadership in Healthcare</h3>
                            </li>
                        </ul>
                        <p>The Basic Leadership Speculation coordinated by James MacGregor Consumes and advanced by Bernard M. Bass should be used to lead the work to lessen Central Line-Related Dissipating framework Infections (CLABSIs) in the ICU of Roseland Social Class Hospital (Siangchokyoo et al., 2020). This speculation gives an indistinct focus on using motivational techniques to compel subordinates to perform past suppositions to support the spread-out vision. Conspicuous leadership combines four key parts:</p>
                        <h3>Idealized Influence</h3>
                        <p>It consolidates the trailblazers expecting some liability, including the extensive thoughts anticipated by the team; the trailblazers ought to persistently set the standards to be achieved in practice (Siangchokyoo et al., 2020). Subsequently, DNP, as a genuine portrayal of safe patient consideration, could influence a near approach among the faculty.</p>
                        <h3>Inspirational Motivation</h3>
                        <p>It rotates around sharing a sensible vision and making unbelievable targets. Accordingly, while conveying the particular target of diminishing CLABSIs and zeroing in on their essential worth, trailblazers can improve the team's execution.</p>
                        <h3>Intellectual Stimulation</h3>
                        <p>This promotes elective approaches to solving issues (Victoria et al., 2021). The concerned trailblazers should ensure that all team members feel free to propose novel considerations on the best technique for avoiding infections and achieving extra-large and expanded-length approaches.</p>
                        <h3>Individualized Consideration</h3>
                        <p>It might be characterized as the vital capacity to acknowledge and understand all specialists' unprecedented necessities and gifts. These approaches take on a warm and exquisite environment inside the team since each delegate is overseen according to their sensibility.</p>
                        <h2>Organizational Culture</h2>
                        <p>The organizational culture at Roseland Social Class Hospital highlights patient-centered care, participation, and trustworthy improvement. This culture is, consequently, key to impacting how any intervention assessing diminishing CLABSI for the ICU will be created and its outcome.</p>
                        <p>In the "DNP 850 Module 8 Assignment Advanced Nursing Leadership", the responsibility is highlighted to ensure that any intervention aimed at reducing CLABSIs prioritizes the optimal benefit of the patient. The focus on patient safety, coupled with the development of quality indicators, defines the hospital&rsquo;s commitment to upholding the principles of evidence-based practices, ensuring the best possible outcomes for patients.</p>
                        <p>The hospital workplace can be characterized as warm and team-facilitated, as interdisciplinary teamwork is considered fundamental. Communication and joint exertion at all organizational levels are pivotal for strong healthcare, considering being made heads or tails off (Kwame &amp; Petrucka, 2021). The immense joint effort between the ICU nurses, specialists, and specialists in organizing and completing an infection counteraction plan helps execute new practices. It shows actually that craving decreases the infection rate.</p>
                        <ul>
                            <li>
                                <h3>Hospital Culture and Improvement</h3>
                            </li>
                        </ul>
                        <p>Accordingly, Roseland Social Class Hospital is pivoted around best practices and its solid development. This social direction allows the staff to find out and take on extraordinary practices, which makes them seek standard coaching and partake in quality improvement drives (Kwame &amp; Petrucka, 2021).</p>
                        <p>In the hospital culture of supporting the staff's professional advancement near the essentials program and focusing on information accountability, the enormous length of CLABSI decline can remain mindful. Even so, it is central that the hospital's culture has its substitute side; there, with hardly any wavering, is security from change; besides, there is a substitute level of staff obligation. A few of these issues can only be tackled with apparent mediation to ensure that everybody in the team contributes vivaciously to the goals of the strength.</p>
                        <h2>Key Stakeholders</h2>
                        <p>A few stakeholders should be tended to in making a vibe of the crucial stages for the helpful execution of the endeavor to decrease the pace of central line-related course framework infections (CLABSIs) in the ICU of Roseland Social Class Hospital. In this manner, they are vital stakeholders who contribute different experiences, headings, and attempts to the endeavor creation, coordination, and satisfaction.</p>
                        <h3>ICU Nurses</h3>
                        <p>ICU nurses are the critical stakeholders in CLABSI counteraction and should participate in the infection evacuation. An assessment by Morely et al. (2020) conveyed that the disease could spread and is a practice-practical piece. Another concentrate by Dyar and Griswold (2020) depicts how they can deal with these central lines and the disclosure of signs of infection. Standard revision of the shows, their participation in the improvement of planning, and feedback on the enlightening class being joined will attract the ICU nurses to the errand.</p>
                        <h3>Infection Control Specialists</h3>
                        <p>These specialists are fundamental overall because of the information they provide concerning infection evasion and control. Their obligations include developing approaches and standards for infection control using research, energetically monitoring infection rates, and providing information to the clinical staff. Accordingly, their specialization ensures that the endeavor stays consistent with practices and the law.</p>
                        <h3>Physicians</h3>
                        <p>According to this source, the physicians who work with the patients in the ICU are intensivists, near other involved specialists. They are crucial for the endeavor and should participate in their positions. They can educate prepared patients on the significance of adherence to the set infection reduction measures, attend interdisciplinary social gatherings, and advance patients' opportunities to provide essential resources.</p>
                        <h3>Hospital Administration</h3>
                        <p>Hospital administration expects a frantic role in enabling accessible resources, such as resources for preparing ventures, new staff, and developments (Wong et al., 2020). Managers have a central commitment to leading the patient safety and quality improvement tracks.</p>
                        <h3>Quality Improvement Team</h3>
                        <p>The quality team's liabilities coordinate organizing the instances of researching and executing the different quality improvement projects. An information ace has profound knowledge of evaluations, process showing up, and coordination, which is essential for significant activities, including seeing the results, characterizing changes, and controlling the endeavor's work processes.</p>
                        <h3>Patients and Families</h3>
                        <p>Reviewing the patients and their relatives for the endeavor could help us understand their experiences and hypotheses (Wong et al., 2020). The endeavor furthermore wants to expand their knowledge into infection supposition practices and attract them to decision-making about their consideration, which is more likely than not going to draw them into consistency with the endeavor.</p>
                        <h3>Support Staff</h3>
                        <p>Unequivocal unit staff, housekeeping faculty, and other support staff are valuable in ensuring the place is perfect and secure. They depend on ensuring that each part is essential to convey care and limit infections.</p>
                        <h2>Leadership Philosophy and Framework</h2>
                        <p>While considering my leadership values and standards, the crucial leadership approach used to coordinate the staff resonates with my characteristics. As an expert in nursing practice (DNP) trailblazer, I have a strong potential for enhancing teamwork, team people's support, and obligation regarding superb outcomes in practice.<br /> Accordingly, the central top of my professionalism is the patient-centered approach. Any action or decision made utilizing the top administration to the staff level ought to revive the thriving and safety of our patients (Howick et al., 2024).</p>
                        <div>
                            <div>
                                <div>
                                    <div dir="auto" data-message-author-role="assistant" data-message-id="4da18374-751c-4880-9e30-7799dd0ceb0e" data-message-model-slug="gpt-4o-mini">
                                        <div>
                                            <div>
                                                <p>In the "DNP 850 Module 8 Assignment Advanced Nursing Leadership", it is emphasized that initiatives such as reducing CLABSIs are not only clinically achievable but also aligned with the needs of patients and their families. Adopting ethical practices and integrity has been fundamental in shaping my leadership style. I commit to acting as a model for the frameworks I implement in the workplace, prioritizing legitimacy, transparency, and accountability.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>This also helps build workers' trust in other team members and promotes positive reliance on moral requests, which can be examined and settled without tendency. I'm sure this revolves around focusing on the team's thoughts and the ordinary results of entrusting each team member with their viewpoints and achievements. Concerning free interaction, graciousness, and support, I attract an open and agreeable workplace. When executed precisely, it gains affiliation and creates headway for improvement.</p>
                        <ul>
                            <li>
                                <h3>Leadership Framework for Improvement</h3>
                            </li>
                        </ul>
                        <p>The leadership framework I have created has the crucial considerations of zeroing in on learning and being willing to improve reliably (Fitzgerald, 2020). I support changes in practices contemplating examination disclosures, professional new development, and assessment of staff presentations. This responsibility prescribes that we can stay updated with change in all we do and constantly endeavor to give the best consideration. As to standards of critical leadership and their practical application in the work, I draw in crafted by a particular team.</p>
                        <p>By trusting and mixing each person from a team, each expert can be given an extra huge level and meet the set targets. My leadership framework is facilitated around the four pieces of famous leadership: driving the party as a conspicuous sign, prodding the laborers, drawing in the imaginative viewpoint inside the social gathering, and ensuring that particular delegates' necessities are met. With these parts made, I can be in a circumstance to lead my team through a piece of the issues affecting healthcare improvement, each of the more so in crucial districts like the ICU.</p>
                        <h2>Impact of Healthcare Policy and Information Technology</h2>
                        <p>Technology and clinical benefits policy is essential in the cycles related to the endeavored and acknowledged decline of central line-related circulatory framework infections (CLABSIs) in the ICU of Roseland Social Class Hospital. Meticulous records and rules present a practice strategy (Selby et al., 2021).</p>
                        <ul>
                            <li>
                                <h3>Infection Prevention and Accountability</h3>
                            </li>
                        </ul>
                        <p>The execution of such standards reasonably motivates the purposeful development of infection-preventive measures, strengthening patient consideration. It is central to see that healthcare plans make broadcasting the infection rates mandatory as it manufactures the level of accountability and straightforwardness (Rosati et al., 2021). Adherence to these courses of action helps the relationship avoid authentic, authoritative repercussions and supports public quality improvement plans, achieving upgraded patient safety and care.</p>
                        <p>The usage of EHR and information evaluation as an IT moves close by and evasion of the amount CLABSIs. Electronic achievement records help record central line care practices and infection rates more accurately and continually, which ponders the ID of potential problems (Neyra &amp; Nadkarni, 2021). Along these lines, using information evaluation in such populaces could uncover plans, outline the nature and efficacy of the interventions, and enlighten practice decisions. Besides, with the help of different IT instruments, such as clinical decision support frameworks, the staff gets updates and alerts concerning infection presumption measures.</p>
                        <h2>Conclusion</h2>
                        <p>In the "DNP 850 Module 8 Assignment Advanced Nursing Leadership,<strong>"</strong> the focus is on the crucial role of nursing leadership in organizing CLABSI events in the ICU at Roseland Social Class Hospital. The social skills and personal attributes developed by DNP leaders, along with the assessment of leadership theories, organizational culture, stakeholder engagement, leadership beliefs, planning, and the use of health policies and IT, facilitate the implementation of impactful change initiatives. This strategic approach underscores the leadership's commitment to ensuring optimal patient care and safety outcomes.</p>
                        <h2>References</h2>
                        <p>Brewster, D. J., Butt, W. W., Gordon, L. J., &amp; Rees, C. E. (2020). Leadership in intensive care: A review.&nbsp;<em>Anaesthesia and Intensive Care</em>,&nbsp;<em>48</em>(4), 266&ndash;276.</p>
                        <p><a href="https://doi.org/10.1177/0310057x20937319">https://doi.org/10.1177/0310057x20937319</a></p>
                        <p>Cummings, G. G., Lee, S., Tate, K., Penconek, T., Micaroni, S. P. M., Paananen, T., &amp; Chatterjee, G. E. (2020). The essentials of nursing leadership: A systematic review of factors and educational interventions influencing nursing leadership.&nbsp;<em>International Journal of Nursing Studies</em>,&nbsp;<em>115</em>(103842).</p>
                        <p><a href="https://doi.org/10.1016/j.ijnurstu.2020.103842">https://doi.org/10.1016/j.ijnurstu.2020.103842</a></p>
                        <p>Fitzgerald, A. (2020). Professional identity: A concept analysis.&nbsp;<em>Nursing Forum</em>,&nbsp;<em>55</em>(3), 447&ndash;472.</p>
                        <p><a href="https://doi.org/10.1111/nuf.12450">https://doi.org/10.1111/nuf.12450</a></p>
                        <p>Grover, S., Fitzpatrick, A., Azim, F. T., Ariza-Vega, P., Bellwood, P., Burns, J., Burton, E., Fleig, L., Clemson, L., Hoppmann, C. A., Madden, K. M., Price, M., Langford, D., &amp; Ashe, M. C. (2021). Defining and implementing patient-centered care: An umbrella review.&nbsp;<em>Patient Education and Counseling</em>,&nbsp;<em>105</em>(7).</p>
                        <p><a href="https://doi.org/10.1016/j.pec.2021.11.004">https://doi.org/10.1016/j.pec.2021.11.004</a></p>
                        <p>&zwnj;Handley, S. C., Bell, S., &amp; Nembhard, I. M. (2020). A systematic review of surveys for measuring patient-centered care in the hospital setting.&nbsp;<em>Medical Care</em>,&nbsp;<em>Publish Ahead of Print</em>.</p>
                        <p><a href="https://doi.org/10.1097/mlr.0000000000001474">https://doi.org/10.1097/mlr.0000000000001474</a></p>
                        <p>Howick, J., Paquita de Zulueta, &amp; Gray, M. (2024). Beyond empathy training for practitioners: Cultivating empathic healthcare systems and leadership.&nbsp;<em>Journal of Evaluation in Clinical Practice</em>.</p>
                        <p><a href="https://doi.org/10.1111/jep.13970">https://doi.org/10.1111/jep.13970</a></p>
                        <p>Kwame, A., &amp; Petrucka, P. M. (2021). A literature-based study of patient-centered care and communication in nurse-patient interactions: Barriers, facilitators, and the way forward.&nbsp;<em>BMC Nursing</em>,&nbsp;<em>20</em>(158), 1&ndash;10. BMC Nursing.</p>
                        <p><a href="https://doi.org/10.1186/s12912-021-00684-2">https://doi.org/10.1186/s12912-021-00684-2</a></p>
                        <p>&zwnj;Neyra, J. A., &amp; Nadkarni, G. N. (2021). Continuous kidney replacement therapy of the future: Innovations in information technology, data analytics, and quality assurance systems.&nbsp;<em>Advances in Chronic Kidney Disease</em>,&nbsp;<em>28</em>(1), 13&ndash;19.</p>
                        <p><a href="https://doi.org/10.1053/j.ackd.2021.03.020">https://doi.org/10.1053/j.ackd.2021.03.020</a></p>
                        <p>&zwnj;Rosati, P., Saulle, R., Amato, L., Mitrova, Z., Crocoli, A., Brancaccio, M., Ciliento, G., Alessandri, V., Piersigilli, F., Nunziata, J., Cecchetti, C., Inserra, A., Ciofi degli Atti, M., &amp; Raponi, M. (2021). Mindful organizing as a healthcare strategy to decrease catheter-associated infections in neonatal and pediatric intensive care units. A systematic review and grading recommendations (GRADE) system.&nbsp;<em>The Journal of Vascular Access</em>,&nbsp;<em>22</em>(6), 955&ndash;968.</p>
                        <p><a href="https://doi.org/10.1177/1129729821990215">https://doi.org/10.1177/1129729821990215</a></p>
                        <p>Selby, L. M., Rupp, M. E., &amp; Fawcett, K. A. (2021). Prevention of central-line associated bloodstream infections.&nbsp;<em>Infectious Disease Clinics of North America</em>,&nbsp;<em>35</em>(4), 841&ndash;856.</p>
                        <p><a href="https://doi.org/10.1016/j.idc.2021.07.004">https://doi.org/10.1016/j.idc.2021.07.004</a></p>
                        <p>Siangchokyoo, N., Klinger, R. L., &amp; Campion, E. D. (2020). Follower transformation as the linchpin of transformational leadership theory: A systematic review and future research agenda.&nbsp;<em>The Leadership Quarterly</em>,&nbsp;<em>31</em>(1), 101341.</p>
                        <p>Stoller, J. K. (2020). Leadership essentials for the chest physician: Models, attributes, and styles.&nbsp;<em>Chest</em>,&nbsp;<em>159</em>(4).</p>
                        <p><a href="https://doi.org/10.1016/j.chest.2020.09.095">https://doi.org/10.1016/j.chest.2020.09.095</a></p>
                        <p>Victoria, A., Olalekan, A., &amp; Abraham, A. (2021). Leadership: A review of definitions and theories.&nbsp;<em>International Journal of Advances in Engineering and Management (IJAEM)</em>,&nbsp;<em>3</em>, 273.</p>
                        <p><a href="https://doi.org/10.35629/5252-0301273291">https://doi.org/10.35629/5252-0301273291</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Dnp850module8assignment