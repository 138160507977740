import React from 'react'
import {FaRegArrowAltCircleRight, FaPhoneAlt} from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Bannerone = () => {
  return (
    <>
      <div className='ocs2-bannerone'>
        <div className='ocs2-bannerone-image'>
          <img src='images/bannerone.png' alt='' className='ocs2-bannerone-image-box'/>
        </div>
        <div className='ocs2-bannerone-detail'>
          <p className='ocs2-bannerone-detail-title'>EXPERIENCE OUR FIRST-RATE  <br/>ONLINE COURSE SUPPORT SERVICES</p>
          <p className='ocs2-bannerone-detail-text'>Make academic progress with our excellent online course services. Get perfect, high-quality papers tailored to your exact course requirements at unbeatable costs. Our qualified assistance will help you become more proficient with your tasks and improve your scores. Take advantage of hassle-free online course assistance with superior performance and academic advancement.</p>
          <div className='ocs2-orderNow-box'>
            <Link to={"/order"} className='ocs2-orderNow-btnn'><button className='ocs2-orderNow'>Order Now &nbsp;<FaRegArrowAltCircleRight/></button></Link>
            <img src='images/time.png' alt='' className='ocs2-orderNow-time'/>
            <div className='ocs2-bannerone-phoneNo'>
              <FaPhoneAlt className='ocs2-bannerone-phoneNo-icon'/>
              <span>
                <p>Call us at</p>
                <strong>+1(669) 209-0161</strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Bannerone
