import React from 'react'
import { ImSearch } from "react-icons/im";
import { BsHeadset } from "react-icons/bs";
import { MdOutlineSendTimeExtension } from "react-icons/md"
import Container from 'react-bootstrap/esm/Container';
const Orderingprocess = () => {
  return (
    <>
    <Container>
        <div className='ocs2-order' id='ordering'>
            <h2><strong>Get Nursing Assessment Help</strong><span>In Three Steps</span></h2>
            <p>To get expert online course help and acquire excellent outcomes from our course assistants, simply follow these easy steps.</p>
            <div className='ocs2-order-steps'>
                <div className='ocs2-order-card'>
                    <ImSearch className='ocs2-order-icon'/>
                    <h3>1. Consultation</h3>
                    <p>We are here to learn about your assessment requirements.</p>
                </div>
                <div className='ocs2-order-card'>
                    <BsHeadset className='ocs2-order-icon'/>
                    <h3>2. Personalized Match</h3>
                    <p>As much as possible, we attempt to have a tutor in or near your area of further education.</p>
                </div>
                <div className='ocs2-order-card'>
                    <MdOutlineSendTimeExtension className='ocs2-order-icon'/>
                    <h3>3. Ongoing Support</h3>
                    <p>Throughout your entire course get consistent support and feedback every time.</p>
                </div>
            </div>
        </div> 
        </Container>
    </>
  )
}

export default Orderingprocess
