import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx8045assessment5 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 8045 Assessment 5 Evidence for Obesity</title>
                <meta name='description'
                    content="Explore NURS FPX 8045 Assessment 5 Synthesis of the Evidence for Obesity. Discover key findings and insights—Read for in-depth analysis!" />
                <meta name='keywords' content='NURS FPX 8045 Assessment 5 Evidence for Obesity' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 8045 Assessment 5 < br /><span>Evidence for Obesity</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/Nursfpx8045assessment5.webp" alt="NURS FPX 8045 Assessment 5 Evidence for Obesity" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><strong><em>NURS FPX 8045 Assessment 5 Synthesis of the Evidence for Obesity.</em></strong></li>
                        </ul>
                        <h2>Synthesis of the Evidence for Obesity</h2>
                        <p>Obesity is a bewildering, moderate, and falling away from the sureness of reliable wrecking portrayed by undeniable or past crazy muscle versus fat that tried individuals' succeeding and individual satisfaction. Obesity is a major issue (Ceccarini et al., 2015). It is depicted as a weight record (BMI) with a few spots at 25 and 30 kg m&minus;2, and terrible obesity is shown as a BMI of 30 kg m&minus;2 or significantly more critically (Caterson et al., 2019).</p>
                        <p>The conviction of cross-country obesity has increased from 4.6% in 1980 to 14.0% in 2019 (Boutari &amp; Mantzoros, 2022). Obesity influences 42% of grown-ups, costing clinical advantages worth $3.8 trillion in 2019 (Area of Nevada, 2021). An opening in care exists in the relationship of fat patients; paying little view to the genuinely extensive assertion as a solid issue, obesity remains especially underdiagnosed and undertreated (Caterson et al., 2019).</p>
                        <p>This paper will isolate five articles related to the consistency of obesity. The reference section will introduce a table and give a critical review and synthesis of the evidence for the opportunity of an obesity-related project. It will also introduce past assessments and review this creator's points of view about her needs.</p>
                        <h2>Identified Research Articles</h2>
                        <p>Five articles were identified for this paper. A 2022 update on the assessment of sickness transmission of obesity and a wellspring of inspiration: As its twin Coronavirus pandemic sends an impression of being subsiding, the obesity and dysmetabolism pandemic continues to smoulder on (Boutari, C., and Mantzoros, C. S. 2022). Openings to relate: Misalignment between sharpness, reality, and practices in obesity (Caterson et al., 2019). Farrell et al. (2021), the lived information on individuals with obesity: Study shows a deliberate review and synthesis of profound assessments.</p>
                        <p>Silveira et al. (2022). Fixed structure for controlling acting, authentic laziness, stomach obesity, and obesity in grown-ups and more set-up grown-ups, and (Sun et al., 2022), Models for the most part and stomach obesity in us grown-ups: Evidence from the public accomplishment and food assessment review (Boutari and Mantzoros, 2022). It was singled out, considering that the creators report that America has one of the highest obesity rates.</p>
                        <h2>NURS FPX 8045 Assessment 5 Evidence for Obesity</h2>
                        <p>Females unendingly lead to the degree that the level of obesity is standard, given the generally settled more critical degree of muscle versus fat in ladies. This advances sponsorship to the endeavour, seeing fat female patients' status change for weight decline. (Caterson et al., 2019) was singled out considering the way that the creators expected that level of 68% of people with obesity (PoW)and 88% of clinical thought suppliers (HCPs) concurred with the explanation that obesity is solid area for a. (Silveira et al., 2022) It was singled out considering the way that researchers figured out that patients with a BMI of 30 or higher ought to be reviewed for status to change, which is the mystery push toward weight the pioneers.</p>
                        <p>(Farrell et al., 2021) It was almost singled out considering how the outcome further depicts a key for obesity screening. (Sun et al., 2022) was singled out considering the way that the producers examine the normality of general obesity and stomach obesity, which are colossally succeeding<br />loads among US grown-ups.</p>
                        <h2>Critical Review of the Literature</h2>
                        <p>Boutari and Mantzoros (2022) zeroed in on a 2022 locale report on obesity by the World Succeeding Connection (WHO) and the information passed on from the Corner Shop of Destroying Study. The WHO restricted the world into six regions: Africa, the Americas, the Eastern Mediterranean, Europe, Southeast Asia, and the Western Pacific; this is associated with 78% of the individuals. The creators found that the age-normalized expectedness of obesity was related to 4.6% in 1980 to 14.0% in 2019.</p>
                        <p>A critical piece of this climb happened in 22 years, with a few spots in the level of 1980 and 2002, and another half occurred in 17 years, some in 2002 and 2019. Creators report that the repercussions of obesity on flourishing join those that result from the mechanical impacts of flood body weight, for example, several outer muscle bothers, metabolic impacts like diabetes and cardiovascular beat, and the consequences for mental success. Obesity is, in addition, considered at risk for more than 13 sorts of malignancies.</p>
                        <p>The impacts of the Coronavirus pandemic have outrageously squashed overweight and tubby individuals. The move in obesity has advanced by and large. Its sureness is higher in females than people and levels of progress with age. (Boutari and Mantzoros, 2022). This article covers the consistency of obesity throughout recent years and has a high-grade conviction rating.</p>
                        <ul>
                            <li>
                                <h3>Openings in Obesity Care and Mediation</h3>
                            </li>
                        </ul>
                        <p>Caterson et al. (2019) are a cross-sectional, non-interventional sorting rotated around the gathered information in an electronic report. Qualified clinical advantages informed a lot of informed subject matter experts (HCPs) were clinical professionals in a sharing country who had been clearing for two years or more, with something like 70% of their time related to direct quiet assessments, and who had seen something like 100 patients during the previous month, something like ten of whom had a BMI of something like 30 kg/m2, or. 25 kg/m2. Individuals with obesity (PwO) were 18 years or more ready and were tenants of a five-star country, with an energy BMI, taking into account self-uncovered level and weight, of something like 30 kg/m2 or maybe 25 kg/m2.</p>
                        <p>Two reviews, one for PwO and one for HCPs, were made as a forewarning, considering everything; a gathering of obesity showed many informed, prepared professionals. The goal model size, considering 12 sharing nations, was 19,700. Quantifiable importance was set at P &lt; 0.05, utilizing 2-followed tests. A level of 14,502 PwO and 2785 HCPs finished the review. Producers obliterated that 68% of PwO and 88% of HCPs concurred with the explanation that obesity is a commonplace issue. Most PwO (82%) and HCPs (76%) saw that obesity influences standard accomplishment.</p>
                        <p>In assessment, 75%-85% of PwOs and 82%-93% of HCPs conveyed that diabetes, stroke, harming progress, or advancing obstructive pneumonic torture massively influence, if all else fails. Pardoning and trusting in obesity as unrest that influences consistently talking, most PwO formed weight decline (81%) and trusted in the way of life to be a major register in their battle with obesity (63%). 36% of PwO had reviewed their weight with an HCP during the past 5 years, and 67% did not set up to for each situation have obesity. The most troublesome system for coordinating weight bound among PwO and HCPs was a general improvement in dietary models and a general improvement in solid work level.</p>
                        <ul>
                            <li>
                                <h3>Obesity Models and Experiences</h3>
                            </li>
                        </ul>
                        <p>Nonetheless, unambiguous eating plans or exercise programs, following weight decline drugs, and bariatric development were more against have been reviewed. This article covers the opening in obesity care and mediation; it has a high-grade conviction rating. Silveira et al. (2022) drove a meta-assessment of 23 meta-assessment bases that included 638,000 grown-ups and more set-up grown-ups to look at the general consistency of force. This deliberate game plan followed the PRISMA (Inclined toward Revealing Things for Explicit Reviews and Meta-assessments) clarification rules.</p>
                        <p>As featured in NURS FPX 8045 Assessment 5 Synthesis of the Evidence for Obesity, thought rules included (1) being driven in grown-ups and more planned grown-ups; (2) importance, depicted as a BMI &ge;30 kg/m2 or not settled by midriff limit (WC) more clear than 102 cm in men and 88 cm in ladies. The creators made a table with the going with limits: ID (producers and year of scattering and information gathering country), based on the blueprint (target individuals and age pack, kind of review, and test size), focal issues of view or brand name rules (strength interest, certifiable work assessment, and expressive measures or cutoff standard regular components for SB/PI). The Titanic pursuit tracked down 6295, maybe monstrous, surprising copies.</p>
                        <p>After getting liberated from copies, 5989 articles were decided to sort out titles and changed works, and 165 were chosen to be brokenfully broken down. Most assessments were finished in made nations. The assessments were done in the going with nations: the US and Sweden. Australia, Europe, the Bound Together Locale, Germany, Brazil, Spain, Ireland, and Uganda. People's age took off to a few spots, in the 1spotsd 92 years. The producers report that this is the foremost cautious review and meta-assessment of observational assessments to destroy the normality of fixed way of life (35%) and trustworthy lethargy (43%) in grown-up and more settled grown-ups with significance.</p>
                        <p>They tracked down high measures and a huge change for SB (16%-71%) and PI (3%-82%) in people with thickness. This study has two or three necessities, for example, paying little psyche to what an assessment thinks about the absence of information on the expectedness of PI and fixing it straightforwardly in individuals with weight (Silveira et al., 2022). This meta-assessment shows the sureness of abnormal quality in made countries. It has a moderate conviction rating.</p>
                        <h2>NURS FPX 8045 Assessment 5 Evidence for Obesity</h2>
                        <p>Farrell et al. (2021) report that strength is a floundering, bewildered, moderate, and breaking sureness driving weight portrayed by tremendous or unessential muscle to the fat degree that weakens individuals' thrive and satisfaction. It influences more than 650 million grown-ups from one side of the world to the other. The creators made a show for the profound proof synthesis of the lived comprehension of individuals with sympathy.</p>
                        <p>The information made through the discerning review was synthesized, including thematic synthesis as depicted by Thomas and Set. Producers report that weight is an awe-inspiring, clear issue that transforms into the bet of extended length, stunning issues, and diminished individual satisfaction. It influences a central level of unyielding individuals and is essential for general success. A need to reexamine how we approach importance has been seen &mdash; one that watches out for the voices and encounters of individuals living with thickness. This is a moderate grade sureness.</p>
                        <ul>
                            <li>
                                <h3>Plans for US Obesity</h3>
                            </li>
                        </ul>
                        <p>(Sun et al., 2022) This study looks at the standard weight and stomach strength model in US grown-ups from 2001 to 2018. Forty-4,000 one hundred 84 grown-ups from 9 creative Public Prospering and Food Assessment Progress (NHANES) events were merged. The age- The qualification in mean weight record and midsection is not enduringly settled, and the format cycle illustrated the sex-unequivocal yearly change.</p>
                        <p>The producers report that the review showed that overall brawniness and stomach weight tended to around 35.48, and 53.13% of the US public. Furthermore, from 2001-2002 to 2017-2018, the age-changed consistency of general weight connected from 33.09 to 41.36% in females and from 26.88 to 42.43% in people. During 2001-2018, the age-changed force of stomach thickness fell from 57.58 to 67.33% in females and from 39.07 to 49.73% in people. A beast time-subordinate expansion was tracked down in the conventionality of general strength (changed OR, 1.007; 95% CI 1.005-1.009, P &lt; 0.001) and stomach weight (changed OR, 1.006; 95% CI, 1.004-1.008; P &lt; 0.001). This study checked out the inevitability of the beast. It is of high-grade measures.</p>
                        <h2>Synthesis of the Literature</h2>
                        <ul>
                            <li>
                                <h3>Overall Effect of Obesity</h3>
                            </li>
                        </ul>
                        <p>Giganticness is depicted as a BMI &ge;30 kg/m2. It influences more than 650 million grown-ups from one side of the world to the other (Farrell et al., 2021). Creators Boutari and Mantzoros (2022) report that repercussions of monsters on thriving heading result from the mechanical impacts of overabundance body weight, for example, several outer muscle loads, metabolic impacts like diabetes and cardiovascular beat, and the consequences for mental accomplishment.</p>
                        <p>Weight is furthermore viewed as in peril for more than 13 sorts of malignancies. In a cross-sectional, non-interventional, certain review that gathered information from people with weight (PwO) through an electronic assessment of 19,700 respondents, producers saw that a level of 68% of PwO and 88% of HCPs concurred with the explanation that strength is a dependable issue, Most PwO (82%) and HCPs (76%) saw that importance by and enormous impacts in standard thriving.</p>
                        <p>In assessment, 75%-85% of PwOs and 82%-93% of HCPs conveyed that diabetes, stroke, compromising new turn of events, or occurring obstructive pneumonic issues hugely influence all over (et al., 2019). In an assessment of 165 articles, experts report that this is the major review and meta-assessment of observational assessments to research the customariness of fixed way of life (SL) (35%) and authentic apathy (PI) (43%) in grown-ups and more settled grown-ups with finish.</p>
                        <p>They tracked down high measures and a goliath irregularity for SL (16%-71%) and PI (3%-82%) in people with strength (Silveira et al., 2022). A lethargic way of life, with a high sugar, refined carb diet, nearby brand name, metabolic, and standard parts, is right at this point attempted to be the focal driver for the tubbiness plague (Boutari and Mantzoros). Beast influences more than 650 million grown-ups for the most section (2021). During 2001-2018, the age-changed force of stomach magnanimity related from 57.58 to 67.33% in females (Sun et al., 2022).</p>
                        <h2>Writing Feedback</h2>
                        <p>Feedback for this producer's past assessment (3) is that she should be more aware of her APA. This aligns with around one-third of the writing in this program and has chipped away over the last couple of classes. While I got a "proficient" score twice or more, I have felt I am "forgetting to satisfy questions." To counter this, I have begun using my APA seventh-mix manual. Other feedback included adding that my professor euphorically got (Dr.) before their names.</p>
                        <h2>Conclusion</h2>
                        <p>Obesity is a defilement, influencing ladies more than men. In Nevada, 28.7% of grown-ups report battling with obesity (Area of Nevada, 2021). A weight record (BMI) of 30 kg/m&sup2; or higher is named obesity. A decent way of life, got together with an eating routine high in sugar and refined carbs, nearby hereditary, metabolic, and standard parts, is at last clear as the key driver of the obesity pandemic. As underlined in <a href="https://www.writinkservices.com/nurs-fpx-8045-assessment-5-evidence-for-obesity/">NURS FPX 8045 Assessment 5 Synthesis of the Evidence for Obesity</a>, paying special attention to obesity requires doing systems for change custom fitted to the patient's necessities.</p>
                        <h2>References</h2>
                        <p>Boutari, C., and Mantzoros, C. S. (2022). A 2022 update on the assessment of confusion transmission of breadth and a wellspring of motivation: As its twin Covid pandemic sends an impression of being dying down, the weight and dysmetabolism pandemic keeps seething on. Maintenance, 133, 155217.<br /><a href="https://doi.org/10.1016/j.metabol.2022.155217">https://doi.org/10.1016/j.metabol.2022.155217</a></p>
                        <p>Caterson, I. D., Alfadda, A. A., Auerbach, P., Coutinho, W., Cuevas, A., Exchange, D., Hughes, C., Iwabu, M., Kang, J., Nawar, R., Reynoso, R., Rhee, N., Rigas, G., Salvador, J., Sbraccia, P., V&aacute;zquez‐Vel&aacute;zquez, V., and Halford, J. C. (2019). Openings to relate: Misalignment between shrewdness, reality and practices in breadth. Diabetes, Heartiness and Taking care of, 21(8), 1914-1924.<br /><a href="https://doi.org/10.1111/dom.13752">https://doi.org/10.1111/dom.13752</a></p>
                        <p>Farrell, E., Bustillo, M., le Roux, C. W., Nadglowski, J., Hollmann, E., and McGillicuddy, D. (2021). The lived data on people with weight: Studies show a strong framework and relationship of calculated examinations. Intentional Frameworks, 10(1).<br /><a href="https://doi.org/10.1186/s13643-021-01706-5">https://doi.org/10.1186/s13643-021-01706-5</a></p>
                        <p>Shane, S. (2022, October 22). To change the tide, Nevada should believe fortitude is an affirmed infection. Reno paper journal. Recuperated January 6, 2023, from<br /><a href="https://www.rgj.com/story/assessment/2022/10/27/change tide-nevada-must-see weight real torment/10612349002/">https://www.rgj.com/story/assessment/2022/10/27/change tide-nevada-must-see weight real torment/10612349002/</a></p>
                        <p>Silveira, E., Mendon&ccedil;a, C., Delpino, F., Elias Souza, G., Pereira de Souza Rosa, L., de Oliveira, C., and Noll, M. (2022). Fixed method for managing acting, veritable sluggishness, stomach strength and weight in adults and more coordinated adults: A cognizant overview and meta-assessment. Clinical Food ESPEN, 50, 63-73.<br /><a href="https://doi.org/10.1016/j.clnesp.2022.06.001">https://doi.org/10.1016/j.clnesp.2022.06.001</a></p>
                        <p>Area of Nevada. (2021). Yearly strength report 2021. Recuperated January 4, 2023, from<br /><a href="https://www.leg.state.nv.us/Division/Assessment/Reports/RTTL_NRS439.521_2022.pdf">https://www.leg.state.nv.us/Division/Assessment/Reports/RTTL_NRS439.521_2022.pdf</a></p>
                        <p>Sun, J.- Y., Huang, W.- J., Hua, Y., Qu, Q., Cheng, C., Liu, H.- L., Kong, X.- Q., Mother, Y.- X., and Sun, W. (2022). Plans generally and stomach power in adults: Attestation from the public achievement and food assessment review (2001-2018). Backwoods in Normal Flourishing, 10.<br /><a href="https://doi.org/10.3389/fpubh.2022.925293">https://doi.org/10.3389/fpubh.2022.925293</a></p>
                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx8045assessment5